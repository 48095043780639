import React, { useEffect, useState } from "react";
import DocumentIcon from "../../../assets/images/icons/document-icon.png";
import BuildingDocumentTemplate from "../template/Documents/BuildingDocumentTemplate";
import { getContentInDir, humanreadablePath } from "../../../store/actions/fetchDocs";
import {env} from "../../../env";
import {getFileUrl} from "../../../store/actions/handleStorage";

const BuildingLevel2DocumentListing = ({ docPath, directory, userRole, buildingId, buildingName }) => {

  const [docs, setDocs] = useState([])
  useEffect(() => {
    if (docPath === '' || docPath === directory) return;
    if (env.verbose) console.debug('foo docpath', docPath)
    getContentInDir(docPath, userRole, true).then(docs => {
      console.log(docs.length, docs.length === 1 ? ("Document") : ("Documents"))
      setDocs(docs)
    })
    return () => { }
  }, [docPath, directory, userRole])

  //const relevantFolders = documentData[id] || [];
  if (!docs) return env.devmode ? <p>Debug: Inga dokument laddade</p> : <></>
  if (docs < 1) return env.devmode ? <p>Debug: Dokument att visa: 0</p> : <></>
  return (
    <div className="hejsan">
      <h1>Dokument,
        {env.devmode ? ' (debug: att visa: ' + docs.length + ")" : <></>}
      </h1>
      {docs.map((item) => (
        <BuildingDocumentTemplate
          key={item.BuildingDocumentHeadline || item.id}
          icon={item.BuildingDocumentPicture || DocumentIcon}
          title={item.title || item.fileName || item.id}
          inFolder={humanreadablePath(item.path, buildingId, buildingName).replace(item.id, '') || 'error'}
          DocumentLink={item.filePath /*|| item.DocumentLink */}
        />
      ))}
    </div>
  );
};

export default BuildingLevel2DocumentListing;
