import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import Template from "../pages/template/Template";
import NewsForm from "../components/Forms/NewsForm";
import NewsListing from "../components/Listing/NewsListing.js";
import "../assets/styles/Pages/NewsPage.scss";
import {fetchDocs, fetchDocsDataSerializeable} from "../store/actions/fetchDocs";
import filterIcon from "../assets/images/icons/filter-icon-white.png";
import { BackButton } from "../components/Button.js";
import { env } from "../env";

function NewsPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterTypeRole, setFilterTypeRole] = useState("");
  const [filterValueRole, setFilterValueRole] = useState("");
  const [filterTypeUnit, setFilterTypeUnit] = useState("");
  const [filterValueUnit, setFilterValueUnit] = useState("");
  const [units, setUnits] = useState([]);
  const {refreshBool, setRefreshBool} = useState(false)

  /*Hämtar data om aktuell användare från Global state*/
  const theUser = useSelector((state) => state.account.theUser);

  function refreshTheBool() {setRefreshBool(!refreshBool)}

  useEffect(() => {
    let ignore = false;

    if (!ignore) fetchDocsDataSerializeable("units").then((res) => setUnits(res))
    return () =>
      ignore = true;
  }, []);

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  const dropdownUnitRef = useRef(null);
  const dropdownRoleRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownUnitRef.current &&
        !dropdownUnitRef.current.contains(event.target)
      ) {
        document.getElementById("filterDropdown-unit").classList.remove("show");
      }
      if (
        dropdownRoleRef.current &&
        !dropdownRoleRef.current.contains(event.target)
      ) {
        document.getElementById("filterDropdown-role").classList.remove("show");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFormSubmit = (formData) => {
    //console.log("Formulärdata: ", formData);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterClickRole = (type, value) => {
    setFilterTypeRole(type);
    setFilterValueRole(value);
  };
  const handleFilterClickUnit = (type, value) => {
    setFilterTypeUnit(type);
    setFilterValueUnit(value);
  };

  return (
    <Template>
      <BackButton />
      <h1>Hantera nyheter,</h1>
      <p>
        Här är dina nyheter:{filterValueRole}
        {filterValueUnit}{" "}
      </p>
      <NewsForm onSubmit={handleFormSubmit} theUnits={units} theUser={theUser} refreshTheBool={refreshTheBool} />
      <section className="profile-page">
        <section className={env.devmode ? "special-section" : 'hidden'}>
          {/*to do*/}
          <h2>Att göra</h2>
          <p>
            Denna sektion har unika stilar definierade i NewsPage.scss.
            Noterbart att vi ännu inte har implementerat alla värden för nyheter
            sidan.
          </p>
        </section>
      </section>
      <section className="template-subsection">
        <section className="left-section">
          <h1>Hantera skapade nyheter</h1>
          <p>Här är dina skapade nyheter:</p>
        </section>
        <section className="right-section">
          <input
            type="text"
            name="search-filter-field"
            className="searchFilterbtn"
            placeholder="Sök efter nyhet här"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() =>
                document
                  .getElementById("filterDropdown-role")
                  .classList.toggle("show")
              }
            >
              <img
                className="dropbtn-filtericon"
                src={filterIcon}
                alt="filter-icon"
              ></img>
              Filtrera roll
            </button>
            <div
              id="filterDropdown-role"
              className="dropdown-content"
              ref={dropdownRoleRef}
            >
              <a
                href="#"
                onClick={() => handleFilterClickRole("role", "admin")}
              >
                Administratör
              </a>
              <a
                href="#"
                onClick={() => handleFilterClickRole("role", "manager")}
              >
                Föreståndare
              </a>
              <a href="#" onClick={() => handleFilterClickRole("role", "user")}>
                Medarbetare
              </a>
              <a href="#" onClick={() => handleFilterClickRole("", "")}>
                Alla
              </a>
            </div>
          </div>
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() =>
                document
                  .getElementById("filterDropdown-unit")
                  .classList.toggle("show")
              }
            >
              <img
                className="dropbtn-filtericon"
                src={filterIcon}
                alt="filter-icon"
              ></img>
              Enhet
            </button>
            <div
              id="filterDropdown-unit"
              className="dropdown-content"
              ref={dropdownUnitRef}
            >
              {units.map((unit) => (
                <a
                  key={unit.uid}
                  value={unit.uid}
                  href="#"
                  onClick={() => handleFilterClickUnit("unit", unit.uid)}
                >
                  {unit.name}
                </a>
              ))}
              <a href="#" onClick={() => handleFilterClickUnit("", "")}>
                Alla
              </a>
            </div>
          </div>
        </section>
      </section>
      <NewsListing
        searchQuery={searchQuery}
        filterTypeRole={filterTypeRole}
        filterValueRole={filterValueRole}
        filterTypeUnit={filterTypeUnit}
        filterValueUnit={filterValueUnit}
        theUser={theUser}
      />
    </Template>
  );
}

export default NewsPage;
