import React, { useEffect, useState } from "react";
import DocumentPageTemplate from "../template/Documents/DocumentPageTemplate.js";
import {
  fetchDocs,
  getContentInDirAndSubdirs,
  humanreadablePath,
  getFilteredContent,
  fetchDocsData
} from "../../../store/actions/fetchDocs";
import { doc } from "firebase/firestore";
import "../../../assets/styles/Listing/template/DocumentListingTemplate.scss";
import {env, foo, invalidString} from "../../../env";
import Icons from "../../../assets/images/icons/Icons";
import {useSelector} from "react-redux";
import {elevatedRoles} from "../../../store/firebase/roleEnums";

const DocumentPageListing = ({ unReadablePath, readablePath, unit, allUnits, dirs, loading, upcount, setUpCount }) => {
  const user = useSelector((state) => state.account.auth);
  const [docs, setDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [debugNum, setDebugNum] = useState(0);
  const [queries, setQueries] = useState(0);

  useEffect(() => {
    let queryUnit = unit
    if (elevatedRoles.includes(user.role)) queryUnit = null
    if (upcount > queries)
    setQueries(queries +1)
    getContentInDirAndSubdirs(queryUnit, user.role, 100).then((docs => {
      // docs.map(doc => {console.debug(doc)})
      setDocs(docs)
    }))
  }, [user.role, upcount, queries]);

    useEffect(() => {
        if (invalidString.includes(readablePath) && invalidString.includes(unReadablePath) && invalidString.includes(unit) ) {
            setFilteredDocs(docs);
            setDebugNum(1)
        } else if (!docs || docs.length === 0) {
            setFilteredDocs([]);
            env.verbose?console.log("Inga dokument tillgängliga"):{}
            setDebugNum(2)
        } else if (unReadablePath !== "") {
            const filteredDocuments = docs.filter(doc => doc.path && doc.path.includes(unReadablePath));
            setFilteredDocs(filteredDocuments);
            setDebugNum(3)
            env.verbose?console.debug(filteredDocuments, docs):{}
        } else {

            const filteredDocuments = docs.filter(doc => doc && doc.path?.includes(unit) )
            setFilteredDocs(filteredDocuments);
            setDebugNum(4)
        }
    }, [docs, readablePath, unReadablePath, unit, upcount]);

    const getUnitName = (document) => {
        const matchingUnit = allUnits.find(unit => unit.uid === document.unit);
        return matchingUnit ? matchingUnit.name : 'odefined';
    };
    const icons = Icons()

    // console.log({unit, dirs, allUnits, filteredDocs})
    return (<section className="folder-document-row-spacing">

        {!env.devmode ? <></> :
          <section className="debug-text">
            <section>debug: #
              {debugNum} |
              uploadcount: {upcount} |
              querycount: {queries} |
              docs: {filteredDocs.length} / {docs.length} | {getUnitName(unit)}
            </section>
            <section>unReadablePath="{unReadablePath}" | readablePath="{readablePath}"</section>
          </section>
        }

          {filteredDocs.length === 0 ? <></> :
            <section>
              <h1>Dokument,</h1>
              <section className="user-list">
                {filteredDocs.map((doc) =>
                  <DocumentPageTemplate
                    key={doc.path}
                    document={doc}
                    readablePath={readablePath}
                    UnitName={getUnitName(doc)}
                    allUnits={allUnits}
                    dirs={dirs}
                    upCount={upcount}
                    setUpCount={setUpCount}
                  />
                )}
              </section>
            </section>
          }
      </section>
    )
}



export default DocumentPageListing;
