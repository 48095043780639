import React, {useEffect, useState} from "react";
import FolderIcon from "../../../assets/images/icons/folder-icon.png";
import BuildingLevel1FolderTemplate from "../template/Folders/BuildingLevel1FolderTemplate";
import {env} from "../../../env";
import {useSelector} from "react-redux";
import {getSubDirsRecursive} from "../../../store/actions/fetchDocs";

const BuildingLevel1FolderListing = ({ building, docPath }) => {
  const user = useSelector(state => state.account.theUser);
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    let ignore = false
    if (ignore) return ;
    if (!ignore  && docPath)
      getSubDirsRecursive(docPath, user.role, building.id)
      .then((docs) => {

        setFolders(docs.filter(d => (d.unit === building.id)))
      })
    return () => {ignore = true}
  }, [setFolders, docPath, user.role]);

  if (env.verbose) console.assert(folders?.length < 1, '\nid', building.id, '\npath:', docPath)
  if (!folders) return env.devmode ? <>Debug: Inga mappar att visa</> : <></>
  if (folders.length === 0) return env.devmode ? <>Debug: Inga mappar att visa</> : <></>
  console.log(folders.length, (folders.length === 1 ? ("Folder") : ("Folders")))
  return (
    <div className="folder-list">
      {folders.map((item) => (
        <BuildingLevel1FolderTemplate
          key={item.id}
          icon={FolderIcon}
          path={item.path}
          title={item.id}
          building={building}
          folderId={item.id}
          hasSubFolder={item.hasSubFolder || true}
        />
      ))}
    </div>
  );
};

export default BuildingLevel1FolderListing;
