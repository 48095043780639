import React from "react";
import "../../assets/styles/Menues/CardMenu.scss";

const CardMenu = ({ icon, title }) => {
  const isIconEmoji = typeof icon === "string" && icon.length < 10;
  return (
    <div className="card">
      <div className="card-icon">
        {isIconEmoji ? icon : <img className="card-icon-png" src={icon} alt={`${title} icon`} />}
      </div>
      <div className="card-title">{title}</div>
    </div>
  );
};

export default CardMenu;
