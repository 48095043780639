import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import "../../../../assets/styles/Listing/template/BuildingLevel1DocumentTemplate.scss";
import "../../../../assets/styles/Popup/PopupRemoveFolder.scss"
import {humanreadablePath} from "../../../../store/actions/fetchDocs";
import documentIcon from "../../../../assets/images/icons/document-icon.png"
import Swal from 'sweetalert2';
import env from "../../../../env";
import {deleteDocFully, disableDoc} from "../../../../store/actions/setDocs";
import PopupConfirmDeletion from "../../../Popups/PopupConfirmDeletion";

const FolderPageTemplate = ({ user, folder, documents, units }) => {

    const [docIcon, setDocIcon] = useState(documentIcon);
    const [affectedDocs, setAffectedDocs] = useState([]);
    const [affectedUnit, setAffectedUnit] = useState([]);
    const [deleted, setDeleted] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {

        try {
            const affectedDocs = documents.filter(doc => doc.fullpath.startsWith(folder.path));
            setAffectedDocs(affectedDocs)
        }
        catch (error) {
            console.error("OhNo:", error);
        }

    }, []);

    /**Returnera berörda dokument */
    const getAffectedDocuments = () => {
        const affectedDocs = documents.filter(doc => doc.fullpath?.startsWith(folder.path));
        setAffectedDocs(affectedDocs);
        return affectedDocs;
    };
    /**Returnera enhetsnamn */
    const getFolderUnitName = () => {
        const unit = units.find(unit => unit.uid === folder.unit);
        if (unit) {
            setAffectedUnit(unit);
            return unit.name;
        }
        return '';
    };
    function handleDelete() {
        console.warn('DEL ERR')
        deleteDocFully(folder.path).then(() => {
            setDeleted(true)
        })
    }

    const handleClick = async (e) => {
        const { id } = e.target;

        switch (id) {
            case 'delete':
                try {

                    setAffectedDocs(documents);

                } catch (error) {
                    console.error("OhNo: ", error);
                }
                break;
            default:
                break;
        }
    };
    /** Fråga om användaren vill radera allt eller flytta dokument */
    const openPopup = () => {
        const AffectedDocs = getAffectedDocuments();
        const folderUnitName = getFolderUnitName();
        const folderRadablePath = humanreadablePath(folder.path, folder.unit, '')

        const popupHtml = `
        <section class="popupRemoveFolder-select-column-container">
            <h1>${folderUnitName}</h1>
            <h2 class="popupRemoveFolder-headline">${AffectedDocs.length > 0 ? `Du har ${AffectedDocs.length} dokument i mappen.` : 'Mappen innehåller inga dokument.'}</h2>
            <strong>
                <p class="popupRemoveFolder-subtext">
                    Om du raderar mappen ${folderRadablePath} ${AffectedDocs.length > 0 ? 'kommer alla dokument och undermappar att försvinna. Behåll dokumenten genom att flytta plats på dem innan du raderar mappen.' : 'kommer alla undermappar att försvinna.'}
                </p>
            </strong>
        </section>
        <button id="delete-btn" class="popupRemoveFolder-submit-btn-delete">Radera allt innehåll</button>
        ${AffectedDocs.length > 0 ? '<button id="review-btn" class="popupRemoveFolder-submit-btn-edit">Granska & flytta dokument</button>' : ''}
        `;
        Swal.fire({
            html: popupHtml,
            showConfirmButton: false,
            didOpen: (popup) => {
                const deleteButton = popup.querySelector('#delete-btn');
                const reviewButton = popup.querySelector('#review-btn');

                deleteButton.addEventListener('click', () => {

                    !env.devmode?{}: console.log("KEVIN: Radera alla dokument/undermappar och skit i konsekvenserna..", folder);
                    disableDoc(folder.fullpath).catch(e => {
                        env.devmode ? console.error(e) : console.error("Det gick inte att ta bort mappen")
                    })
                    .then(() => {
                        console.log('Dokument borttaget')
                        setDeleted(true)
                        Swal.close()
                    })
                });
                if (reviewButton) {
                    reviewButton.addEventListener('click', () => {

                        navigate('/raderamapp', { state: { folder, documents: affectedDocs, UnitName: folderUnitName, allUnits: units } });
                        Swal.close();
                    });
                }
            }
        });
    };

    return deleted ? <></> : (
        <section className="folder-document-row-container">
            {/* Tillagd container klass */}
            <section className="folder-document-row">

                <section className="folder-document-cell folder-document-icon">
                    <img src={docIcon} alt={`${document.id} icon`}
                        id='icon' onClick={handleClick} />
                </section>
                <section id='title' className="folder-document-cell folder-document-folder" onClick={handleClick}>
                    {/*document.fileName || document.title}{/* "id: " {document.id}*/}

                    {humanreadablePath(folder.path, folder.unit, '') || "/" + folder.name + ' (ny) '}
                    {!env.devmode? <></> : ' [' + folder.path + ']'}

                </section>
                <section className="folder-document-cell folder-document-title">

                </section>
                <section className="folder-document-cell folder-document-action">
                    {env.devmode ? <button onClick={handleDelete} style={{backgroundColor: 'black'}}>devdelete</button>: <></>}
                    <button onClick={openPopup} className="folder-document-download-btn delete">
                        Radera
                    </button>
                </section>
            </section>
            {/*eslint-disable-next-line*/}
            <a id="" className="hidden"></a>
        </section>
    );
};

export default FolderPageTemplate;