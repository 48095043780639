import React, { useEffect, useState } from "react";
import FolderPageTemplate from "../template/Folders/FolderPageTemplate";
import {
    fetchDocs,
    getContentInDirAndSubdirs,
    humanreadablePath,
    getFilteredContent,
    fetchDocsData
} from "../../../store/actions/fetchDocs";
import { doc } from "firebase/firestore";
import "../../../assets/styles/Listing/template/DocumentListingTemplate.scss";
import { env, foo, invalidString } from "../../../env";
import Icons from "../../../assets/images/icons/Icons";
import { useSelector } from "react-redux";
import { elevatedRoles } from "../../../store/firebase/roleEnums";

const FolderPageListning = ({ unitDirs, documents, units, theUnit, addedDocs }) => {


    /*Användardata från globalt state*/
  const user = useSelector((state) => state.account.theUser);

    /*Mappar*/
  const [docs, setDocs] = useState([]);
  const filteredDocs = unitDirs.filteredDirs
  {/*eslint-disable-next-line*/}
  addedDocs.map(ad => (
    filteredDocs.map(fd=>(fd.path)).includes(ad.path) ? {} : filteredDocs.push(ad)
  ));


  !env.verbose?{}:console.log(foo.kd + 'added:', addedDocs.map(sf=> (" /" + sf.name)).toString())
  !env.verbose?{}:console.log(foo.kd + 'filtered dirs', unitDirs.filteredDirs.map(sf=> ("/" + sf.name)).toString() )
  !env.verbose?{}:console.log(foo.kd + 'all dirs', unitDirs.dirs.map(sf=> (" /" + sf.name)).toString() )

  return (
    <section className="folder-document-row-spacing">
      <h1>{theUnit ? 'Mappar ' + theUnit : 'Mappar:'}</h1>

      {!env.devmode ? <></> : <section className="debug-text">
        <section>(debug) added: {addedDocs.map(a => (a.name + ", ") ) } ||</section>
        <section>dirs: {unitDirs.filteredDirs.map(sf=> ("/" + sf.name + " | " ))} ||</section>
      </section>}

      <section className="user-list">
        {unitDirs.filteredDirs.map(sf => (
          <section key={sf.path} id={sf.id}>
            <FolderPageTemplate user={user} folder={sf} documents={documents} units={units} />
          </section>
        ))}
      </section>
    </section>
    );
};

//{humanreadablePath(sf.path, sf.unit, '')}{/*sf.path*/}
export default FolderPageListning;
