import React from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "../../assets/styles/Popup/PopupConfirmDeletion.scss";

const PopupConfirmDeletion = ({word, handleDelete}) => {
  const openPopup = () => {
    Swal.fire({
      html: `
                <section class="popup-confirm-deletion-select-column-container">
                    <h1 class="">Är du säker på att du vill radera ${word}?</h1>
                </section>
               
               <p>Detta går ej att ångra</p>
               
                <section class="popup-confirm-deletion-btns">
                    <button id="cancel-btn" class="cancelBtn">Avbryt</button>
                    <button id="delete-btn" class="deleteBtn">Radera</button>
                </section>
            `,
      showConfirmButton: false,
      didOpen: (popup) => {
        const deleteButton = popup.querySelector('#delete-btn');

        deleteButton.addEventListener('click', () => {
          Swal.close();
          handleDelete()
        });

        const cancelButton = popup.querySelector('#cancel-btn');

        cancelButton.addEventListener('click', () => {
          Swal.close();
        });

      }
    });
  };

  return (
    <button onClick={openPopup} className="deleteBtn">
      Radera
    </button>
  );
};

export default PopupConfirmDeletion;