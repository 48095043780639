import {deleteObject, getBlob, getDownloadURL, getStorage, listAll, ref, uploadBytesResumable} from "firebase/storage";
import {env, foo} from "../../env";
import {getAuth} from "firebase/auth";

require('../firebase/firebaseConfig')
const storage = getStorage()

export const storageDirectories = {
  profilepics: 'public/profile_pictures/',
  unitimages: 'public/units/',
  documents: 'files/',
  news: 'news/',
}

export const toUploadDefault = {
  image: { name: 'Ladda upp en bild' },
  file: { name: 'Ladda upp en fil' }
}

export function checkIfFileExists(filepath) {
  const fileRef = ref(storage, filepath)
  return listAll(fileRef).then(r => {
    if (r.items.length === 0) return false
    else return r.items.length
  })
}

export function getUploadTask(path, file, fileName) {
  if (!path) throw new Error('No path')
  if (!file) throw new Error('No file')
  if (file.name === undefined && !fileName) throw new Error('File name is undefined')
  if (!fileName) fileName = file.name
  const storageRef = ref(storage, path + fileName)
  const uploadTask = uploadBytesResumable(storageRef, file)
  let prevProgress = 0
  uploadTask.on('state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progressVerbose = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      const progress = String(progressVerbose)?.split(".")[0]
      // console.log('Upload is ' + progress + '% done');
      let started = false

      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused at' + progress + '% done');
          break;
        case 'running':
          started ? console.log('Upload started') : started = true
          if (progress < prevProgress) {
            prevProgress = progress
            // console.log('Upload is running,', progress + '% done');
          }
          break;
        default: break
      }
    },
    (error) => {
      switch (error.code) {
        case 'storage/canceled':
          console.log('Upload canceled')
          break
        default:
          console.error(error)
          break
      }
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    })
  return uploadTask
}

export function uploadFile(path, file, fileName) {
  console.debug('file', file)
  //to do validera filer
  const uploadTask = getUploadTask(path, file, fileName)

  return uploadTask.then((snapshot) => {
    console.log('Uploaded a blob or file!', snapshot.metadata.fullPath)
    return snapshot.metadata.fullPath
  })
    .catch((e) => {
      console.error(e)
      throw e
    })
}

export function deleteFile(path) {
  const storageRef = ref(storage, path)
  return deleteObject(storageRef)
  .then(()=> {
    console.log(path, 'deleted')
    return 200
  })
  .catch(e => {throw e})
}

export async function getFilesImagePathUrl(docs) {
  const docsWithUrl = await docs.map(doc => {
    return getFileUrl(doc.imagePath)
      .then(img => ({ ...doc, img }))
  })
  return docsWithUrl
}

// to do errorhantering
// to do cache
export function getFileUrl(filepath, role) {
  if (!filepath) {
    console.error("No file specified")
    return undefined
  }
  if (!role){
    // console.error("No role specified")
    // throw new Error('No role specified')
    const json = JSON.parse(getAuth().currentUser.reloadUserInfo.customAttributes)
    role = json.role
  }
  if (env.verybose) console.debug(foo.kd, 'getting URL...', filepath)
  const pathRef = ref(storage, filepath)
  return getDownloadURL(ref(pathRef.parent, pathRef.name))
    .then(res => (res))
    .catch((error) => {
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          console.error(error.code, 'filepath:', filepath)
          return ''
        case 'storage/unauthorized':
          throw error
          // case 'storage/canceled': break
        case 'storage/unknown':
          throw new Error(error, pathRef.fullPath)
        case 'storage/invalid-root-operation':
          throw new Error(error, pathRef.fullPath)
        default:
          // console.error(error.code)
          throw error
      }
    })
}

export function getFileRef(filepath, promise) {
  if (promise) return getFileRefAsPromise(filepath)
  if (!filepath) { throw new Error("No file specified") }
  return ref(storage, filepath)
}

export function getFileRefAsPromise(filepath) {
  return new Promise((resolve, reject) => {
    if (filepath) resolve(ref(storage, filepath))
    if (!filepath) reject( new Error("No file specified")  )
  })
}

export function prepDownload(fileRef, link) {
  if (!fileRef) { throw new Error("No file specified") }
  if (!link) { throw new Error("No <a> link specified (" + fileRef + ")") }

  return getBlob(fileRef).then(blob => {
    link.href = window.URL.createObjectURL(new Blob([blob]))
    return fileRef.name
  })
}

export function startDownload(fileRef, link) {
  console.debug('Starting download')
  return new Promise((resolve, reject) => {
    if (link) resolve(link.click())
    else reject(new Error('Missing download link'))
  })
// Clean up and remove the link
}
