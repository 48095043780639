import {
  getAuth,
  onAuthStateChanged,
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
} from 'firebase/auth';
import {useState} from 'react';
import type {User} from 'firebase/auth';

type UpdateUserProps = {
  displayName?: User['displayName'];
  photoURL?: User['photoURL'];
  email?: User['email'];
  password?: string | null;
};

const useUser = (): {
  user?: User | null;
  updateUser: (props: UpdateUserProps) => Promise<void>;
  logout: () => Promise<void>;
} => {
  const auth = getAuth();
  const [user, setUser] = useState<User | null>(auth.currentUser);

  onAuthStateChanged(auth, setUser);

  const updateUser = async (
    {
      displayName,
      photoURL,
      email,
      password,
    }:
      UpdateUserProps) => {

    if (!user)
      return;


    if (displayName)
      await updateProfile(user, {displayName});
    if (photoURL)
      await updateProfile(user, {photoURL});
    if (email)
      await updateEmail(user, email);
    if (password)
      await updatePassword(user, password);
  }

  const logout = async () => {
    await signOut(auth);
  };

  return {user, updateUser, logout};
};

export default useUser;