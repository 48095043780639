import { doc, getFirestore, setDoc, Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import env from "../../env";


export function setDirectory(data, consoleReturn) {
  if (env.verbose) console.debug('formData', data.formData)
  if (consoleReturn === undefined) consoleReturn = true
  const d = data.formData
  const subroot = 'folders'

  const aref = {
    title: "test",
    role: "user",
    unitId: "testunit",
    folderLocation1: "",
    folderLocation2: "",
    folderLocation3: "",
  }

  // /f/enhetA/dirs/sdir/docs/doc
  let docRef

  if (d.folderLocation3) {
    if (env.verbose) console.debug(3)
    docRef = doc(getFirestore(), subroot,
      d.unitId, //enhetA
      'content', d.folderLocation1, //sdir
      'content', d.folderLocation2, //sdir
      'content', d.folderLocation3,
      'content', d.title
    )
  }
  else if (d.folderLocation2) {
    if (env.verbose) console.debug(2)
    docRef = doc(getFirestore(), subroot,
      d.unitId, //enhetA
      'content', d.folderLocation1, //sdir
      'content', d.folderLocation2, //sdir
      'content', d.title
    )
  }
  else if (d.folderLocation1) {
    if (env.verbose) console.debug(1)
    docRef = doc(getFirestore(), subroot,
      d.unitId, //enhetA
      'content', d.folderLocation1, //sdir
      'content', d.title
    )
  }
  else {
    if (env.verbose) console.debug(0)
    
    docRef = doc(getFirestore(), subroot,
      d.unitId,
      'content', d.title)
  }

  const docData = {
    name: d.title,
    role: d.role,
    unit: d.unitId,
    creationTime: Timestamp.now(),
    updateTime: Timestamp.now(),
    disabled: false,
    directory: true,
    author: getAuth().currentUser.uid,
  }

  return setDoc(docRef, {...docData})
    .then(() => {
      if (consoleReturn) console.log("doc created with ref:", docRef.id, docRef.path)
      return {docRef: {id: docRef.id}, path: docRef.path, data: docData}
    })
    .catch((e) => {
      throw e
    })
}