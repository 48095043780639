import React from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/Buttons/cta-button.scss";
import "../assets/styles/Buttons/back-buttons.scss"
import backBtn from "../assets/images/icons/back-icon.png";

const Button = ({ text }) => {
  const navigate = useNavigate();

  const goToHome = () => {
    console.log("knapptryck");
    navigate("/");
  };

  // if (text)
  return (
    <button className="home-button" onClick={goToHome}>
      {text}
    </button>
  )
};

export function BackButtonBig({ onClick }) {
  const navigate = useNavigate();
  return (
    <div
        className="back-button-wrapper"
    // onClick={onClick}
    >
      <button
        onClick={(e) => handleBackTemplate(navigate)}
        className="back-button-big"
      >
        Tillbaka
      </button>
    </div>
  )
}

export function BackButton({ onClick }) {
  const navigate = useNavigate();
  return (
    <div className="back-button-wrapperu">
      <button className="back-button"
        onClick={(e) => handleBackTemplate(navigate)}
      >
        {/*<img src={backBtn} alt="Tillbaka" className="back-icon"/>*/}
        {"<"} Tillbaka
      </button>
    </div>
  )
}

export const handleBackTemplate = (navigate) => {
  navigate(-1)
  window.scrollTo(0, 0)
}

export default Button;
