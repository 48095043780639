import React from "react";
import BuildingIcon from "../../../assets/images/icons/folder-icon.png";
import BuildingLevel2FolderTemplate from "../template/Folders/BuildingLevel2FolderTemplate";
import env from "../../../env";

const BuildingLevel2FolderListing = ({ folders, path, name }) => {
  if (!folders) return env.devmode ? <>Debug: Inga mappar att visa</> : <></>
  if (folders.length === 0) return env.devmode ? <>Debug: Inga mappar att visa</> : <></>
  const pathSegments = path.replace('content/', '').split("/")
  return (
    <div className="folder-list">
      {/* <div>pathsegments: {pathSegments.map((segment, i) => (<p>{i + ": " + segment + "  "}</p>))}</div> */}
      {folders.map((item) => (
        <BuildingLevel2FolderTemplate
          key={item.id}
          icon={BuildingIcon}
          title={item.id}
          nextfolder={item.id}
        />
      ))}
    </div>
  );
};

export default BuildingLevel2FolderListing;
