import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "../../assets/styles/Popup/PopupEditDocuments.scss";
import "../../assets/styles/global.scss";
import {getUnitName, humanreadablePath, prettyPath} from "../../store/actions/fetchDocs";
import {env, foo} from "../../env";

const PopupEditDocuments = ({ document, currentPath, handleSelectChange, handleSubmit, allUnits, dirs }) => {
    const [units, setAllUnits] = useState([]);
    const [directories, setDirectories] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('');

    useEffect(() => {
        setAllUnits(allUnits);
        setDirectories(dirs);
    }, [allUnits, dirs]);

    const handleSelectedUnit = (e) => {
        const selectedUid = e.target.value;
        setSelectedUnit(selectedUid);
        handleSelectChange('unitSelect', selectedUid);

        const newFilteredDocs = directories.filter(doc => doc.path.includes(selectedUid));
        console.debug(13371612, 'new', newFilteredDocs)
        console.debug(13371612, 'old', directories)
        setFilteredDocs(newFilteredDocs);
    };

    const handleSelectedDir = (e) => {
        handleSelectChange('dirSelect', e.target.value);
    };

    const openPopup = () => {
        Swal.fire({
            html: `
                <div class="popupEditDocuments-select-column-container">
                
                    <h1 class="popupEditDocuments-headline">
                        Flytta 
                        ${document.title || document.fileName || document.id}
                    </h1>
                    
                    <p class="popupEditDocuments-subtext">
                        Nuvarande mapp:
                        <br>
                        ${currentPath.replace(document.id, document.title || document.fileName)}
                    </p>
                    
                    <select id="unitSelect" class="popupEditDocument-select">
                        <option value="" disabled selected>
                            Välj en huvudenhet
                        </option> ${units.map(option => `
                        <option value="${option.uid}">${option.name}</option>`).join('')}
                    </select>
                    
                    <select id="dirSelect" class="popupEditDocument-select" disabled>
                        <option value="" disabled selected>
                            Välj en undermapp (valfritt)
                        </option>
                    </select>
                    
                </div>
                <button id="submit-btn" class="popupEditDocuments-submit-btn">Ändra information</button>
                    
                <section class="${debugclass(1)}">(debug) mappar (${debugdirs(directories).length}): 
                <section class="${debugclass(2)}">
                    ${debugdirs(directories).map((o,i) => `
<!--                    <section>${prettyPath(o.path, o.unit, dirs, units)}  -->
                    <!--    unit:${o.unit} </br>  -->
                    <!--    pretty:${ prettyPath(o.path, o.unit, dirs, units) } -->
                    <!--  fileName:${o.fileName} </br> -->
<!--                    </section>-->`).join('')}
                </section>
                </section>
                
            `,
            showConfirmButton: false,
            didOpen: async (popup) => {
                const selectUnit = popup.querySelector('#unitSelect');
                const selectDir = popup.querySelector('#dirSelect');
                const submitButton = popup.querySelector('#submit-btn');
                selectUnit.addEventListener('change', async (e) => {
                    handleSelectedUnit(e);
                    if (e.target.value) {
                        const newFilteredDocs = directories.filter(doc => doc.path.includes(e.target.value));
                        setFilteredDocs(newFilteredDocs);
                        selectDir.innerHTML = `
                            <option value="" disabled selected>Välj en undermapp (valfritt)</option>
                            ${newFilteredDocs.map(option => `
                                <option value="${option.fullpath}">
                                    ${ prettyPath(option.fullpath, option.unit, newFilteredDocs, units)
                                        /*.replace(option.id, option.fileName )*/}                                </option>`).join('')
                            }
                        `;
                        selectDir.disabled = false;
                    } else {
                        selectDir.disabled = true;
                    }
                });

                selectDir.addEventListener('change', handleSelectedDir);

                submitButton.addEventListener('click', () => {
                    handleSubmit(selectUnit.value, selectDir.value);


                    Swal.close();
                });
            }
        });
    };

    return (
        <button onClick={openPopup} className="folder-document-download-btn edit">
            Ändra
        </button>
    );
};

const debugclass = (i) => {
    switch (i) {
        case 0: return ''
        case 1: return "debug-text-popup-1"
        case 2: return "debug-text-popup-2"
        case 25: return 'opacity-25'
        case 50: return 'opacity-50'
        case 75: return 'opacity-75'
        case 100: return 'opacity-100'
        default: return 'debug-text'
    }
}
const debugdirs = (input) => {
    return env.devmode ? (input) : []
}


export default PopupEditDocuments;
