import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeroImage from "../components/Building/BuildingHeroImage";
import UserIcon from '../assets/images/icons/user-icon.png'
import { fetchDocData, fetchDocsData } from "../store/actions/fetchDocs";
import Template from "../../src/pages/template/Template";
import backBtn from ".././assets/images/icons/back-icon.png";
import "../assets/styles/Pages/Buildings/BuildingContactPage.scss";
import useProfileImage from "../hooks/images/userProfileImage";
import { getFileUrl } from "../store/actions/handleStorage";
import companyLogotype from "../assets/images/logotyp/company-logotype.png";
import { env, foo, invalidString, invalidUnitIds } from "../env";
import {BackButton, handleBackTemplate} from "../components/Button";

function BuildingContactPage() {
  const [users, setUsers] = useState([]);
  const [img, setImg] = useState(companyLogotype);
  const location = useLocation();
  const navigate = useNavigate();
  const [profileImages, setProfileImages] = useState({});
  // const { /*building,*/ currentBuilding } = location?.state;
  const building = location?.state?.currentBuilding


  useEffect(() => {
    let ignore = false;
    if (!ignore && building)
      fetchDocsData("users").then((res) => {
        env.devmode ? console.debug('building', building) : {}
        setUsers(
          res.filter((userItem) =>
            userItem.unitId === building.uid || userItem.role === "admin" || userItem.role === "superadmin"
          )
        )
      });
    return () => { ignore = true };
  }, [building]);

  useEffect(() => {
    env.verbose ? console.debug(20, foo.kd, building):{}
    if (!building) return ;
    else if (!building.uid || Object.keys(building).length === 0) return;
    else if (!invalidString.includes(building.uid))
      fetchDocData('units', building.uid).then(doc => {
        getFileUrl(doc.imagePath).then(url => { setImg(url) })
      }).catch((e) => { console.warn(e) })
    return () => { };
  }, [building?.uid])

  useEffect(() => {
    const fetchProfileImages = async () => {
      const images = {};
      for (const user of users) {
        if (user.imagePath) {
          const url = await getFileUrl(user.imagePath);
          images[user.uid] = url;
        } else {
          images[user.uid] = UserIcon;
        }
      }
      setProfileImages(images);
    };

    fetchProfileImages();
  }, [users]);

  function goToBuilding() {
    // navigate(0)
    navigate('/enhet/' + building.name)
  };
  // if (env.verbose) console.debug("building", building, img);
  const uniqueUsers = new Set();

  const renderUsers = (items, roles) => {
    return items.map((item) => {
      if ((roles.includes(item.role) || item.unit === "Alla") && !uniqueUsers.has(item.id)) {
        uniqueUsers.add(item.id);
        const profileImage = profileImages[item.uid];
        return (
          <div key={item.id} className="contact-card">
            <img
              src={profileImage}
              alt="Profile"
              className="profile-picture"
            />
            <div className="contact-info">
              <div className="contact-name-title">
                <p className="contact-name">
                  {item.firstName} {item.lastName}
                </p>
                <p className="contact-title">{item.title}</p>
              </div>
              <div className="contact-details">
                <p className="contact-phone">
                  <a
                    className="contact-phone-link"
                    href={`tel:${item.phoneNr}`}
                  >
                    {item.phoneNr}
                  </a>
                </p>
                <p className="contact-phone">
                  <a
                    href={`mailto:${item.email}`}
                    className="contact-email-link"
                  >
                    {item.email}
                  </a>
                </p>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  // console.log("building", building)
  return (
    <div>
      <HeroImage
        url={img}
        title={building?.name || ''}
        description={building?.description || ''}
      />
      <div className="building-contact-page-menu">
        {/*<BackButton />*/}
        <div className="back-button-wrapperu">
          <button className="back-button" onClick={goToBuilding}>
            {"<"} Tillbaka
          </button>
        </div>
      </div>
      <div className="building-contact-page-layout">
        <div className="building-contact-content">
          <h1 className="contact-headline">Kontaktuppgifter {building?.name || ''}</h1>
          <div className="building-contact-management">
          <h1>Ledningen</h1>
            <div className="card-contact-container">
              {/*eslint-disable-next-line*/}
              {renderUsers(users, ["admin", "superadmin", "manager"])}
            </div>
          </div>
          <div className="building-contact-staff">
            <h1 className="contact-headline">Medarbetare</h1>
            <div className="card-contact-container">
              {/*eslint-disable-next-line*/}
              {renderUsers(users, ["user"])}
            </div>
          </div>
        </div>
      </div>
      <Template>
        {/*<h1>test</h1>*/}
      </Template>
    </div>
  );
}

export default BuildingContactPage;
