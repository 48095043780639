import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Template from "./template/Template";
import BuildingLevel2FolderListing from "../components/Listing/Folders/BuildingLevel2FolderListing";
import BuildingLevel2DocumentListing from "../components/Listing/Documents/BuildingLevel2DocumentListing";
import {fetchDoc, getSubDirs, getUnitByName, humanreadablePath} from "../store/actions/fetchDocs";
import {env, invalidUnitIds} from "../env";
import {getAuth} from "firebase/auth";
import {useSelector} from "react-redux";
import {setName} from "../store/reducers/unitReducer";

const BuildingLevel2FolderPage = () => {
  const { buildingId, folderId } = useParams();
  const [building, setBuilding] = useState()
  const name = useSelector(state => state.unit.name)
  const [folders, setFolders] = useState([])

  useEffect(() => {
    if (!invalidUnitIds.includes(buildingId)) {
      if (buildingId.includes('EQ')) {
        getUnitByName(buildingId).catch(e => console.warn(e))
        .then(unit => {
          if (env.verbose) console.debug('setting b name:', unit.name, unit)
          setBuilding(unit)
        })
      }
      else {
        fetchDoc('units', buildingId, 'onlydata')
        .then(unit => {
          if (env.verbose) console.debug('setting b name:', unit.name)
          setBuilding(unit)
        })
      }
    }
  }, [buildingId])

  const directory = "folders" + "/" //+ folderId + "/"
  const currentUser = getAuth().currentUser
  const json = JSON.parse(currentUser.reloadUserInfo.customAttributes)
  const userRole = json.role
  let ignore = false

  const [docPath, setDocPath] = useState('')
  const [prevId, setPrevId] = useState('1')

  useEffect(() => {
    if (ignore || docPath === '' || docPath === directory) return;
    if (env.verbose) console.debug('foo docpath', docPath)
    getSubDirs(docPath, userRole, true).then(dirs => {
      console.log(dirs.length, (dirs.length === 1 ? ("Folder") : ("Folders")))
      setFolders(dirs)
    })
    // getContentInDir(docPath, userRole, true).then(docs => {
    //   console.log(docs.length, docs.length === 1 ? ("Document") : ("Documents"))
    //   setDocs(docs)
    // })
    return () => { ignore = true }
  }, [docPath])

  useEffect(() => {
    if (prevId === buildingId || buildingId === "1") {
      if (env.verbose) console.debug('foo id:', buildingId)

    }
    else if (building?.id !== undefined) {
      setPrevId(buildingId)
      if (env.verbose) console.debug('foo', 'id', buildingId, 'user', userRole)
      const docPathToSet = directory + building?.id + "/content/" + folderId
      if (buildingId !== undefined) setDocPath(docPathToSet)
    }
  }, [building, buildingId,  directory, folderId, prevId,  userRole])

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  // Logik för att hämta och visa undermappar baserat på 'buildingId' och 'folderId'
  return (
    <div>
      <Template>
        <h1>{name} - Mappar & Dokument</h1>
        < Link className={"back-button"} to={"/enhet/" + buildingId + "/mappar"}>{"<"} Tillbaka</Link>
        <h1>{folderId}</h1>
        <BuildingLevel2FolderListing path={docPath} folders={folders} name={building?.name}/>
        <BuildingLevel2DocumentListing
                                       docPath={docPath}
                                       directory={directory}
                                       userRole={userRole}
                                       buildingId={building?.id}
                                       buildingName={building?.name}
        />
      </Template>
    </div>
  );
};

export default BuildingLevel2FolderPage;
