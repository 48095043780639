import React, { useEffect, useState } from "react";
import DocumentIcon from "../../../assets/images/icons/document-icon.png";
import DocumentHeadlineTemplate from "../template/BuildingDocumentHeadlineTemplate";
import "../../../assets/styles/Listing/template/BuildingDocumentHeadlineTemplate.scss";
import {
  fetchDoc,
  fetchDocDataSerializeable,
  getContentInDirAndSubdirs,
  humanreadablePath
} from "../../../store/actions/fetchDocs";
import { getAuth } from "firebase/auth";
import { env, foo, invalidUnitIds } from "../../../env";
import {useSelector} from "react-redux";


const BuildingDocumentHeadlineListing = ({ id, limit }) => {
  const [files, setFiles] = useState([])
  const [unit, setUnit] = useState()
  const [role, setRole] = useState('user')
  /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
  const user = useSelector((state) => state.account.theUser)

  const unvalidUnitIds = [invalidUnitIds.map(i => (i)), '', ' ']

  useEffect(() => {
    if (id !== undefined && !unvalidUnitIds.includes(id))
      fetchDocDataSerializeable("units/", id, true).then(udoc => { setUnit(udoc) })
  }, [id])

  useEffect(() => {setRole(user?.role)}, [user]);

  useEffect(() => {
    if (!user) return;
    if (unit && user.role) getContentInDirAndSubdirs(unit.id, role, limit)
      .then(res => { setFiles(res) })
  }, [unit, user])

  function getLinkToParent(item) {
    let res = humanreadablePath(item.path)
    const resAsArr = res.split('/')
    const unit = resAsArr[0]
    const pathsegments = resAsArr.slice(1, resAsArr.length-1).join("/")
    const firstPartOfLink ='/enhet/' + unit + "/mappar/"
    const link = firstPartOfLink + pathsegments
    console.log(res, resAsArr, link )
    return link
    // const pathsegmentsWithOutFileName = humanreadablePath(path).replace("/" + title, '').split("/")
    // // console.debug(foo.kd, title, "path:", path, pathsegmentsWithOutFileName, pathsegmentsWithOutFileName.length)
    // const firspartOfURL = pathsegmentsWithOutFileName.length == 1 ? "/enhetsmappar/" : "/byggnad/"
    // const secondPartOfURL = String(pathsegmentsWithOutFileName.map(s => (s + "/"))).replace(",", "").replace(",", "").replace(",", "").replace(",", "").replace(",", "")
    // const link = firspartOfURL + secondPartOfURL
  }

  if (files.length === 0) return (<p key="1">Inga dokument att visa</p>)
  console.debug(files)
  return (
    <div className="">
      {/* <p>{unit.id}  {unit.name}</p> */}
      {files.map((item) => (
        <DocumentHeadlineTemplate
          key={item.path || item.id}
          icon={DocumentIcon}
          title={item.title || item.fileName || item.id}
          inFolder={humanreadablePath(item.path, unit.id, unit.name).replace(item.id, '')}
          pathsegments={humanreadablePath(item.path).split("/")}
          DocumentLink={getLinkToParent(item)}
          link={item.DocumentLink || item.id}
          path={item.path}
          filepath={item.filePath}
        />
      ))}
    </div>
  );
};

export default BuildingDocumentHeadlineListing;
