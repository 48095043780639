import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore, initializeFirestore, CACHE_SIZE_UNLIMITED,
  persistentLocalCache,
  persistentMultipleTabManager } from 'firebase/firestore'
import {store} from "../reducers/rootreducer";
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import {getFunctions} from "firebase/functions";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
  location: "europe-north1"
}

/** dev db */
export const config2 = {
  apiKey: process.env.REACT_APP_ALT_APIKEY,
  authDomain: process.env.REACT_APP_ALT_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_ALT_DATABASEURL,
  projectId: process.env.REACT_APP_ALT_PROJECTID,
  storageBucket: process.env.REACT_APP_ALT_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_ALT_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_ALT_APPID,
  location: "europe-north1"
}
const useCache = process.env.REACT_APP_USE_CACHE === 'true'

const withCache = {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager()
  })
}

export const app = initializeApp(config)
// export const db = getFirestore(app)
export const db = initializeFirestore(app, useCache ? withCache : {localCache: false})
export const storage = getStorage(app)
export const auth = getAuth(app)
export const functions = getFunctions(app, "europe-north1")

const emulator = () => {
  // eslint-disable-next-line no-restricted-globals
  if (
    window.location.hostname === 'localhost'
    && process.env.REACT_APP_EMULATE_FIREBASE
  ) {
    connectAuthEmulator(auth, 'http://127.0.0.1:9099')
    connectFirestoreEmulator(db, '127.0.0.1', 8070)
    connectStorageEmulator(storage, '127.0.0.1', 9199)
    return "default login w/ emulator: (http://localhost:1337/auth) (https://firebase.google.com/docs/emulator-suite)\nuser: dev@infid \npass: qweqwe"
  } else if (window.location.hostname === 'localhost') return 'using remote db'
}
console.debug(emulator())

export default firebase