import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import Template from "./template/Template";
import BuildingLevel3FolderListing from "../components/Listing/Folders/BuildingLevel3FolderListing";
import BuildingLevel3DocumentListing from "../components/Listing/Documents/BuildingLevel3DocumentListing";
import { fetchDoc, getSubDirs, humanreadablePath } from "../store/actions/fetchDocs";
import { getAuth } from "firebase/auth";
import {env, invalidUnitIds} from "../env";
import {useSelector} from "react-redux";

const BuildingLevel3FolderPage = () => {
  const { buildingId, folderId, level2ID } = useParams();
  const [folders, setFolders] = useState([])
  const name = useSelector(state => state.unit.name)
  const unitId = useSelector(state => state.unit.id)

  const root = "folders" + "/"
  const directory = root //+ folderId + "/"
  const currentUser = getAuth().currentUser
  const json = JSON.parse(currentUser.reloadUserInfo.customAttributes)
  const userRole = json.role
  let ignore = false

  const [docPath, setDocPath] = useState('')
  const [prevId, setPrevId] = useState('1')

  useEffect(() => {
    if (ignore || docPath === '' || docPath === directory) return;
    if (env.verbose) console.debug('foo docpath', docPath)
    getSubDirs(docPath, userRole, true).then(dirs => {
      console.log(dirs.length, (dirs.length < 2 ? ("Folder") : ("Folders")))
      setFolders(dirs)
    })
    return () => { ignore = true }
  }, [docPath])

  useEffect(() => {
    if (prevId === unitId || unitId === "1") {
      if (env.verbose) console.debug('foo id:', unitId)

    }
    else {
      setPrevId(unitId)
      if (env.verbose) console.debug('foo', 'id', unitId, 'user', userRole)
      const docPathToSet = directory + unitId + "/content/" + folderId + "/content/" + level2ID
      if (unitId !== undefined) setDocPath(docPathToSet)
    }
  }, [unitId])

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  console.assert(unitId !== undefined, 'unitid undefined')

  return (
    <div>
      <Template>
        <h1>{name} - Mappar & Dokument</h1>
        < Link className={"back-button"} to={"/enhet/" + buildingId + "/mappar/" + folderId}>{"<"} Tillbaka</Link>
        <h1>{level2ID}</h1>
        <BuildingLevel3FolderListing id={folderId} id2={level2ID} path={docPath} folders={folders}/>
        <BuildingLevel3DocumentListing id={folderId}
                                       docPath={docPath}
                                       directory={directory}
                                       userRole={userRole}
        />
      </Template>
    </div>
  );
};

export default BuildingLevel3FolderPage;
