import React from "react";
import {Link, useParams} from "react-router-dom";
import "../../../../assets/styles/Listing/template/BuildingFolderHeadlineTemplate.scss";

const BuildingLevel3FolderTemplate = ({
  icon,
  title,
                                        nextfolder,
}) => {
  const isIconEmoji = typeof icon === "string" && icon.length < 10;
  const {buildingId, folderId, level2ID} = useParams()
  return (
    <div className="card">
      <Link to={"/enhet/" + buildingId + "/mappar/" +
        folderId + "/" + level2ID + "/" + nextfolder
      }
        className="folder-link"
      >
        <div className="card-icon">
          {isIconEmoji ? icon : <img src={icon} alt={`${title} icon`}/>}
        </div>
        <div className="card-title">{title}</div>
      </Link>
    </div>
  )
}

export default BuildingLevel3FolderTemplate;
