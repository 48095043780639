import React from "react";
import "../../assets/styles/Template/template.scss"; // Importera den gemensamma stilen

const Template = ({ children, title, inputValue }) => {
  return (
    <div className="page-layout">
      <h1>{title}</h1>
      <div className="content">{children}</div>
    </div>
  );
};

export default Template;
