import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { createDoc, updateDoc, deleteDocFully } from "./setDocs";
import { Timestamp } from "firebase/firestore";

export function signIn(payload) {
  const auth = getAuth();
  //upgradeable: se efter om auto-logout ska implementeras (authPersistence)
  return signInWithEmailAndPassword(auth, payload.email, payload.password)
    .then((userCredential) => { return userCredential })
    .catch((error) => {

      switch (error.code) {
        case "auth/missing-email":
          console.warn(error.message, "foo");

          throw error;
        default:

          throw error;
      }
    });
}

export function deleteUser(uid) {
  return deleteDocFully("users", uid)
    .then((result) => {
      return true;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}

export function signUp(formData) {
  // sendCreateUserReq(formData)
  return createDoc("users", formData)
    .then((uid) => {
      return uid;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}

export function updateUserAccount(uid, formData) {
  return updateDoc("users", uid, formData )
    .then((result) => {
      return true;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}

// export function updateEmailAction () {
// const auth = getAuth()
//     updateEmail(auth.currentUser, "user@example.com").then(() => {
//         // Email updated!
//         // ...
//     }).catch((error) => {
//         // An error occurred
//         // ...
//     })
// }
// export function updatePasswordAction () {
// const auth = getAuth()
//     const user = auth.currentUser;
// //    const newPassword = getASecureRandomPassword();
//     const newPassword = "password" //TO DO remove
//     updatePassword(user, newPassword).then(() => {
//         // Update successful.
//     }).catch((error) => {
//         // An error ocurred
//         // ...
//     })
// }
