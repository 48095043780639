export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase()) ? "" : "Ogiltig e-postadress";
};

export const validatePassword = (password) => {
  const minLength = 8
  return password.length < minLength ? ("Lösenordet måste innehålla minst " + minLength + " tecken") : "";
};

export const validatePhoneNr = (phoneNr) => {
  const re = /^\d{10}$/;
  return re.test(phoneNr)
    ? ""
    : "Ange ett giltigt telefonnummer med 10 siffror";
};
function validateManagerPhoneNr(phoneNr) {
  const re = /^\d{10}$/;
  return re.test(phoneNr)
    ? ""
    : "Ange ett giltigt telefonnummer med 10 siffror (Föreståndare)";
}

function validateName(text) {return text.length < 2 ? 'Namnet är för kort' : ''}
function validateFname(text) {return text.length < 2 ? 'Förnamnet är för kort' : ''}
function validateLname(text) {return text.length < 2 ? 'Efternamnet är för kort' : ''}
function validateTitle(text) {return text.length < 2 ? 'Titeln är för kort' : ''}
function validateRole(text) {return text === '' || text === 'default' ? 'Välj en behörighet' : ''}
function validateUnit(text) {return text === '' || text === 'default' ? 'Välj en enhet' : ''}

function validateAddress(text) {return text.length < 2 ? 'Adressen är för kort' : ''}
function validateZipCode(text) {return text.length !== 5 ? 'Postnumret är ej 5 siffror' : ''}
function validateCity(text) {return text.length < 2 ? 'Ortsnamnet är för kort' : ''}
function validateManager(text) {return text === '' ? 'Välj en föreståndare' : ''}
function validateDescription(text) {return text.length < 2 ? 'Beskrivningen är för kort' : ''}


function validator(formData, functionArray) {
  const errors = {}
  let hasErrors = false;
  let errorString = "";
  for (const field in formData) {
    const validator = functionArray[field];
    if (validator) {
      const error = validator(formData[field]);
      if (error) {
        hasErrors = true;
        errorString += `<p>${error}</p>`;
        errors[field] = error
      } else {
        errors[field] = null
      }
    }
  }
  return {hasErrors, errorString}
}

export function ValidateAccountForm(formData, pw) {
  const valideringsFunctionArray = {
    firstName: validateFname,
    lastName: validateLname,
    title: validateTitle,
    email: validateEmail,
    role: validateRole,
    unitId: validateUnit,
    phoneNr: validatePhoneNr,
  };
  if (pw) {
    valideringsFunctionArray["password"] = validatePassword
    valideringsFunctionArray["passwordrepeat"] = (value) =>
      value !== formData.password ? "Lösenorden matchar inte" : null
  }
  return validator(formData, valideringsFunctionArray)
}

export function ValidateUnitForm(formData) {
  let hasErrors = false;
  let errorString = "";
  const valideringsFunctionArray = {
    name: validateName,
    // phoneNr: validatePhoneNr,
    // email: validateEmail,
    // address: validateAddress,
    // zipCode: validateZipCode,
    // city: validateCity,
    // manager: validateManager,
    // managerPhoneNr: validateManagerPhoneNr,
    // description: validateDescription,
  };

  return validator(formData, valideringsFunctionArray)
}

export function ValidateNewsForm(formData) {

  let hasErrors = false;
  let errorString = "";
  const valideringsFunctionArray = {
    title: validateTitle,
    unitId: validateUnit,
    role: validateRole,
    description: validateDescription,
  }

  return validator(formData, valideringsFunctionArray)
}

export function ValidateFolderForm(formData) {

  let hasErrors = false;
  let errorString = "";
  const valideringsFunctionArray = {
    title: validateTitle,
    unitId: validateUnit,
    role: validateRole,
  }

  return validator(formData, valideringsFunctionArray)
}
