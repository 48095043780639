import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import "../../assets/styles/Menues/BuildingsMenu.scss";
import { env, foo } from "../../env";
import button from "../Button";
let ignoreConsole = false
function BuildingsMenu({ buildings, currentBuilding }) {
  const { id } = useParams();
  const [unitId, setUnitId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (unitId === id) return;
    console.debug('setting bold text', id)
    setUnitId(id)
  }, [id, buildings]);

  if (env.verbose) console.debug("buildings", buildings.map((b) => b.name),
    '\nselected', { id, name: buildings.filter((b) => b.id === id).map((b) => b.name)[0] })
  env.verbose ? console.debug('currentbuilding', currentBuilding?.id
  ) :{}

  const handleContactButton = (id) => {
    if (currentBuilding?.id) navigate(
      `/kontaktaenhet/${currentBuilding.name}`, {
        state: { building: currentBuilding, currentBuilding },
      });
    else {
      let linkToBuilding = null;
      if (id === "1" || id === 1) {
        linkToBuilding = buildings[0];
      } else {


        if (buildings?.length > 0) {
          linkToBuilding = buildings.find((building) => building.uid === id);
        } else {
          if (env.verbose) console.log("Tjenixen");
          linkToBuilding = {uid: id};
        }
      }

      if (linkToBuilding) {
        navigate(`/kontaktaenhet/${linkToBuilding.uid}`, {
          state: {building: linkToBuilding, currentBuilding: currentBuilding},
        });
      }
    }
  };
  return (
    <div className="buildingMenyLayout">
      <ul className="buildings-menu">
        {env.devmode ? <li className="building-menu-item">< Link /*className={"back-button"}*/ to={"/"}>{"<"} Tillbaka {buildings.length > 1 ? '  ‎ ' : ''}</Link> </li> : <></>}
        {buildings
          .filter(b => b.id !== 'Alla')
          .map((building, index) => (
            <React.Fragment key={building.id}>
              <li className="building-menu-item">
                <Link
                  to={`/enhet/${building.id}`}
                  className={unitId !== building.id ? "building-menu-link" : "building-menu-link bold-text"}
                >
                  {building.name}
                </Link>
              </li>
              {index < buildings.filter(b => b.id !== 'Alla').length - 1 && (
                <span className="building-menu-separator">|</span>
              )}
            </React.Fragment>
          ))}
      </ul>

      <button
        className="building-contactbtn"
        onClick={() => handleContactButton(id)}
      >
        Kontaktdetaljer
      </button>
    </div>
  );
}

export default BuildingsMenu;
