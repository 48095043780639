import React, { useEffect, useState } from "react";
import UserItem from "./template/AccountTemplate";
import {
  addToStringForFiltering,
  convertTimestampsToString,
  fetchDocs,
  fetchDocsData,
  userDocToStringForFiltering
} from "../../store/actions/fetchDocs";

const AccountListing = ({ searchQuery }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    let ignore = false;

    if (!ignore)
      fetchDocsData("users")
        .then((res) => { setUsers(res.filter(u => (u.role !== 'dev'))) })

    return () => {
      ignore = true;
    };
  }, []);

  const filteredUsers = users.filter(
    (userItem) => (
      addToStringForFiltering(userItem).toString.includes(searchQuery.toLowerCase()) //||
      // userItem.content?.toLowerCase().includes(searchQuery.toLowerCase())
      // userItem
    )

  );

  if (users === undefined) return <></>;

  return (
    <div className="user-list">
      {/*<p>{searchQuery}</p>*/}
      {filteredUsers.map((user) => {
        const itemkey = user.id
        // console.debug('key:', itemkey)
        return (
          < UserItem key={itemkey} user={user} />
        )
      })}
    </div>
  );
};

export default AccountListing;
