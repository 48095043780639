import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import "../../../assets/styles/Listing/template/AccountTemplate.scss";
import UserIcon from "../../../assets/images/icons/user-icon.png";
import { useDispatch } from "react-redux";
import { setSelectedUser } from "../../../store/reducers/accountReducer";
import {
  disableUser,
  deleteSpecificUser,
} from "../../../store/reducers/accountReducer";
import { getFileUrl } from "../../../store/actions/handleStorage";
import { getRoleAsString } from "../../../store/firebase/roleEnums";
import { env, ReloadPage } from "../../../env";
import {
  addToStringForFiltering,
  convertTimestampsToString,
  userDocToStringForFiltering
} from "../../../store/actions/fetchDocs";
import PopupConfirmDeletion from "../../Popups/PopupConfirmDeletion";

const AccountTemplate = ({ user }) => {
  let ignoreConsole = false;
  // if (env.verbose && ignoreConsole == false) console.debug("userdoc", user);
  ignoreConsole = true;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [img, setImg] = useState(UserIcon);
  const [deleted, setDeleted] = useState(false);
  const role = getRoleAsString(user.role || 'user')

  /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
  const theUser = useSelector((state) => state.account.theUser);

  const handleEdit = () => {
    navigate(`/editera-konto/${user.id}`);
    window.scrollTo(0, 0)
    const { creationTime, updateTime, ...userWithoutTimestamps } = user;
    dispatch(setSelectedUser(userWithoutTimestamps));
  };

  const handleDelete = () => {
    const { creationTime, updateTime, ...userWithoutTimestamps } = user;
    //dispatch(setSelectedUser(userWithoutTimestamps));
    dispatch(deleteSpecificUser({ uid: user.uid }))

    reloadPage()
    setDeleted(true)
  };

  function handleMore() {
    reloadPage()
  }
  function reloadPage() { ReloadPage("/konton", { navigate }) }

  //to do cache
  useEffect(() => {
    let ignore = false
    if (env.verbose) console.debug(1336, user.email, user?.imagePath)
    if (user?.imagePath !== '' && img === UserIcon) {
      if (env.verbose) console.debug(user.imagePath)
      getFileUrl(user.imagePath)//TO DO ??
        .then((url) => { setImg(url) })
        .catch(e => { console.error(e.code) })
    }
    return () => { ignore = true }
  }, [user])


  if (!user) return <></>
  // if (env.devmode) console.log("userDoc", user);
  const isDecisionMakers = ["superadmin", "admin", "dev"].includes(theUser.role);
  const isSameUser = theUser.uid === user.uid;
  const isManagerOfUserInSameUnit = theUser.role === "manager" && user.role === "user" && theUser.unitId === user.unitId;

  // console.log("theUser", theUser)
  return deleted ? <></> : (
    <div className="user-item">
      <div className="user-info">
        <img
          src={img}
          alt={`${user.firstName} ${user.lastName}`}
          className="profile-pic"
        />
        <div className="user-details">
          <div className="user-role">
            {user.title} | {user.unitId === 'Alla' ? 'Alla enheter' : user.unit}
          </div>
          <div className="user-name">
            {user.firstName} {user.lastName}
          </div>
          <div className="user-note">{role}</div>
          {/*<section>{addToStringForFiltering(user).toString}</section>*/}
        </div>
      </div>
      <div className="user-actions">
        {(isDecisionMakers || isSameUser || isManagerOfUserInSameUnit) && (
          <button className="changeBtn" onClick={handleEdit}>
            Ändra
          </button>
        )}
        {/*(isDecisionMakers || isManagerOfUserInSameUnit) && (
          <>
            <button className="deleteBtn" onClick={handleDelete}>
              Radera
          </button>
          </>
        )*/}
        <PopupConfirmDeletion
          word={'kontot'}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export default AccountTemplate;
