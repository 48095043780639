import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../reducers/userReducer";
import accountReducer from "./accountReducer";
import unitReducer from "./unitReducer";
import newsReducer from "./newsReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    account: accountReducer,
    unit: unitReducer,
    news: newsReducer,
  },
});
