import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {fetchDoc, getUnitByName} from '../store/actions/fetchDocs'
import Template from "./template/Template";
import BuildingLevel1FolderListing from "../components/Listing/Folders/BuildingLevel1FolderListing";
import BuildingLevel1DocumentListing from "../components/Listing/Documents/BuildingLevel1DocumentListing";
import {env, invalidUnitIds} from "../env";
import {useDispatch, useSelector} from "react-redux";
import {setId, setName} from "../store/reducers/unitReducer";

function BuildingFolderPage() {
  let { id } = useParams();
  const user = useSelector((state) => state.account.theUser)

  const [building, setBuilding] = useState();
  const [buildingName, setBuildingName] = useState('')
  const name = useSelector(state => state.unit.name)
  const dispatch = useDispatch()

  const directory = "folders" + "/"
  const userRole = user?.role
  let ignore = false

  const [docPath, setDocPath] = useState('')
  const [prevId, setPrevId] = useState('1')
  useEffect(() => {
    if (!invalidUnitIds.includes(id)) {
      if (id.includes('EQ')) {
        dispatch(setName(id))
        getUnitByName(id).catch(e => console.warn(e))
        .then(unit => {
          if (env.verbose) console.debug('setting b name:', unit.name, unit)
          setBuilding(unit)
          dispatch(setName(unit.name))
          dispatch(setId(unit.id))
        })
      }
      else fetchDoc('units', id, 'onlydata')
        .then(unit => {
          if (env.verbose) console.debug('setting b name:', unit.name, unit)
          setBuilding(unit)
          dispatch(setName(unit.name))
          dispatch(setId(unit.id))
        })
    }
  }, [id])

  // useEffect(() => {
  //   if (ignore || docPath === '' || docPath === directory) return;
  //   if (env.verbose) console.debug('foo docpath', docPath)
  //   getSubDirs(docPath, userRole, true).then(dirs => {
  //     setFolders(dirs)
  //   })
  //   getContentInDir(docPath, userRole, true).then(docs => {
  //     setDocs(docs)
  //   })
  //   return () => { ignore = true }
  // }, [docPath])

  useEffect(() => {
      setPrevId(id)
      console.assert(building?.id !== undefined, 'id', building?.id, 'user', userRole)
      if (building !== undefined) {
        const docPathToSet = directory + building.id
        setDocPath(docPathToSet)

    }
  }, [building])

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  return !building?.id ? <div>Loading...</div> : (
    <Template>
      {env.devmode ? <p>debug: {buildingName}/ ( {docPath} )</p> : <></>}
      <h1>{name || buildingName} - Mappar & Dokument</h1>
      < Link className={"back-button"} to={"/enhet/" + building.name}>{"<"} Tillbaka</Link>
      <BuildingLevel1FolderListing building={building} docPath={docPath} />
      <BuildingLevel1DocumentListing id={building.id}
                                     docPath={docPath}
                                     directory={directory}
                                     userRole={userRole}
                                     buildingId={building.id}
                                     buildingName={building.name}
      />
    </Template>
  );
}

export default BuildingFolderPage;
