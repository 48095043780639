import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Template from "./template/Template";
import BuildingLevel4DocumentListing from "../components/Listing/Documents/BuildingLevel4DocumentListing";
import { env, invalidUnitIds } from "../env";
import { fetchDoc, humanreadablePath } from "../store/actions/fetchDocs";
import { getAuth } from "firebase/auth";
import {useSelector} from "react-redux";

const BuildingLevel4FolderPage = () => {
  const { buildingId, folderId, level2ID, level3ID } = useParams();
  const id = buildingId
  const [buildingName, setBuildingName] = useState('')
  const name = useSelector(state => state.unit.name)
  const unitId = useSelector(state => state.unit.id)
  const [docPath, setDocPath] = useState('')
  const [prevId, setPrevId] = useState('1')
  useEffect(() => {
    if (!invalidUnitIds.includes(buildingId)) {
      fetchDoc('units', buildingId, 'onlydata')
        .then(unit => {
          if (env.verbose) console.debug('setting b name:', unit.name, unit)
          setBuildingName(unit.name)
        })
    }
  }, [buildingId])

  const root = "folders" + "/"
  const directory = root //+ folderId + "/"
  const currentUser = getAuth().currentUser
  const json = JSON.parse(currentUser.reloadUserInfo.customAttributes)
  const userRole = json.role


  useEffect(() => {
    if (prevId === id || id === "1") {
      if (env.verbose) console.debug('foo id:', id)

    }
    else {
      setPrevId(id)
      if (env.verbose) console.debug('foo', 'id', id, 'user', userRole)
      // const docPathToSet = directory + buildingId + "/content/" + folderId + "/content/" + level2ID
      const docPathToSet = directory + buildingId + "/content/" + folderId + "/content/" + level2ID + "/content/" + level3ID
      if (id !== undefined) setDocPath(docPathToSet)
    }
  }, [id, buildingId, directory, folderId, level2ID, level3ID, prevId, userRole])

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  if (env.verbose) console.log("hej");
  return (
    <div>
      <Template>
        <h1>{name} - Mappar & Dokument</h1>
        < Link className={"back-button"}
               to={"/enhet/" + buildingId + "/mappar/" + folderId + "/" + level2ID}>{"<"} Tillbaka</Link>
        {/*{env.devmode ? <p>debug: {humanreadablePath(docPath, buildingId, buildingName)}  ( {docPath} )</p>:<></>}*/}
        <h1>Dokument,</h1>
        <BuildingLevel4DocumentListing id={level2ID} docPath={docPath} directory={directory} userRole={userRole}
                                       buildingId={buildingId} buildingName={buildingName}/>
      </Template>
    </div>
  );
};

export default BuildingLevel4FolderPage;
