import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Template from "../pages/template/Template";
import { getAllWithRole } from "../store/actions/fetchDocs";
import { uploadFile, getFileRef, toUploadDefault, storageDirectories, getFileUrl } from "../store/actions/handleStorage";
import { Timestamp } from "firebase/firestore";
import { updateSpecificUnit } from "../store/reducers/unitReducer";
import {env, invalidString} from "../env";
import {BackButton} from "../components/Button";
import {ValidateUnitForm} from "../components/Validate/FormValidation";
import {viewErrorPopup} from "../components/Popups/FormErrorPopup";

const EditUnitPage = () => {
  const { uid } = useParams();
  const storageDirectory = storageDirectories.unitimages
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedUnit = useSelector((state) => state.unit.unitToEdit);

  const [formData, setFormData] = useState(
    selectedUnit || {
      name: "",
      phoneNr: "",
      email: "",
      address: "",
      zipCode: "",
      city: "",
      manager: "",
      managerPhoneNr: "",
      description: "",
    }
  );
  const [originalImagePath, setOriginalImagePath] = useState();
  const [oImageName, setOImageName] = useState('')
  const [originalImageName, setOriginalImageName] = useState()
  const [toUpload, setToUpload] = useState(toUploadDefault.image)
  const [imageRef, setImageRef] = useState(originalImageName)
  const [img, setImg] = useState()
  // const iData = imageRef.data()
  const [managers, setManagers] = useState([])

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  useEffect(() => {
    if (managers.length !== 0) return;
    getAllWithRole('manager')
      .then(docs => {
        const result = docs.map(doc => {
          const id = doc.id
          const data = doc.data()
          return { ...data, id }
        })
        setManagers(result || [])
        setOriginalImagePath(selectedUnit.imagePath || '')
        setOImageName(selectedUnit.imagePath?.replace('public/units/', '') || '')
      })
      .catch(e => { console.error(e) })

  }, [selectedUnit, managers.length])

  useEffect(() => {
    if (oImageName.length > 0) setOriginalImageName(oImageName.split("/")[1])
  }, [oImageName]);

  useEffect(() => {
    if (selectedUnit) {
      setFormData(selectedUnit);
    }
  }, [managers, selectedUnit]);

  useEffect(() => {
    let ignore = false
    if (originalImagePath && originalImagePath !== '') getFileUrl(originalImagePath)
      .then(url => { setImg(url) })
    return () => { ignore = true }
  }, [originalImagePath])

  const handleChange = (e) => {
    const { name, id, value, files } = e.target;
    switch (name) {
      case 'image':
        const img = files[0]
        if (!img) {
          console.debug('resetting', id)
          setImageRef(originalImagePath)
          return setToUpload(originalImagePath)
        }
        setToUpload(img)
        console.debug(111, img)
        setFormData(prevState => ({ ...prevState, imagePath: storageDirectory + uid + "/" + img.name }))
        console.debug('imgname:', img.name, storageDirectory + uid + "/" + img.name)
        setImageRef(img.name)
        break
      default:
        console.debug(name, '=>', value)
        setFormData({
          ...formData,
          [name]: value,
        });
    }
  };

  function resetToUpload(type, e) {
    console.debug('no', type)
    setToUpload(toUploadDefault.image)
    setFormData(prevState => ({ ...prevState, imagePath: originalImagePath }))
    // document.getElementById(type).setAttribute('value', '')
  }
  function uploadFiles() {
    if (toUpload) if (toUpload.name !== toUploadDefault.image.name)
      uploadFile(storageDirectory + uid + "/", toUpload)
        .then(path => {
          resetToUpload()
          console.debug("file upload success:", path)
        })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const {hasErrors, errorString} = ValidateUnitForm(formData)
    console.debug('hasErrors', hasErrors, errorString, formData)
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {
    let same = true

    if (formData.description !== selectedUnit.description) same = false
    if (formData.unitId !== selectedUnit.unitId) same = false
    if (formData.role !== selectedUnit.role) same = false
    if (formData.imagePath !== selectedUnit.imagePath) same = false
    if (formData.filePath !== selectedUnit.filePath) same = false
    if (formData.title !== selectedUnit.title) same = false
    if (formData.zipCode !== selectedUnit.zipCode) same = false

    console.log('same', same)
    if (same) {
      console.log('No change, going back')
      navigate(-1)
      return;
    }
    const {creationTime, ...updatedFormData} = formData;
    updatedFormData.updateTime = Timestamp.now().toMillis();
    uploadFiles()
    dispatch(updateSpecificUnit({uid, formData: updatedFormData}))
    .unwrap()
    .then(() => {
      navigate(-1);
    })
    .catch((error) => {
      console.error("OhNo:", error);
    });
  }
  };

  // console.debug('imageRef', imageRef.name, imageRef)
  return (
    <Template>
      <BackButton />
      <h1>Ändra enhet</h1>
      <div>
        <form className="flex-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            className="flex100"
            value={formData.name}
            onChange={handleChange}
            placeholder="Namn på enheten"
          />
          <input
            type="number"
            name="phoneNr"
            className="flex50"
            value={formData.phoneNr}
            onChange={handleChange}
            placeholder="Telefonnummer till enheten"
          />
          <input
            type="email"
            name="email"
            className="flex50"
            value={formData.email}
            onChange={handleChange}
            placeholder="E-post til enhet"
          />
          <input
            type="text"
            name="address"
            className="flex30"
            value={formData.address}
            onChange={handleChange}
            placeholder="Skriv in enhetsadress"
          />
          <input
            type="number"
            name="zipCode"
            className="flex30"
            value={formData.zipCode}
            onChange={handleChange}
            placeholder="Skriv in postnummer"
          />
          <input
            type="text"
            name="city"
            className="flex30"
            value={formData.city}
            onChange={handleChange}
            placeholder="Skriv in Stad"
          />
          <select
            name="manager"
            className="flex50"
            value={formData.manager}
            onChange={handleChange}
          >
            <option value="">Ansvarig föreståndare</option>
            {/* <option value="alternativ1">Pernilla Söderman</option> */}
            {/* <option value="alternativ2">Linn Nillson</option> */}
            {/* <option value="alternativ3">Jan Samuelsson</option> */}
            {managers.map(m => {
              const itemkey = m.id
              if (env.verbose) console.debug('itemkey', itemkey)
              return (
                <option key={itemkey} value={itemkey}>
                  {env.devmode ? (m.firstName + ' ' + m.lastName +  " (" + m.id + ")") : (m.firstName + ' ' + m.lastName) }
                </option>
              )
            })}
          </select>
          <input
            type="number"
            name="managerPhoneNr"
            className="flex30"
            value={formData.managerPhoneNr}
            onChange={handleChange}
            placeholder="Telefonnummer till föreståndare"
          />
          <input
            type="text"
            name="description"
            className="flex30"
            value={formData.description}
            onChange={handleChange}
            placeholder="Beskrivning av enhet"
          />
          <input hidden
            type="file"
            name="image"
            id='image'
            onChange={handleChange}
          /><label htmlFor="image" className="flex30 formBtn upload-button">{imageRef || 'Ladda upp en bild'}</label>
          <button className=" flex30 formBtn" type="submit">
            Skicka
          </button>
        </form>


        <div
          style={{justifyContent: "center", marginTop: "10vh" }}
          className={img ? 'grid' : 'hidden'}
        //todo inline css
        >
          <img
            src={img}
            alt={"unit"}
            className="edit-unit-pic"
            id={"edit-unit-img"}
            style={{ maxWidth: "40vw" }}
          //todo inline css
          />
        </div>

      </div>
    </Template>
  );
};

export default EditUnitPage;
