import LoginForm from "../components/Forms/LoginForm";
import "../assets/styles/Forms/LoginForm.scss";
import "../assets/styles/Pages/LoginPage.scss";
import logo from "../assets/images/logotyp/company-logotype.png";

function LoginPage() {
  return (
    <div className="login-page">
      <div className="LoginPage-inner-wrapper">
        <img src={logo} className="logo" alt="Logotyp" />
        <h1>Välkommen till Humanisthemmets intranät</h1>
        <LoginForm />
      </div>
    </div>
  );
}

export default LoginPage;
