import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import "../../../assets/styles/Listing/template/UnitTemplate.scss";
import BuildingIcon from "../../../assets/images/icons/building-icon.png";
import { setUnitToEdit } from "../../../store/reducers/unitReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUnit } from "../../../store/reducers/unitReducer";
import { getFileUrl } from "../../../store/actions/handleStorage";
import { ReloadPage } from "../../../env";
import PopupConfirmDeletion from "../../Popups/PopupConfirmDeletion";

const UnitTemplate = ({ unit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uid = unit.id;

  /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
  const theUser = useSelector((state) => state.account.theUser);

  const [image, setImage] = useState(BuildingIcon);
  const [deleted, setDeleted] = useState(false)
  function reloadPage() { ReloadPage("/enheter", { navigate }) }
  useEffect(() => {
    if (!unit) return;
    if (!unit.imagePath) return;
    getFileUrl(unit.imagePath) //TO DO ??
    .then(url => { setImage(url || BuildingIcon) })
    .catch(e => console.error(e));

  }, [unit])

  function handleClick(e) {
    console.debug(e.target.className, uid);
    switch (e.target.className) {
      case "changeBtn":
        // dispatch()
        const { creationTime, updateTime, ...unitWithoutTimestamps } = unit;
        dispatch(setUnitToEdit(unitWithoutTimestamps));
        navigate(`/editera-enhet/${unit.uid}`);

        break;
      case "moreBtn":
        // dispatch()
        break;
      default:
        break;
    }
  }
  function handleDelete() {
    dispatch(deleteUnit({ uid }));
    setDeleted(true)
  }
  const isDecisionMakers = ["superadmin", "admin", "dev"].includes(theUser.role);
  return deleted ? <></> : (
    <div className="unit-item" name={unit.name} id={uid}>
      <div className="unit-info">
        <img src={image} alt={`${unit.name}`} className="unit-pic" />
        <div className="unit-details">
          <div className="unit-phoneNr">{unit.phoneNr}</div>
          <div className="unit-name">{unit.name}</div>
          <div className="unit-email">{unit.email}</div>
        </div>
      </div>
      <div className="unit-actions">
        {(isDecisionMakers) && (
          <>
            <button className="changeBtn" onClick={handleClick}>
              Ändra
       </button>
            <PopupConfirmDeletion
              word={'kontot'}
              handleDelete={handleDelete}
            />
            <button className="moreBtn hidden" onClick={handleClick}>
              Mera
       </button>
          </>
        )}
      </div>
    </div>
  );
};

export default UnitTemplate;
