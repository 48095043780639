// src/config/StartPageConfig.js
import buildingIcon from "../../../assets/images/icons/building-icon.png";
import documentIcon from "../../../assets/images/icons/document-icon.png";
import folderIcon from "../../../assets/images/icons/folder-icon.png";
import newsIcon from "../../../assets/images/icons/news-icon.png";
import userIcon from "../../../assets/images/icons/user-icon.png";

export const StartPageConfig = {
  superadmin: [
    { path: "/konton", icon: userIcon, title: "Hantera konton" },
    { path: "/enheter", icon: buildingIcon, title: "Hantera enheter" },
    { path: "/hantera-nyheter", icon: newsIcon, title: "Hantera nyheter" },
    { path: "/hantera-dokument", icon: documentIcon, title: "Dokument" },
    { path: "/hantera-mappar", icon: folderIcon, title: "Mappar" },
    { path: "/enhet/", icon: buildingIcon, title: "Våra enheter" },
  ],
  admin: [
    { path: "/konton", icon: userIcon, title: "Hantera konton" },
    { path: "/enheter", icon: buildingIcon, title: "Hantera enheter" },
    { path: "/hantera-nyheter", icon: newsIcon, title: "Hantera nyheter" },
    { path: "/hantera-dokument", icon: documentIcon, title: "Dokument" },
    { path: "/hantera-mappar", icon: folderIcon, title: "Mappar" },
    { path: "/enhet/", icon: buildingIcon, title: "Våra enheter" },
  ],
  dev: [
    { path: "/konton", icon: userIcon, title: "Hantera konton" },
    { path: "/enheter", icon: buildingIcon, title: "Hantera enheter" },
    { path: "/hantera-nyheter", icon: newsIcon, title: "Hantera nyheter" },
    { path: "/hantera-dokument", icon: documentIcon, title: "Dokument" },
    { path: "/hantera-mappar", icon: folderIcon, title: "Mappar" },
    { path: "/enhet/", icon: buildingIcon, title: "Våra enheter" },
  ],
  manager: [
    { path: "/hantera-nyheter", icon: newsIcon, title: "Hantera nyheter" },
    { path: "/hantera-mappar", icon: folderIcon, title: "Hantera mappar" },
    { path: "/konton", icon: userIcon, title: "Hantera konton" },
    { path: "/enhet/", icon: buildingIcon, title: "Enhet" },
  ],
  user: [{ path: "/enhet/", icon: buildingIcon, title: "Enhet" },],
};
