import React, { useEffect, useState } from "react";
import {
  validateEmail,
  validatePassword,
  validatePhoneNr, ValidateUnitForm,
} from "../Validate/FormValidation";
import { uploadFile, toUploadDefault, storageDirectories, checkIfFileExists } from "../../store/actions/handleStorage";
import { viewErrorPopup } from "../Popups/FormErrorPopup";
import { useDispatch } from "react-redux";
import { createUnitT } from "../../store/reducers/unitReducer";
import {docPlaceholder, getAllWithRole} from "../../store/actions/fetchDocs";
import {env, foo, ReloadPage} from "../../env";
import {useNavigate} from "react-router-dom";
import {updateDoc} from "../../store/actions/setDocs";


const storageDirectory = storageDirectories.unitimages

function FormTemplate({ onSubmit }) {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState(docPlaceholder);
  const navigate = useNavigate()
  const defaultUploadLabel = toUploadDefault.image.name
  const [toUpload, setToUpload] = useState({})
  const [submitCount, setSubmitCount] = useState(0);
  const [managers, setManagers] = useState([])

  useEffect(() => {
    if (managers.length !== 0) return;
    getAllWithRole('manager')
      .then(docs => {
        const result = docs.map(doc => {
          const id = doc.id
          const data = doc.data()
          return { ...data, id }
        })
        setManagers(result || [])
      })
      .catch(e => { console.error(e) })

  })

  const handleChange = (e) => {
    const { name, id, value, files } = e.target;
    switch (name) {
      case 'image':
        const file = files[0]
        if (!file) return resetToUpload(id, e.target)
        setToUpload(prevState => ({ ...prevState, image: file }))
        setFormData(prevState => ({ ...prevState, imagePath: storageDirectory + file.name }))
        console.debug('imgname:', file.name)
        break
      default:
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }
  }

  function resetToUpload(type, e) {
    console.debug('no', type)
    setToUpload(prevState => ({ ...prevState, image: toUploadDefault.image }))
    setFormData(prevState => ({ ...prevState, imagePath: '' }))
    // document.getElementById(type).setAttribute('value', '')
  }

  function uploadFiles(uid, docPath) {
    if (toUpload.image) if (toUpload.image.name !== toUploadDefault.image.name) {
      console.log('Uploading files')
      const pathToSet = storageDirectory + uid + "/"
      checkIfFileExists(pathToSet + toUpload.image.name).then(
        fileExists => {
          console.debug(foo.kd, fileExists)
          if (!fileExists)
            uploadFile(pathToSet, toUpload.image, )
            .then((path) => {
              resetToUpload();
              console.debug("file upload success:", path);
              updateDoc('units', uid, {imagePath: path})
            })

          else {
            uploadFile(pathToSet, toUpload.image, "(" + String(fileExists) + ")" + toUpload.file.image)
            .then((path) => {
              resetToUpload();
              console.debug("file upload success:", path);
            })
          }
      })
    }
  }
  function reloadPage(){ReloadPage("/enheter", {navigate})}
  const handleSubmit = (e) => {
    e.preventDefault();
    const {hasErrors, errorString} = ValidateUnitForm(formData)
    
    /* Kör eller neka... */
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {
      dispatch(createUnitT({ formData }))
        //[x] ändra namn på fil efter uid skapats
        .unwrap()
      .then((res) => {
        console.debug('res', res)
        console.debug('toUpload', toUpload)
        if (toUpload) uploadFiles(res.uid, res.path)
        reloadPage()
        setSubmitCount(submitCount + 1)
        setFormData(docPlaceholder)
        })
      .catch(e => console.error(e))
    }
  };

  return (
    <form className="flex-form" onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        className="flex100"
        value={formData.name}
        onChange={handleChange}
        placeholder="Namn på enheten"
        autoComplete="name"
      />
      <input
        type="number"
        name="phoneNr"
        className="flex50"
        value={formData.phoneNr}
        onChange={handleChange}
        placeholder="Telefonnummer till enheten"
      />
      <input
        type="email"
        name="email"
        className="flex50"
        value={formData.email}
        onChange={handleChange}
        placeholder="E-post til enhet"
        autoComplete="work tel"
      />
      <input
        type="text"
        name="address"
        className="flex30"
        value={formData.address}
        onChange={handleChange}
        placeholder="Skriv in enhetsadress"
        autoComplete="address-level1"
      />
      <input
        type="number"
        name="zipCode"
        className="flex30"
        value={formData.zipCode}
        onChange={handleChange}
        placeholder="Skriv in postnummer"
      />
      <input
        type="text"
        name="city"
        className="flex30"
        value={formData.city}
        onChange={handleChange}
        placeholder="Skriv in Stad"
      />

      <select
        name="manager"
        className="flex50"
        value={formData.manager}
        onChange={handleChange}
      >
        <option value="">Ansvarig föreståndare</option>
        {/* <option value="alternativ1">Pernilla Söderman</option>
        <option value="alternativ2">Linn Nillson</option>
        <option value="alternativ3">Jan Samuelsson</option> */}
        {managers.map(m => (
          <option key={m.id} value={m.id}>
            {env.devmode ? (m.firstName + ' ' + m.lastName + " (" + m.id + ")") : (m.firstName + ' ' + m.lastName)}
          </option>
        ))}
      </select>
      <input
        type="number"
        name="managerPhoneNr"
        className="flex30"
        value={formData.managerPhoneNr}
        onChange={handleChange}
        placeholder="Telefonnummer till föreståndare"
      />
      <input
        type="test"
        name="description"
        className="flex30"
        value={formData.description}
        onChange={handleChange}
        placeholder="Beskrivning av enhet"
      />
      <input hidden
        type="file"
        name="image"
        id='image'
        onChange={handleChange}
      /><label htmlFor="image" className="flex30 formBtn upload-button">{toUpload?.image?.name || defaultUploadLabel}</label>
      <button className=" flex50 formBtn" type="submit">
        Skicka
      </button>
      <section className={"flex100"} style={{marginTop: '1vh'}}>
        {submitCount > 0 ? 'Konto har skapats!' : ''}
      </section>
    </form>
  );
}

export default FormTemplate;
