import { deleteDoc, doc, getFirestore, serverTimestamp, setDoc, Timestamp, updateDoc as firebaseUpdateDoc, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import {humanreadablePath} from "./fetchDocs";
import env from "../../env";
//[x] altfil
export async function createDoc(path, data, id) {
  env./*TODO verybose*/devmode?console.debug('time', customTimestampString(), path, data):{}
  const db = getFirestore();

  let docRef
  if (!id) docRef = doc(collection(db, path))
  if (id) docRef = doc(db, path, id);

  // Detta är data som genereras med varje databasdokument
  const boilerplateData = {
    uid: docRef.id,
    creationTime: Timestamp.now(),
    updateTime: Timestamp.now(),
    disabled: false,
    author: getAuth().currentUser.uid,
    // hasAccess: getRoles(data.formData.role)
  }

  console.debug('formdata:', data.formData)

  // if (data.formData.fileURL) uploadFile({...data.formData, ...boilerplateData})

  // else docRef = doc(getFirestore(), path)
  return setDoc(docRef, { ...data.formData, ...boilerplateData, path: docRef.path })
    .then(() => {
      console.log("doc created with ref:", docRef.id, 'and path', humanreadablePath(docRef.path).split("/"))
      return docRef.id
    })
    .catch((e) => {
      throw e
    })
}

export async function createDocUniqID(path, data) {
  const db = getFirestore();
  const colRef = collection(db, path);

  // Detta är data som genereras med varje databasdokument
  const boilerplateData = {
    creationTime: Timestamp.now(),
    updateTime: Timestamp.now(),
    disabled: false,
    author: getAuth().currentUser.uid,
  };

  try {
    const docRef = await addDoc(colRef, { ...data, ...boilerplateData });
    console.log("doc created with ref:", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
}

function uploadFile(data) {
  const storage = getStorage()
  console.debug('upload:', data)

  const url = data.fileURL
  const file = data.profile_photo

  const storageRef = ref(storage, url);

  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log('Uploaded a blob or file!')
  })
}

export function updateDoc(path, uid, data) {
  env./*TODO verbose*/devmode?console.debug('path:', path, '\nuid:', uid, '\ndata:', data):{}
  const docRef = doc(getFirestore(), path, uid);
  return firebaseUpdateDoc(docRef, {...data, updateTime: Timestamp.now()})
    .then(() => {
      console.log("Document updated with ID:", uid);
      return true;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}

export async function deleteDocFully(path, uid) {
  console.debug('path', path, 'uid', uid)
  const dref = !uid ? doc(getFirestore(), path) : doc(getFirestore(), path, uid)
  return deleteDoc(dref)
    .then(() => {
      console.log("doc -deleted- with path:", path)
      return uid
    })
    .catch((e) => {
      throw e
    })
}

export function disableDoc(path, uid) {
  const dref = uid ? doc(getFirestore(), path, uid) : doc(getFirestore(), path)

  const data = {disabled: true, updateTime: serverTimestamp()}
  return firebaseUpdateDoc(dref, data)
    .then((r) => {
      !env.devmode?{}:console.debug("doc disabled with ref:", dref.id)
      return r
    })
    .catch((e) => {
      throw e
    })
}

export function getRoles(role) {
  switch (role) {
    case 'user': return ['user', 'manager', 'admin', 'superadmin', 'dev']
    case 'manager': return ['manager', 'admin', 'superadmin', 'dev']
    case 'superadmin' || 'dev': return ['superadmin', 'dev']
    default: return ['admin', 'superadmin', 'dev']
  }
}

export const customTimestampString = () => {
  const date = Timestamp.now().toDate()

  return addAzero(date.getUTCFullYear()) +
    addAzero(date.getMonth()) +
    addAzero(date.getDate()) + "T" +
    addAzero(date.getHours()) + "-" +
    addAzero(date.getMinutes()) + "-" +
    addAzero(date.getSeconds())
}

function addAzero(int) {
  if (int > 9) return int.toString()
  return '0' + int.toString()
}
