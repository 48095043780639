import React, {useEffect, useState} from "react";
import "../../assets/styles/Pages/Buildings/BuildingHeroImage.scss"; // Importera SCSS-filen
import companyLogotype from "../../assets/images/logotyp/company-logotype.png";
import {invalidString} from "../../env";
import {getFileRef, getFileUrl} from "../../store/actions/handleStorage";
import {fetchDoc, fetchDocData} from "../../store/actions/fetchDocs";
import {useSelector} from "react-redux";

function BuildingHeroImage({ imagePath, title, description, url }) {
  const [img, setImg] = useState(companyLogotype);
  const user = useSelector(state => state.account.user);
  useEffect(() => {
    let ignore = false
    if (img === imagePath || ignore || !user?.role) return;
    else if (imagePath === '' || invalidString.includes(imagePath)) setImg(companyLogotype);
    else getFileUrl(imagePath, user.role)
      .then(url => setImg(url))
    .catch(e => {
      console.warn(e)
      setImg(companyLogotype)
    })
    return () => {ignore=true}
  }, [imagePath, user]);

  useEffect(() => {
    let ignore = false
    setImg(url)
    return () => {ignore=true}
  }, [url])


  // console.debug({img})
  return (
    <div className="hero-image" style={{ backgroundImage: `url(${img})`, transition: 'background-image ease 0,5s'}}>
      <div className="hero-overlay">
        <div className="hero-container">
          <h1 className="hero-title">{title}</h1>
          <p className="hero-description">{description}</p>
          {/*<p>img: {imgs[id] ? imgs[id] : 'nil'}</p>*/}
          {/*<img src={imgs[id] ? imgs[id] : 'nil'} alt="" />*/}
        </div>
      </div>
    </div>
  );
}

export default BuildingHeroImage;
