import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createNewsArticle } from "../../store/reducers/newsReducer";
import { checkIfFileExists, storageDirectories, uploadFile } from "../../store/actions/handleStorage";
import { toUploadDefault } from "../../store/actions/handleStorage";
import { env, foo } from "../../env";
import { useNavigate } from "react-router-dom";
import { ValidateNewsForm } from "../Validate/FormValidation";
import { viewErrorPopup } from "../Popups/FormErrorPopup";
import {updateNews} from "../../store/actions/newsAction";

function FormTemplate({ theUnits, theUser, refreshTheBool }) {
  const dispatch = useDispatch();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [units, setUnits] = useState([]);
  const [toUpload, setToUpload] = useState({});
  const storageDirectory = storageDirectories.news
  const defaultFileName = toUploadDefault.file.name
  const defaultImageName = toUploadDefault.image.name

  const [formData, setFormData] = useState({
    unitName: "",
    unitId: "",
    role: "",
    title: "",
    description: "",
    filePath: "",
    imagePath: "",
  });
  useEffect(() => {
    setUnits(theUnits);
  }, [theUnits]);
    /*useEffect(() => {
    setUnits(theUnits);

    if (units.length == 0) return () => {};
    const res = units.map((u) => u.data());
    if (env.verbose) setNewsBuildings(res);

    return () => {};
  }, [units]);
 */
  function resetToUpload(type, e) {
    // if (!type) throw new Error('ingen typ')
    switch (type) {
      case "file":
        setToUpload((prevState) => ({}));
        setFormData((prevState) => ({ ...prevState, filePath: "" }));
        break;
      case "image":
        setToUpload((prevState) => ({}));
        setFormData((prevState) => ({ ...prevState, imagePath: "" }));
        document.getElementById(type).setAttribute("value", "");
        break;
      default:
        resetToUpload("file");
        resetToUpload("image");
    }
    const el = document.getElementById(type)
    try { el.setAttribute("value", ""); }
    catch { (e) => { console.error(e) } }

  }

  function handleFileChange(e) {
    const { id, files } = e.target;
    const file = files[0];

    if (!file) return resetToUpload(id, e.target);
    // const pathToSet = "filepath:", storageDirectory.filetest + formData.role + "/" + formData.unitId + "/" + file.name
    switch (id) {
      case "file":
        setToUpload((prevState) => ({ ...prevState, file: file }));
        // setFormData((prevState) => ({
        //   ...prevState,
        //   filePath: storageDirectory.filetest + file.name,
        // }));
        break;
      case "image":
        setToUpload((prevState) => ({ ...prevState, image: file }));
        // setFormData((prevState) => ({
        //   ...prevState,
        //   imagePath: storageDirectory.newspics + file.name,
        // }));
        break;
      default:
        break;
    }
  }

  function uploadFiles(role, unitId, newsId) {
    if (!toUpload.file && !toUpload.image) return undefined;
    let pathToSet = storageDirectory + role + "/" + unitId + "/" + newsId + "/"

    console.debug('1338', toUpload.image)

    if (toUpload.file)
      if (toUpload.file.name !== toUploadDefault.file.name)
        checkIfFileExists(pathToSet + toUpload.file.name).then(fileExists => {
          console.debug(foo.kd, fileExists)
          if (!fileExists)
            uploadFile(pathToSet, toUpload.file, 'file-' + toUpload.file.name)
            .then((path) => {
              console.debug("file upload success:", path);
              updateNews(newsId, {filePath: path}).then(r => {console.debug('newsDoc filepath updated')})
            })

          else {
            uploadFile(pathToSet, toUpload.file, "(" + String(fileExists) + ")" + 'file-' +  toUpload.file.name)
            .then((path) => {
              console.debug("file upload success:", path);
              updateNews(newsId, {filePath: path}).then(r => {console.debug('newsDoc filepath updated')})
            })
          }
        })
    if (toUpload.image) {
      !env.devmode ? {}: console.debug(toUpload.image.name)
      !env.devmode ? {}: console.debug(toUploadDefault.image.name)
      const imagenameSegments = toUpload.image.name.split(".")
      const fileExtension = imagenameSegments[ imagenameSegments.length -1 ]
      if (toUpload.image.name !== toUploadDefault.image.name)
        checkIfFileExists(pathToSet + toUpload.image.name).then(fileExists => {
          env.devmode ? console.debug(foo.kd, fileExists):{}
          if (fileExists === 0 || fileExists === false) uploadFile(pathToSet, toUpload.image, 'image-' + toUpload.image.name)
          .then((path) => {
            console.debug("image upload success:", path);
            updateNews(newsId, {imagePath: path}).then(r => {console.debug('newsDoc imagepath updated')})
          })
          else {
            uploadFile(pathToSet, toUpload.image, "(" + String(fileExists) + ")" + 'image-' + toUpload.image.name)
            .then((path) => {
              console.debug("image upload success:", path);
              updateNews(newsId, {imagePath: path}).then(r => {console.debug('newsDoc imagepath updated')})
            })
          }
        });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "unitId") {
      if (value !== 'unassigned') {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const unitName = selectedOption.text;
        const unitId = e.target.value;
        setFormData((prevState) => ({
          ...prevState,
          unitName,
          unitId,
        }));
      } else { setFormData(prevState => ({ ...prevState, unitName: '', unitId: 'unassigned' })) }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  //function reloadPage(){ReloadPage("/hantera-nyheter", {navigate}, 450)}

  const handleSubmit = (e) => {
    e.preventDefault();
    const { hasErrors, errorString } = ValidateNewsForm(formData, true)

    /* Kör eller neka... */
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {

      if (!formData.unitId || !formData.unitName || !formData.role || formData.unitId === 'unassigned' || formData.role === 'unassigned') {
        console.warn('formData.unitId', formData.unitId, '\nformData.unitName:', formData.unitName, '\nformData.role', formData.role)
        alert("Kontrollera enhet och rättighet");
        return;
      }
      const { role, unitId } = formData
      let pathToSet = storageDirectory + role + "/" + unitId + "/"// + uid + "/"
      // console.log("foo bar", formData);
      let iPath = formData.imagePath
      let fPath = formData.filePath
      if (toUpload.file) {
        fPath = pathToSet + toUpload.file.name
        // formData = { ...formData, filePath: fPath }
      }
      if (toUpload.image) {
        iPath = pathToSet + toUpload.image.name
        // formData = { ...formData, imagePath: iPath }
      }
      // formData = {...formData, }
      dispatch(createNewsArticle({ ...formData, imagePath: iPath, filePath: fPath }))
        .unwrap()
        .then((res) => {
          console.log("News article created successfully!", res.uid);
          if (toUpload) uploadFiles(formData.role, formData.unitId, res.uid);
          // Återställ formData efter framgångsrik skapning
          setFormData({
            title: "",
            description: "",
            role: "unassigned",
            filePath: "",
            imagePath: "",
            unitId: "unassigned",
            unitName: "",
          });
          resetToUpload();
          setConfirmationMessage("Nyheten har lagts upp!");

          //reloadPage()

        })
        .catch((error) => {
          console.error("OhNo:", error);
        });
    }
  };

  // if (env.verbose) console.debug(units?.map(u => (u.uid)))

  return !theUser ?  <></> : (
    <section>
      <form className="flex-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          className="flex100"
          value={formData.title}
          onChange={handleChange}
          placeholder="Skriv titel på nyheten här"
        />
        <select
          name="unitId"
          className="flex50"
          value={formData.unitId}
          onChange={handleChange}
        >

          <option value="" >Välj enhet </option>
          <option value="Alla">Alla</option>

          {units.map((unit) => {
            if ((theUser.role === 'manager' || theUser.role === 'user') && theUser.unitId === unit.uid) {
              return (
                <option key={unit.uid} value={unit.uid}>
                  {unit.name}
                </option>
              );
            }
            else if (theUser.role !== 'manager' && theUser.role !== 'user') {
              return (
                <option key={unit.uid} value={unit.uid}>
                  {unit.name}
                </option>
              );
            }
            return null;
          })}
        </select>
        <select
          name="role"
          className="flex50"
          value={formData.role}
          onChange={handleChange}
        >

          <option key="NewsForm-role" value="unassigned">
            Tillgång för...
        </option>
          {theUser.role === 'superadmin' && (
            <>
              <option value="superadmin">Superadmin</option>
              <option value="admin">Administratör</option>
              <option value="manager">Föreståndare</option>
              <option value="user">Medarbetare</option>
            </>
          )}
          {theUser.role === 'dev' && (
            <>
              <option value="superadmin">Superadmin</option>
              <option value="admin">Administratör</option>
              <option value="manager">Föreståndare</option>
              <option value="user">Medarbetare</option>
            </>
          )}
          {theUser.role === 'admin' && (
            <>
              <option value="admin">Administratör</option>
              <option value="manager">Föreståndare</option>
              <option value="user">Medarbetare</option>
            </>
          )}
          {theUser.role === 'manager' && (
            <>
              <option value="manager">Föreståndare</option>
              <option value="user">Medarbetare</option>
            </>
          )}
          {theUser.role === 'user' && (
            <>
              <option value="user">Medarbetare</option>
            </>
          )}
        </select>
        <input
          type="text"
          name="description"
          className="flex100"
          value={formData.description}
          onChange={handleChange}
          placeholder="Skriv in beskrivning här"
        />
        <input hidden id="file" type="file" onChange={handleFileChange} />
        <label htmlFor="file" name="file-label" className="flex50 formBtn upload-button">
          {toUpload?.file?.name || defaultFileName}
        </label>
        <input hidden id="image" type="file" onChange={handleFileChange} />
        <label htmlFor="image" name="image-label" className="flex50 formBtn upload-button">
          {toUpload?.image?.name || defaultImageName}
        </label>
        <button className=" flex100 formBtn" type="submit">
          Skicka
      </button>
      </form>
      {confirmationMessage && <div className="confirmation-message">{confirmationMessage}</div>}
    </section>
  );
}

export default FormTemplate;
