import React, {useEffect, useState} from "react";
import "../../../../assets/styles/Listing/template/BuildingLevel1DocumentTemplate.scss";
import {getFileRef, getFileUrl, prepDownload, startDownload} from "../../../../store/actions/handleStorage";
import {getMetadata} from "firebase/storage";
import {getIcon} from "../../../../assets/images/icons/Icons";
import imageIcon from "../../../../assets/images/icons/picture-icon.svg";
import env from "../../../../env";

const BuildingDocumentTemplate = ({
  icon,
  title,
  inFolder,
  DocumentLink,
}) => {

  const [docIcon, setDocIcon] = useState(icon);
  const [theLink, setTheLink] = useState('');
  const [fatalError, setFatalError] = useState(false);
  const [fileRef, setFileRef] = useState(null);

  useEffect(() => {
    let ignore = false
    if (ignore || DocumentLink === undefined || !DocumentLink) return

    getFileUrl(DocumentLink)
    .then(url => setTheLink(url))
    .catch(e => {
      console.error('Fatal error:', title, e)
      setFatalError(true)
    })

    getFileRef(DocumentLink, true)
    .then(fileRefRes => {
      setFileRef(fileRefRes)
      getMetadata(fileRefRes)
      .then(md =>
        getIcon(md.contentType)
        .then(ico => setDocIcon(ico))
        .catch(e => console.error(e))
      )
      .catch(e => console.error(e))
    })
    .catch( e => {
      console.error('Fatal error:', title, e)
      setFatalError(true)
    })
    return () => {ignore = true}
  }, [DocumentLink]);

  function handleClick() {
    if (env.devmode) window.open(theLink, '_blank')
  }

  function handleDownload() {
  if (env.devmode) console.log(/*id, */'fileName:', '=> url:\n' + theLink)
  const link = window.document.getElementById(theLink)
  // if (prepped) {
  //   window.document.body.appendChild(link);
  //   startDownload(fileRef, link)
  //   .then(() => {
  //     link.parentNode.removeChild(link)
  //     setPrepped(false)
  //     console.log('Download finished')
  //   })
  //   .catch(e => {
  //     console.error(e)
  //   })
  // } else {
  //   setPrepping(true)
    prepDownload(fileRef, link)
    .then(r => {
      link.setAttribute('download', fileRef.name)
      // setPrepped(true)
      // setPrepping(false)
      console.debug('Download prepped', r)
      window.document.body.appendChild(link);
      startDownload(fileRef, link)
      .then(() => {
        link.parentNode.removeChild(link)
        // setPrepped(false)
        console.log('Download finished')
      })
      .catch(e => {
        console.error(e)
      })
    })
    .catch(e => console.error(e));
  }

  return fatalError ? (<></>) : (
    <div className="folder-document-row-container">
      {" "}
      {/* Tillagd container klass */}
      <div className="folder-document-row">
        <div className="folder-document-cell folder-document-icon"><img src={docIcon} alt={`${title} icon`}
                                                                        className={docIcon === imageIcon ? "filter-color" : ''}/>
        </div>
        <div className="folder-document-cell folder-document-title" onClick={handleClick}>{title}</div>
        <div className="folder-document-cell folder-document-folder">/{inFolder}</div>
        <div className="folder-document-cell folder-document-action">
          <button className="folder-document-download-btn" onClick={handleDownload}>Ladda ner</button>
        </div>
      </div>
      {/*eslint-disable-next-line*/}
      <a id={theLink} className="hidden"></a>
    </div>
  );
};

export default BuildingDocumentTemplate;