import React, {useEffect, useRef, useState} from "react";
import "../../assets/styles/Forms/FormComponents.scss"
import {
  fetchAllFolderDirectoryDocs,
  fetchDocsData,
  getContentInDirAndSubdirs,
  humanreadablePath,
  getAllDocuments,
  getDocRef,
  fetchDocDataSerializeable,
  convertFileName,
  fetchDocByRef,
  getFolderRole,
  getUnitName,
  fetchDocByFullpath,
  getUnitNameInstantly
} from "../../store/actions/fetchDocs";
import {
  getFileRef,
  getUploadTask,
  storageDirectories,
  toUploadDefault,
  uploadFile
} from "../../store/actions/handleStorage";
import {env, foo, invalidString, invalidUnitIds, pseudoClearConsole, ReloadPage} from "../../env";
import { getAuth } from "firebase/auth";
import {elevatedRoles, getRoleName} from "../../store/firebase/roleEnums";
import DocumentPageListing from "../Listing/Documents/DocumentPageListing"
import {createDoc, updateDoc} from "../../store/actions/setDocs";
import {useNavigate} from "react-router-dom";
import {UploadTask} from "firebase/storage";
import {doc, getDoc} from "firebase/firestore";
import {useSelector} from "react-redux";

function FormTemplate({ onSubmit }) {
  const [formData, setFormData] = useState({
    documentMappLocation: "",
    directory: false,
    unit: '',
    role: 'user'
  });
  const json = JSON.parse(getAuth().currentUser.reloadUserInfo.customAttributes)
  /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
  const theUser = useSelector((state) => state.account.theUser);
  const useUser = useSelector((state) => state.account.auth);
  const currentUserRole = useUser?.role

  const [dirs, setDirs] = useState([])
  const [allDirs, setAllDirs] = useState([])
  const [paths, setPaths] = useState([])

  const [readablePath, setReadablePath] = useState("");
  const [UnreadablePath, setUnReadablePath] = useState("");
  const [units, setUnits] = useState([])
  const [unit, setUnit] = useState([])
  const [role, setRole] = useState()
  const [progress, setProgress] = useState(0)
  const storageDirectory = storageDirectories.documents
  const [toUpload, setToUpload] = useState({
    image: toUploadDefault.image,
    file: toUploadDefault.file,
  })
  const [uploadTask, setUploadTask] = useState()
  const [uploadCount, setUploadCount] = useState(0)
  const [prevPath, setPrevPath] = useState('')


  const navigate = useNavigate()

  let ignore1 = false
  useEffect(() => {
    if (ignore1) {
      console.debug("ignored")
      return
    }

    toggleUnitSelectHidden()

    /** Om användaren är dev/super/admin hämtar vi alla enhets-dok & alla mappar som existerar */
    if (elevatedRoles.includes(currentUserRole)) {
    if (units?.length < 1)
      fetchDocsData('units')
      .then(res => setUnits(res))
      .catch(e => {
        console.debug(e)
      })

    fetchAllFolderDirectoryDocs(undefined, currentUserRole)
    .then(res => {
      setDirs(res || [])
      setAllDirs(res || [])
    })
    .catch(e => console.error(e))

  } else
      fetchDocDataSerializeable('units', theUser.unitId)
      .then(res => {
        setUnit(res)
        setUnits([res])
      })
      .catch(e => console.error(e))

    return () => { ignore1 = true }
  }, [theUser])

  useEffect(() => {
    /**Säkerställ att vårt ark baseras på tomma variabler :) */
    setReadablePath('');
    setUnReadablePath('');
    env.verybose?console.debug(1612, formData.unit, elevatedRoles.includes(currentUserRole), invalidUnitIds.includes(formData.unit)):{}
    env.verybose?console.debug(1612, 'fecthing all dirs from', formData.unit):{}
    switch (elevatedRoles.includes(currentUserRole)) {
      case true:
        if (formData.unit) {
          if (env.verbose) console.debug(1612, 'unit')
          setDirs(allDirs.filter(dir => dir.unit === formData.unit))
        } else {
          if (env.verbose) console.debug(1612, 'no unit')
          setDirs(allDirs)
        }
        break
      default:
        if (formData.unit) {
          if (env.devmode) console.debug(unit, foo.kd)
          setDirs(allDirs.filter(dir => dir.unit === formData.unit))
        } else {
          if (env.devmode) console.debug('no unit')
          setDirs(allDirs.filter(dir => dir.unit === formData.unit))
        }
        break
    }
    return () => {}
  }, [formData.unit]);



  useEffect(() => {
    if (prevPath !== '') setTimeout(() => {
        setFormData({...formData, path: prevPath || ''})
        setPrevPath('')
      }, 3000)
  }, [uploadCount])
  const optionRef = useRef(null);

  /*useEffect(() => {
    if (formData.path) fetchDocByRef(getDocRef(formData.path)).then(doc => setRole(doc.role))
        // console.debug('14', foo.kd, formData.unit, formData.path, '\ntempRef', doc, doc.role))

    if (invalidString.includes(formData.unit)) {
      const unitInPath = formData.path?.split("/")[1]
      if (env.verbose) console.debug('13', foo.kd, unitInPath)
      setFormData(prevState => ({...prevState, unit: unitInPath}))
    }
  }, [formData.path]);

  useEffect(() => {
    // console.log(1337, "PROGRESS", progress)
  }, [progress]);
*/

  function reloadPage() {ReloadPage("/hantera-dokument", {navigate}, 500)}

  function resetToUpload(type, e) {
    console.debug('no', type)
    setToUpload(prevState => ({ ...prevState, file: toUploadDefault.file }))
    setFormData(prevState => ({ ...prevState, filePath: '' }))
    setProgress(0)
    // document.getElementById(type).setAttribute('value', '')
  }

  function uploadFiles(path, fileName, docId) {
    // if (toUpload.file?.name !== toUploadDefault.file.name && toUpload.file?.name !== undefined) {
    env.devmode?console.debug(foo.kd + 'path', path, 'docid:', docId):{}

    const finalFilePath = path + docId + "/"

    const upTask = getUploadTask(finalFilePath, toUpload.file, fileName)


    upTask.on('state_changed', (snapshot) => {
      if (snapshot.state === 'running')
        setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
    })


    updateDoc(formData.docPath + "/", docId, {filePath: finalFilePath + fileName}).then()
    resetToUpload()
    setUploadTask(upTask)
    return upTask

    .catch(e => console.error(e))
  }
  function toggleUnitSelectHidden() {
    const sel = document.getElementById('unit')
    if (!currentUserRole || !sel) return;
    if (elevatedRoles.includes(currentUserRole)) setSelectHidden(false, sel)
    else setSelectHidden(true, sel)
  }

  function setSelectHidden(bool, sel) {
    switch (bool) {
      case true:
        if (sel.hasAttribute('hidden')) break
        sel.toggleAttribute('hidden')
        break
      case false:
        if (!sel.hasAttribute('hidden')) break
        sel.toggleAttribute('hidden')
        break
      default: break
    }
  }

  function handleClick(e) {
    const {id} = e.target
    switch (id) {
      case 'cancel':
        uploadTask.cancel()
        break
      default: break
    }
  }

    const handleChange = (e) => {
    const { name, id, value, files } = e.target;
    const pathSelectBox = document.getElementById("path")
    switch (name) {
      case 'file':
        const file = files[0]
        if (!file) {
          console.debug('filepath:', formData.filePath, '\nfilename:', formData.fileName)
          //resetToUpload(id, e.target)
          return;
        }
        setToUpload({file})
        setFormData(prevState => ({ ...prevState, fileName: file.name }))
        /*env.devmode?*/console.debug('filepath:', formData.filePath, '\nfilename:', file.name)/*:{}*/
        setProgress(0)
        break
      case 'unit':
        if (pathSelectBox) pathSelectBox.options[0].disabled = false

        const filePath = 'files/' + 'user/' + value + "/"
        const docPath = 'folders/' + value + "/content"
        getUnitName(value).then(unitName => {
          console.debug('filepath\n' + filePath.replace(value, unitName), '\nunitId:',  value, '\ndocpath:\n' + docPath)

          setFormData(prevState => ({ ...prevState, [name]: value, filePath, unitName }));
        })


        break
      default:
        setFormData(prevState => ({ ...prevState, [name]: value }));
      // console.debug(foo.kev, name, '>', value)
    }
  };

  function setPath(e) {
    const {value} = e.target
    console.debug(value)
    if (!value || value === '') {
      setFormData(prevState => ({
        ...prevState,
        filePath: 'files' + "/" + 'user' + "/" + formData.unit + "/",
        docPath: "folders/" + formData.unit + "/content"
      }))
    }
    else fetchDocByFullpath(value).then(doc => {
      getUnitName(doc.unit).then(unitName => {
        const {name, id, path, unit, role} = doc

        console.debug('eee', doc.role, doc.name)
        console.debug('eee', unitName)
        const readablePath = humanreadablePath(doc.path)
                // getFolderRole(value).then(folderRole => {

        const filePath = value.split("/")[0] + "/" + role + '/' + humanreadablePath(value)
        const docPath = value + "/content"
        // console.debug(filePath.replace(unit,unitName), '\nfull:\n' + filePath)

        console.debug(filePath.split("/").map(sp => {return  sp + "/\n" }).toString().replaceAll(",", "")
        ,'\ndocpath:\n' + docPath
        )
        setFormData(prevState => ({
          ...prevState,
          [name]: value,
          unit,
          unitName,
          filePath,
          docPath
        }))
        })


        // setReadablePath(readablePath);
        // setUnReadablePath(value);
        // if (pathSelectBox) pathSelectBox.options[0].disabled = true
      })
    }



  const handleSubmit = (e) => {
    e.preventDefault();
    console.debug(formData)
    if (!formData?.fileName) return;
    const xyz = formData.path || formData.unit + "/" + formData.fileName

    const fileName = convertFileName(formData.fileName, formData.title)
    let pathToSet = storageDirectory + role + "/" +  humanreadablePath(formData.path) + "/" + fileName
    let title = fileName
    let name = fileName
    //upgradeable: belabouring filnamn
    const data = {formData: {...formData, fileName, title, name, disabled: false}}

    env.devmode?console.debug('xyz', xyz, '\nrole', formData.role, '\nunit', formData.unit, "\npathToSet:", pathToSet) : {}

    createDoc(formData.docPath, data).then(docId => {
      const finalDocPath = formData.docPath + "/" + docId
      env.devmode? console.log('finalDocPath:', finalDocPath)  :{}
      if (toUpload) uploadFiles(formData.filePath, fileName, docId)
      .then(()=> {
        console.log("Finished upload, reloading")
        setUploadCount(uploadCount + 1)
        const docRef = getDocRef(formData.docPath + "/" + docId)
        const someData = {...data.formData, path: docRef.path }
        setPrevPath(formData.path)
        setFormData({...formData, path: ''})
        // this.forceUpdate()
      })
    }).catch(e => {
      console.error(e)
      env.devmode?console.debug(e.code,
        'formdatapath:',formData.path + "/content",
        '\nfileName:', fileName,
        'data', data,
      ):{}
    })
  };

  // const getUnitName = (theId) => {
  //   const matchingUnit = units.find(unit => unit.id === theId);
  //
  //   // console.debug('theId', theId, matchingUnit, units)
  //   return matchingUnit ? matchingUnit.name : 'odefined';
  // };
  // if (env.verbose && dirs && !ignore) console.debug(dirs.map(doc => (doc.ref.path)), dirs.map(d => (d.data())))
  // console.debug('units', units)

  return (
    <div>
      {/*{selectedUnit}*/}
      <form className="flex-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          className="flex100"
          value={formData.documentHeadline}
          onChange={handleChange}
          placeholder="Skriv din rubrik här"
        />
        <select
          id="unit"
          name="unit"
          className="flex100"
          value={formData.unit}
          onChange={handleChange}
        >
          <option value="">Välj enhet (devfunktion)</option>
          {/*<option value="Alla">Alla enheter</option>*/}
          {units.map(u => {
            return (
              <option key={u.id} value={u.id}>
                {u.name} ({u.id})
                {env.devmode ? "(" + u.id + ")" : ''}
              </option>
            )
          })}

        </select>

        {env.devmode && formData.unit === '' ? <section>dirs={dirs.length}</section> : <></>}

        <select
          name="path"
          id="path"
          className="flex100"
          value={formData.path}
          onChange={setPath}
        >
          <option value="">Mappar</option>

          {dirs.map(p => {
            // console.debug(foo.kd, 'p', p)
            return (
              <option
                key={p.path}
                value={p.path}
                // itemRef={iRef}
              >
                {getRoleName(p.role)}
                {p.role !== 'user' ? "‎ - " : "‎ ‎ -‎ "}{"‎  "}
                {humanreadablePath(p.path, p.unit, formData.unit === '' ? getUnitNameInstantly(p.unit, units) : '')}
              </option>
            )
          })}
        </select>

        <progress value={progress}
                  className={progress === 0 ? "full-opacity file-upload-progress-bar" : "file-upload-progress-bar"}
        />

        <input hidden
               name="file"
               id="file"
               type="file"
               onChange={handleChange}
        /><label htmlFor="file" className="flex100 formBtn upload-button">{toUpload.file.name}</label>

        <button className={progress === 0 || progress === 1 || isNaN(progress) ? "flex100 formBtn" : 'hidden'} type="submit">
          Skicka
        </button>
        <button className={progress === 0 || progress === 1 || isNaN(progress) ? 'hidden' : "flex100 formBtn"} id="cancel"
                onClick={handleClick}>
          Avbryt
        </button>
      </form>

      <div>

        {/*
        <p className="debug-text">{debugtext.title}</p>
        <p className="debug-text">{debugtext.units}</p>
        <p>{debugtext.stitle}</p>
        {debugtext.paths.map(x => (<p>{x}</p>))}
        <p>{debugtext.subtitle}</p>
*/
        }
      </div>

      <DocumentPageListing unReadablePath={UnreadablePath} readablePath={readablePath} unit={formData.unit}
                           allUnits={units} dirs={dirs} upcount={uploadCount} setUpCount={setUploadCount}/>

    </div>

  );
}

export default FormTemplate;
