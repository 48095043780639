import React, { useEffect, useState } from "react";
import UnitTemplate from "./template/UnitTemplate";
import {fetchDocs, fetchDocsDataSerializeable} from "../../store/actions/fetchDocs";

const UnitListing = ({ searchQuery }) => {
  const [units, setUnits] = useState()
  const [filteredUnits, setFilteredUnits] = useState()

  useEffect(() => {
    let ignore = false;

    if (!ignore)
      fetchDocsDataSerializeable("units").then(res => {setUnits(res)})
    return () => ignore = true;
  }, []);

  useEffect(() => {
    if (units) setFilteredUnits(
      units.filter(
        (unitItem) =>
          unitItem.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          unitItem.content?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
  }, [units]);

  if (!filteredUnits) return <></>;

  return (
    <div className="unit-list">
      {filteredUnits.map((unit) => (
        <UnitTemplate key={unit.id} unit={unit} />
      ))}
    </div>
  );
};

export default UnitListing;
