
import React, { useState, useEffect } from "react";
import "../../../assets/styles/Listing/template/NewsTemplate.scss";
import { fetchDoc, firestoreTimestampToString } from "../../../store/actions/fetchDocs";
import {env, foo, invalidString} from "../../../env";
import {getFileUrl, prepDownload, startDownload} from "../../../store/actions/handleStorage";
import { getRoleName, getRoleIcon } from "../../../store/firebase/roleEnums";
import NewsIcon from "../../../assets/images/icons/news-icon.png";
import UserIcon from "../../../assets/images/icons/user-icon.png";
import {getStorage, ref} from "firebase/storage";

const BuildingNewsTemplate = ({ news, role }) => {
  const [author, setAuthor] = useState();
  const [authorImg, setAuthorImg] = useState(UserIcon);
  //to do cache
  const [img, setImg] = useState('');
  const [fileName, setFileName] = useState("");
  const [url, setUrl] = useState('')
  const [downloadRef, setDownloadRef] = useState('');

  function handleDownload() {
    if (!news.filePath) return;
    const fileRef = ref(getStorage(), news.filePath);
    // if (env.devmode) console.log(/*id, */'fileName:', '=> url:\n' + url)
    const link = window.document.getElementById(downloadRef)
    prepDownload(fileRef, link)
    .then(r => {
      link.setAttribute('download', fileRef.name)
      // setPrepped(true)
      // setPrepping(false)
      console.debug('Download prepped', r)
      window.document.body.appendChild(link);
      startDownload(fileRef, link)
      .then(() => {
        link.parentNode.removeChild(link)
        // setPrepped(false)
        console.log('Download finished')
      })
      .catch(e => {
        console.error(e)
      })
    })
    .catch(e => console.error(e));
  }

  useEffect(() => {
    if (author !== undefined) if (author.imagePath !== undefined && author.imagePath !== '')
      getFileUrl(author.imagePath) //TO DO ??
        .then(url => { setAuthorImg(url) })
        .catch(e => { console.debug(e) })

    return () => { }
  }, [author])

  useEffect(() => {
    if (env.verbose) console.debug(70, foo.kd, 'role', role)
    if ((img === NewsIcon || invalidString.includes(img))
      && news.imagePath !== undefined
      && news.imagePath !== '')
      getFileUrl(news.imagePath, role)
        .then((url) => { setImg(url) })
        .catch(e => {
          console.error(e)
          setImg(NewsIcon)
        })

    if (news.filePath) {
      const fRef = ref(getStorage(), news.filePath);
      setFileName(fRef.name)
      setDownloadRef(news.filePath)
      getFileUrl(news.filePath).then((res) => {
        setUrl(res)
      })
      .catch(e => {setUrl('')})
    }

    fetchDoc("users", news.author, true)
      .then(res => { setAuthor(res) })
      .catch(e => { console.error(e) })

    return () => { }
  }, [news])

  // console.debug('article', news.title, path, author ? author.imagePath : 'noauthor', news)

  const debugtext = () => {
    if (env.devtest) return (<p className="debug-text" id="image-debug">{'debug: imagepath: ' + news?.imagePath}</p>)
    return <></>
  }


  return (
    <div className="news-item-building">
      <div className="news-info-building">
        {author && (
          <img
            src={authorImg}
            alt={`${authorImg}`}
            className="news-pic-building"
          />
        )}
        <div className="news-details-building">
          <div className="news-roleArray-building">
            <div>
              {" "}
              {author && (
                <span
                  key={news.id}
                  className="news-userName-building"
                  style={{fontSize: "1.2rem"}}
                >
                  {author?.firstName || 'Användare borttagen'}
                </span>
              )}
              {"  "}
              <span
                about={"Author title"}
                className="news-role-building"
                style={{fontSize: "1.2rem"}}
              >
                {author?.title ? ' | ' + author?.title : <></>}
              </span>{" "}
            </div>
            <div>
              <span className="news-dateandplace-building">
                {firestoreTimestampToString(news.creationTime)} - {news.unitName}
              </span>
            </div>
          </div>
          <div className="news-info-content-building">
            <div className="news-name-title-building">
              <span className="news-newsHeadline-building">
                {" "}
                {getRoleName(news.role)}
              </span>
              <img
                src={getRoleIcon(news.role)}
                alt={`${news.role} icon`}
                className="role-icon-building"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="news-content-building">
        <div className="news-headline-building">{news.title}</div>
        {!img ? <></> :
          <img
            id={"newsimage-" + news.id}
            src={img}
            alt={`${news.title}`}
            className="news-main-pic-building"
          />
        }
        <p className="news-text-building">{news.description}</p>
        {!env.devmode ? <></> :
          <section className="debug-text" style={{opacity: "0.2"}}>debug: imagePath={img || 'unexists'}</section>}
      </div>
      <div className="news-actions-building">
        {process.env.REACT_APP_SHOW_FILENAME_ON_BUILDINGNEWS !== 'true' ? <></> :
          <div style={{alignSelf: 'end', marginRight: '1vw', opacity: '0.3'}}>
            {fileName.replace('file-', '').replace(news.id, "")}
          </div>
        }
        <button className={news?.filePath ? "downloadBtn-building" : 'hidden downloadBtn-building'}
                onClick={handleDownload}>Ladda ner
        </button>
      </div>
      {/*eslint-disable-next-line*/}
      <a id={downloadRef} className="hidden"></a>
    </div>
  );
};

export default BuildingNewsTemplate;
