import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "../../assets/styles/Popup/PopupEditDocuments.scss";
import { humanreadablePath, prettyPath } from "../../store/actions/fetchDocs";
import { foo } from "../../env";

const PopupDocBeforeDeleteFolder = ({ document, handleSelectChange, handleSubmit, allUnits, UnitName, dirs, folder }) => {
    const [units, setAllUnits] = useState([]);
    const [directories, setDirectories] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('');

    useEffect(() => {
        setAllUnits(allUnits);
        setDirectories(dirs);
    }, [allUnits, dirs]);

    /* Filter out subfolders that are scheduled for deletion */
    useEffect(() => {
        if (dirs.length > 0) {
            const filteredDirs = dirs.filter(dir => !dir.fullpath.startsWith(folder.fullpath));
            setDirectories(filteredDirs);
        }
    }, [dirs, folder.fullpath]);

    const handleSelectedUnit = (e) => {
        const selectedUid = e.target.value;
        setSelectedUnit(selectedUid);
        handleSelectChange('unitSelect', selectedUid);
        const newFilteredDocs = directories.filter(doc => doc.path.includes(selectedUid));
        setFilteredDocs(newFilteredDocs);
    };

    const handleSelectedDir = (e) => {
        handleSelectChange('dirSelect', e.target.value);
    };

    const openPopup = () => {
        Swal.fire({
            html: `
                <div class="popupEditDocuments-select-column-container">
                    <h1>${UnitName}</h1>
                    <h2 class="popupEditDocuments-headline">Flytta dokument - ${document.fileName || document.id}</h2>
                    
                    <!-- ${directories.map((o) => `<p>id:${o.id} </br> fN:${o.fileName} </br> unit:${o.unit} </br> pretty:${prettyPath(o.path, o.unit, dirs, units)}</p>`).join('')}-->
                    <select id="unitSelect" class="popupEditDocument-select">
                        <option value="" disabled selected>Välj en huvudenhet</option>
                        ${units.map(option => `<option value="${option.uid}">${option.name}</option>`).join('')}
                    </select>
                    <select id="dirSelect" class="popupEditDocument-select" disabled>
                        <option value="" disabled selected>Välj en undermapp (valfritt)</option>
                    </select>
                </div>
                <button id="submit-btn" class="popupEditDocuments-submit-btn">Flytta dokument till mappen</button>
            `,
            showConfirmButton: false,
            didOpen: async (popup) => {
                const selectUnit = popup.querySelector('#unitSelect');
                const selectDir = popup.querySelector('#dirSelect');
                const submitButton = popup.querySelector('#submit-btn');
                selectUnit.addEventListener('change', async (e) => {
                    handleSelectedUnit(e);
                    if (e.target.value) {
                        const newFilteredDocs = directories.filter(doc => doc.path.includes(e.target.value));
                        setFilteredDocs(newFilteredDocs);
                        selectDir.innerHTML = `
                            <option value="" disabled selected>Välj en undermapp (valfritt)</option>
                            ${newFilteredDocs.map(option => `
                                <option value="${option.fullpath}">
                                    ${prettyPath(option.fullpath, option.unit, newFilteredDocs, units)
                                        /*.replace(option.id, option.fileName )*/} </option>`).join('')
                            }
                        `;
                        selectDir.disabled = false;
                    } else {
                        selectDir.disabled = true;
                    }
                });

                selectDir.addEventListener('change', handleSelectedDir);

                submitButton.addEventListener('click', () => {
                    handleSubmit(selectUnit.value, selectDir.value, document);

                    Swal.close();
                });
            }
        });
    };




    return (
        <button onClick={openPopup} className="download-btn edit">
            Flytta
        </button>
    );
};

export default PopupDocBeforeDeleteFolder;
