import React, { useEffect, useState } from "react";
import BuildingNewsTemplate from "../Listing/template/BuildingNewsTemplate";
import { fetchDocsData } from "../../store/actions/fetchDocs";
import {getRoleArray} from "../../store/firebase/roleEnums";
import env, {foo} from "../../env";



const BuildingNewsListing = ({ id, role }) => {
  const [newsArray, setNewsArray] = useState([]);

  useEffect(() => {
    if (!role) return;
    if (id) fetchDocsData("news", ['unitId', "in", [id, 'Alla']], ['role', 'in', getRoleArray(role)])
    .catch(e => { console.error(e) })
    .then(newsForSpecificUnit => {
      fetchDocsData("news", ['unitId', "==", 'Alla'], ['role', 'in', getRoleArray(role)])
      .catch(e => { console.error(e) })
      .then((news) => {
        news.push(...newsForSpecificUnit)
        const sorted = news.sort((a, b) => (a.creationTime.seconds < b.creationTime.seconds ? -1 : 1));
        if (env.verbose) console.debug('news', sorted)
        setNewsArray(newsForSpecificUnit)
      })
    })
    return () => { }
  }, [id, role])
  if (env.verbose) console.debug(60, foo.kd, 'role', role, 'newsArray', newsArray)
  if (newsArray.length === 0) return <></>
  return (
    <div>
      <div className="unit-list">
        {newsArray.map((newsItem) => (
          <BuildingNewsTemplate key={newsItem.id} news={newsItem} path={newsItem.imagePath} role={role} />
        ))}
      </div>
    </div>
  );
};

export default BuildingNewsListing;
