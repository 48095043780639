
import superadminIcon from "../../assets/images/icons/role-icons/superadmin-icon.png";
import forestondareIcon from "../../assets/images/icons/role-icons/forestondare-icon.png";
import medarbetareIcon from "../../assets/images/icons/role-icons/superadmin-icon.png";
import adminstratorIcon from "../../assets/images/icons/role-icons/superadmin-icon.png";

const roles = Object.freeze({
  user: 0,
  manager: 1,
  admin: 2,
  superadmin: 3,
  dev: 4
})

export function getRoleArrayOld(role) {
  /** verkar tasig, låt bli  */
  // const managerArr = ['manager', 'user', 'Alla']
  // const adminArr = ['admin', managerArr.flat()]
  // const sAarr = ['superadmin', adminArr.flat()]
  // const devArr = ['dev', sAarr.flat()]
  // switch (role) {
  //   case 'user': return ['user', 'Alla']
  //   case 'manager': return managerArr.flat()
  //   case 'admin': return adminArr.flat()
  //   case 'superadmin': return sAarr.flat()
  //   case 'dev' || 'all': return devArr.flat()
  //   default: throw new Error('invalid role: ' + role)
  // }
}

export function getRoleArray(role) {
  switch (role) {
    case 'dev':         return ['Alla', 'user', 'manager', 'admin', 'superadmin',  'dev']
    case 'superadmin':  return ['Alla', 'user', 'manager', 'admin', 'superadmin']
    case 'admin':       return ['Alla', 'user', 'manager', 'admin']
    case 'manager':     return ['Alla', 'user', 'manager']
    default:            return ['Alla', 'user']
  }
}

export const elevatedRoles = ['admin', 'superadmin', 'dev']

export function getRoleEnum(role) {
  switch (role) {
    case 'user': return roles.user
    case 'manager': return roles.manager
    case 'admin': return roles.admin
    case 'superadmin': return roles.superadmin
    case 'dev': return roles.dev
    default: throw new Error('invalid role:', role)
  }
}
export function getRoleName(role) {
  // console.debug(role)
  switch (role) {
    case 'user': return 'Medarbetare'
    case 'manager': return 'Föreståndare'
    case 'admin': return 'Administratör'
    case 'superadmin': return 'Superadministratör'
    case 'dev': return 'Utvecklare'
    default: return 'ERR'
  }
}

export function getRoleAsString(role) { return getRoleName(role.toLowerCase()) }

export function getRoleNameAndBelow(role) {
  role = role.toLowerCase()
  return  role === 'user' ?
    getRoleName(role.toLowerCase()) + " (Alla)"
    :
    getRoleName(role.toLowerCase()) + " (samt högre roller)"
}

export const getRoleIcon = (role) => {
  switch (role) {
    case "superadmin": return superadminIcon;
    case "manager": return forestondareIcon;
    case "user": return medarbetareIcon;
    case "admin": return adminstratorIcon;
    default: return null;
  }
}

// const userRole = Object.freeze({enum: 0})
// const managerRole = Object.freeze({enum: 1})
// const adminRole = Object.freeze({enum: 2})
// const superadminRole = Object.freeze({enum: 3})
// const devRole = Object.freeze({enum: 10})
