import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import Template from "../pages/template/Template";
import Card from "../components/menus/CardMenu";
import "../assets/styles/Pages/StartPage.scss";
import { StartPageAccess } from "../hooks/Access/pages/StartPageAccess";
import {getRoleName} from "../store/firebase/roleEnums";
import {env, foo} from "../env";
import useUser from "../hooks/user/useUser.ts";

function StartPage() {
  const user = useSelector((state) => state.account.theUser);
  // const useUser = useUser()

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);


  const links = StartPageAccess()


  !env.verbose ? {}: console.debug(foo.kd + "user:", user.email)
  if (!user) return <></>
  return (
    <Template>
      <h1>Välkommen {user.firstName},</h1>
      <p>
        {getRoleName(user.role )} {user.unit ? 'på ' + user.unit : ''}
      </p>
      <p>Välkommen till din dashboard.</p>
      <div className="card-container">
        {links.map((link) => (
          <Link to={link.path} className="card-link" key={link.path}>
            <Card icon={link.icon} title={link.title} />
          </Link>
        ))}
        <div className="empty-card"></div>
        <div className="empty-card"></div>
      </div>
    </Template>
  );
}

export default StartPage;
