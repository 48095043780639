import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit, onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { getAuth } from "firebase/auth";
import {env, foo, invalidString, invalidUnitIds} from "../../env";
import { getRoleArray, elevatedRoles } from "../firebase/roleEnums";

const swe = new Intl.Locale("sv", { region: "SE", })
export const locale = { swe }

export async function fetchDocs(path, firstCondition, secondCondition, getDataArr, limitI) {
  if (!path) throw new Error('No path to collection specified')
  const linkToManual = 'https://firebase.google.com/docs/firestore/query-data/queries'

  // console.debug('process.env.REACT_APP_MOCK_DATA', process.env.REACT_APP_MOCK_DATA)
  let q = query(
    collection(db, path.replace("/", "")),
    where("disabled", "==", false),
    // where("firstName", "==", "dev"),
    limit(limitI || 500),
    // orderBy('creationTime')
  )

  function validateCondition(cond) {
    if (!cond[0]) throw new Error('First arg of condition invalid', cond[0], 'in', cond, linkToManual)
    if (!cond[1]) throw new Error('First arg of condition invalid', cond[1], 'in', cond, linkToManual)
    if (!cond[2]) throw new Error('First arg of condition invalid', cond[2], 'in', cond, linkToManual)
    return where(cond[0], cond[1], cond[2])
  }

  if (firstCondition && !secondCondition) {
    q = query(collection(db, path),
      where("disabled", "==", false),
      validateCondition(firstCondition),
      limit(limitI || 500),
      // orderBy('creationTime')
    )
  }

  else if (!firstCondition && secondCondition) {
    q = query(collection(db, path),
      where("disabled", "==", false),
      validateCondition(secondCondition),
      limit(limitI || 500),
      // orderBy('creationTime')
    )
  }

  else if (firstCondition && secondCondition) {
    q = query(collection(db, path),
      where("disabled", "==", false),
      validateCondition(firstCondition),
      validateCondition(secondCondition),
      limit(limitI || 500),
      // orderBy('creationTime')
    )
  }


  try {
    // let docs = {}
    let docsArr = []
    let dataArr = []
    // console.debug('getting docs...', path.replace("/", ""))
    const querySnapshot = await getDocs(addCacheLogger(q));
    querySnapshot.forEach((docSnap) => {
      dataArr.push(convertDocSnapshot(docSnap))
      docsArr.push(docSnap)
      // docs[doc.id] = doc
    })
    // const x = docs.
    if (env.verbose) console.debug(Object.keys(dataArr).length, "docs fetched from", path)
    if (env.verbose) console.debug(foo.kd, { docsArr, dataArr })
    if (getDataArr) return dataArr
    return docsArr || []
    // return docs || []
  } catch (e) {
    console.debug("e", e)
    return []
  }
}


function isSubdirectory(basePath, targetPath) {
  const normalizedBase = basePath.replace(/\/$/, '');
  const normalizedTarget = targetPath.replace(/\/$/, '');

  return normalizedTarget.startsWith(normalizedBase) && normalizedTarget[normalizedBase.length] === '/';
}

export function getFilteredContent(unit, role, unReadablePath) {
  return getContentInDirAndSubdirs(unit, role).then(docs => {
    return docs.filter(doc => isSubdirectory(unReadablePath, doc.path));
  }).catch(e => {
    console.error("Error while filtering content: ", e);
    throw e;
  });
}

export function fetchDocsData(path, condition, secondCondition, limitI) {
  return fetchDocs(path, condition, secondCondition, false, limitI)
    .then(docs => {
      const data = docs.map(doc => {
        return ({
          ...doc.data(),
          id: doc.id,
          fullpath: doc.ref.path
        })
      }
      )
      // const id = docs.map()
      // data.map(d => { console.debug(0, foo.kev, d.id, d.name) })
      return data
    })
}
export function fetchDocsDataSerializeable(path, condition, secondCondition) {
  return fetchDocs(path, condition, secondCondition, true)
    .then(docs => (docs.map(docSnap => (
      convertTimestampsToString(docSnap)
    ))))
}

export function getAllUnitIds() {
  return fetchDocs('units/')
    .then(units => (units.map(unit => (unit.id))))
    .catch(e => { console.error(e) })
}
export function getAllUnitIdsAndNames() {
  return fetchDocs('units/')
    .then(units => {
      const ob = units.map(unit => {
        const id = unit.id
        const name = unit.data().name
        ob[id] = name
        return { id: name }
      })
      return ob
    })
    .catch(e => { console.error(e) })

}
export function getFolderRole(path) {
  return fetchDocByFullpath(path).then(res => (res.role)).catch(e => {throw e})
}
export function getUnitName(id) {
  if (id === 'Alla' || invalidString.includes(id)) return new Promise((resolve, reject) => (resolve(id)))
  return fetchDoc('units', id)
  .then(docSnap => (docSnap.data().name))
  .catch(e => {
    console.warn('invalid id:', id)
    env.devmode?console.error(e):{}
    return 'err'
  })
}
export function getUnitNameInstantly(id, units) {
  if (id === 'Alla' || invalidString.includes(id)) return 'Samtliga enheter'
  const res = units.find(unit => unit.id === id)
  if (res === undefined) {
    console.debug('1234', id, units, res)
    return 'Enhet borttagen'
  }
  return res.name
}
export function getUnitByName(name) {
  return fetchDocs('units', ['name', '==', name], false, true)
  .then(res => (res[0]))
  .catch(e => {
    throw e
  })
}

export function getAllWithRole(role) {
  if (!role) throw new Error('No role specified')
  const cond = ['role', 'in', [role]]
  return fetchDocs("users/", cond)
}

export function fetchDoc(collection, docId, onlyData) {
  if (!collection) throw new Error("Database collection not defined")
  if (!docId) throw new Error("Document ID not defined")
  // console.debug('Getting doc', docId)
  const docRef = doc(db, collection, docId)
  return getDoc(docRef)
    .then(doc => {
      if (onlyData) return convertDocSnapshot(doc)
      else return doc
    })
    .catch(e => { throw e })
}

export function fetchDocData(collection, docId) {
  return fetchDoc(collection, docId, true)
}

export function fetchDocDataSerializeable(collection, docId) {
  return fetchDoc(collection, docId, true)
    .then(doc => ((convertTimestampsToString(doc))))
}

export function fetchDocByFullpath(path) {
  return fetchDocByRef(doc(db, path)).catch(e => {throw e})
}

export function fetchDocByRef(docRef) {
  return getDoc(docRef)
    .then(docSnap => (
      convertTimestampsToString(
        convertDocSnapshot(docSnap)
      )
    ))
    .catch(e => { throw e })
}

export function getDocRef(path, pathsegments) {
  return !pathsegments ? doc(db, path) : doc(db, path, pathsegments)
}

export function convertQueryDocSnapshot(queryDocSnap) {
  return queryDocSnap.map(docSnap => {

    const data = docSnap.data()
    const id = docSnap.id
    const path = docSnap.ref.path

    //to do dubbletter
    const uid = docSnap.id
    const fullpath = docSnap.ref.path
    const fullPath = docSnap.ref.path


    return { ...data, id, path, uid, fullPath, fullpath }
  })
}

export function convertDocSnapshot(docSnap) {
  const data = docSnap.data()
  const id = docSnap.id
  const path = docSnap.ref.path

  //to do dubbletter
  const uid = docSnap.id
  const fullpath = docSnap.ref.path
  const fullPath = docSnap.ref.path

  return { ...data, id, path, uid, fullPath, fullpath }
}

export function convertTimestampsToString(doc) {
  if (!doc) return null
  const creationTime = doc.creationTime?.toMillis() || ''
  let updateTime
  if (doc.updateTime) updateTime = getTimeValue(doc.updateTime)
  else if (doc.updateTime) updateTime = getTimeValue(doc.updateTime)
  return {
    ...doc,
    creationTime,
    updateTime
  }
}

function getTimeValue(time) {
  if (time === undefined) return ''
  else if (typeof time === 'number') return time
  else return time.toMillis()
}

export function convertTimestampsFromString(doc) {
  return {
    ...doc,
    creationTime: Timestamp.fromMillis(doc.creationTime),
    updateTime: Timestamp.now(),
  }
}

// async function subToDocs(q) {
//   // const q = query(
//   //   collection(db, "users"),
//   //   where("disabled", "!=", true)
//   // )
//   // const querySnapshot = await getDocs(q)
//   // console.debug('end of getting', querySnapshot)
//   // const unsubscribe = onSnapshot(q, (querySnapshot) => {
//   //   const docs = [];
//   //   querySnapshot.forEach((doc) => {
//   //     console.log(doc.id, " => ", doc.data())
//   //     docs.push(doc.data());
//   //   })
//   //   return docs
//   // })
//   // unsubscribe()
// }
export function getUnits(role) {
  const user = JSON.parse(getAuth().currentUser.reloadUserInfo.customAttributes)
  if (!role) role = user.role
  const ra = getRoleArray(role)
  if (!ra.includes('user')) throw new Error('Invalid role')
  const q = query(collectionGroup(db, 'units'))

  return getDocs(addCacheLogger(q)).then(units => { return units }).catch(e => { throw e })
}

export function fetchAllFolderDirectoryDocs(unit = undefined, role = undefined, bool = true) {
  const user = JSON.parse(getAuth().currentUser.reloadUserInfo.customAttributes)
  if (!role) role = user.role
  if (env.verbose) console.log('fetchAllDirectoryPaths', 'unit:', unit, 'role', role)
  let q
  const ra = getRoleArray(role)
  if (elevatedRoles.includes(role) && !unit) {
    if (env.verbose) console.debug('Role elevated')
    q = query(collectionGroup(db, 'content'),
      where('disabled', '==', false),
      where('directory', '==', bool),
      where('role', 'in', ra),
    )
  }
  else if (unit === undefined) throw new Error("Non-privileged role, unit needs to be specified")
  else {
    if (env.verbose) console.debug('Including role in query', role)
    q = query(collectionGroup(db, 'content'),
      where('disabled', '==', false),
      where('directory', '==', bool),
      where('role', 'in', ra),
      where('unit', 'in', [unit]),
    )
  }
  return getDocs(addCacheLogger(q)).then(dirs => {
    env.verbose?console.debug(1612, dirs.docs):{}
    return dirs.docs.map(d => {//d.data())
      const id = d.id
      const path = d.ref.path
      const fullpath = d.ref.path
      env.verybose?console.debug(1612, id, path, fullpath):{}
      const doc = { ...d.data(), id, path, fullpath }
      return convertTimestampsToString(doc)
    })
  }).catch(e => { throw e })
}

export function getContentInDirAndSubdirs(unit, role, limitI) {

  // If path är tom eller inte.. 
  if (!role) {
    const user = JSON.parse(getAuth().currentUser.reloadUserInfo.customAttributes)
    role = user.role
  }
  if (env.verbose) console.debug(foo.kd, 'fetchAllDirectoryPaths', 'unit:', unit, 'role', role)
  let q
  const ra = getRoleArray(role)
  const unitArrWithAll = [unit, 'Alla']
  const unitArr = [unit]

  switch (elevatedRoles.includes(role)) {
    case true:
      if (!unit) {
        if (env.verbose) console.debug('Role elevated, no unit')
        q = query(collectionGroup(db, 'content'),
          where('disabled', '==', false),
          where('directory', '==', false),
          where('role', 'in', ra),
        )
      } else {
        if (env.verbose) console.debug('Role elevated, filtered by unit')
        q = query(collectionGroup(db, 'content'),
          where('disabled', '==', false),
          where('directory', '==', false),
          where('role', 'in', ra),
          where('unit', 'in', [unit])
        )
      }
      break
    default:
      if (limitI) {
        if (env.verbose) console.debug(foo.kd, 'Including role and limit in query', role)
        q = query(collectionGroup(db, 'content'),
          where('disabled', '==', false),
          where('directory', '==', false),
          where('role', 'in', ra),
          where('unit', 'in', unitArr),
          orderBy('creationTime'),
          limit(limitI)
        )
      } else if (!limitI) {
        if (env.verbose) console.debug(foo.kd, 'Including role in query', role)
        q = query(collectionGroup(db, 'content'),
          where('disabled', '==', false),
          where('directory', '==', false),
          where('role', 'in', ra),
          where('unit', 'in', unitArr),
          orderBy('creationTime'),
        )
      }
  }

  return getDocs(addCacheLogger(q)).then(dirs => {
    if (!dirs) return [];
    if (dirs.empty) return [];
    const docs = dirs.docs

    // console.debug(dirs, dirs.docs)
    return convertQueryDocSnapshot(dirs.docs)
  }).catch(e => { throw e })
}



/*Hämta och returnera alla dokument baserat på roll. */
export function getAllDocuments(role) {

  const ra = getRoleArray(role)
  const user = JSON.parse(getAuth().currentUser.reloadUserInfo.customAttributes)
  let q

  if (role === "dev" || role === "superadmin" || role === "admin") {
    q = query(collectionGroup(db, 'content'),
      where('directory', '==', false))
    console.log("res", q);
  }
  else if (role === "manager") {
    q = query(collectionGroup(db, 'content'),
      where('disabled', '==', false),
      where('directory', '==', false),
      where('role', 'in', ra),
      where('unit', 'in', user.unitId),
      orderBy('creationTime'),
    )
  }
  else {
    // PROBIBLY USER, DO NOTHING
  }
  return getDocs(addCacheLogger(q)).then(dirs => {
    if (!dirs) return [];
    if (dirs.empty) return [];
    const docs = dirs.docs
    return docs.map(doc => {
      const id = doc.id
      const path = doc.ref.path
      const fullpath = doc.ref.path
      const data = doc.data()
      const sum = { ...data, id, path }

      return sum
    })
  }).catch(e => { throw e })
}




export function getContentInDir(docPath, role, onlyData) {
  return getSubDirs(docPath, role, onlyData, false)
}


export function getSubDirsRecursive(docPath, role, unit) {
  const q = query(collectionGroup(db, 'content'),
    where('disabled', '==', false),
    where('directory', '==', true),
    where('role', 'in', getRoleArray(role)),
    // where('unit', 'in', createUnitArray(unit)),
  )


  return getDocs(addCacheLogger(q)).then(qSnapShot => {
    console.assert(qSnapShot.docs.length > 0, 'qSnapShot.docs.length > 0 | unit', unit)
    return convertQueryDocSnapshot(qSnapShot.docs)
  })

}

export function getSubDirs(docPath, role, onlyData, bool = true) {
  if (!role) {
    const user = JSON.parse(getAuth().currentUser.reloadUserInfo.customAttributes)
    role = user.role
  }
  const path = docPath
  let q

  if (elevatedRoles.includes(role)) {
    q = query(collection(db, path, '/content'),
      where('disabled', '==', false),
      where('directory', '==', bool),
      where('role', 'in', getRoleArray(role)),
    )
  } else {
    q = query(collection(db, path, '/content'),
      where('disabled', '==', false),
      where('directory', '==', bool),
      where('role', 'in', getRoleArray(role)),
      //where('unit', '==', createUnitArray(role))
    )
  }
  const subDirectories = []
  const subDirectoriesData = []

  return getDocs(addCacheLogger(q)).then(dirs => {
    const subDirectories = []
    dirs.forEach((dir) => {
      const text = {
        path: dir.ref.path,
        id: dir.id,
        // creationTime: timeStampConversionToString(dir.data().creationTime),
        ...dir.data()
      }
      // .data().fullpath

      // if (env.verbose) console.debug(foo.kd, 'getSubDirs', text,)
      // console.log([text, getSubDirs(dir, role)])
      // return [text, getSubDirs(dir, role)]
      subDirectories.push(dir)
      subDirectoriesData.push(text)
    })
    if (!onlyData) return subDirectories
    else return subDirectoriesData
  })
}

function createUnitArray(role) {
  switch (role) {
    case 'manager': return ['manager', 'user']
    default: return ['user']
  }
}

// export function getDocRef(collection, docId) {
// const docref = ref(db, collection, docid)
// getDoc(docref).then(doc => {
//
// })
// }


function timeStampConversionToString(timestamp) {
  const { seconds, nanoseconds } = timestamp
  const text = String(seconds) + "¤" + String(nanoseconds)
  return text
}

function timeStampConversion(input) {
  const times = input.split("¤")
  return {
    seconds: times[0],
    nanoseconds: times[1],
  }
}
export function firestoreTimestampToString(timestamp, locale) {
  // eslint-disable-next-line
  if (!locale) locale = "sv", { region: "SE", }
  const swe = new Intl.Locale(locale)
  return timestamp.toDate().toLocaleDateString(swe)
}

export function prettyPath(fullpath, unitId, docs, units, replaceArr = ['', ''], replaceArr2 = ['', '']) {
  if (!fullpath && !unitId) return 'ERR: nothing to parse'
  function getUnitDoc(unitId) { return units.find(u => u.id === unitId) || '' }
  function getDoc(path) { return docs.find(d => d.path === path) }


  if (fullpath && unitId) {
    if (!docs) return "ERR: No docs to parse"
    if (!units) return 'ERR: No units to parse'
    const { path, id, title, name, fileName } = getDoc(fullpath)
    console.debug(0, path, title || name || fileName)
    return path
      .replaceAll("content/", "")
      .replace("folders/", "")
      .replace(id, name || title || fileName)
      .replace(getUnitDoc(unitId).id, getUnitDoc(unitId).name)
      .replace(replaceArr[0], replaceArr[1])
      .replace(replaceArr[0], replaceArr2[1])
  }
  if (fullpath) {
    console.debug(1)
    if (!docs) return "ERR: No docs to parse"
    const doc = getDoc(fullpath)
    const { path, id, title, name, fileName } = doc
    return path
      .replaceAll("content/", "")
      .replace("folders/", "")
      .replace(id, name || title || fileName)
      .replace(replaceArr[0], replaceArr[1])
      .replace(replaceArr[0], replaceArr2[1])
      || 'ERR: path not found'
  }
  if (unitId) return getUnitDoc(unitId).name || 'ERR: no unit found'
}

export function humanreadablePath(path, id, text, allUnits, option) {
  if (!path) return;
  // if (env.verybose) console.debug(foo.kd, 'path:', path, '\nid', id, '\ntext', text, option, '\nallUnits', allUnits)

  const getUnitName = (id) => {
    const matchingUnit = allUnits.find(unit => unit.id === id);
    return matchingUnit ? matchingUnit.name : undefined;
  };
  if (!text && allUnits) text = getUnitName(id)

  if (!id || invalidUnitIds.includes(id))
    return path
      .replace("byggnad/", "")
      .replace("folders/", "")
      .replaceAll("content/", "")

  return path
    .replace("byggnad/", "")
    .replace("folders/", "")
    .replaceAll("content/", "")
    .replace(id + "/", text + "/" || '/')
}

export function addToStringForFiltering(doc) {
  return { ...doc, toString: String(Object.values(convertTimestampsToString(doc)).map(i => (i))).toLowerCase() }
}

export function convertFileName(oldName, newName) {
  if (!newName) return oldName;
  const fileExtension = oldName.split(".")[oldName.split(".").length - 1]
  return newName + "." + fileExtension
}

export function sortDocsByCreationTime(docs, descending) {
  return new Promise((resolve, reject) => {
    if (!docs) reject('No docs to sort')
    descending ? resolve(sortDescending(docs)) : resolve(sortAscending(docs))
  })
}
let isString = value => typeof value === "string" || value instanceof String;
function sortDescending(docs) {
  if (!isString(docs[0].creationTime)) return docs.sort((a, b) => (a.creationTime.seconds < b.creationTime.seconds ? -1 : 1))
  else return docs.sort((a, b) => (a.creationTime < b.creationTime ? -1 : 1))
}
function sortAscending(docs) {
  if (!isString(docs[0].creationTime)) return docs.sort((a, b) => (a.creationTime.seconds > b.creationTime.seconds ? 1 : -1))
  else return docs.sort((a, b) => (a.creationTime > b.creationTime ? 1 : -1))
}

export function sorter(ints, desc) {
  return !desc ? ints.sort((a, b) => (a.creationTime - b.creationTime)) : ints.sort((a, b) => (b.creationTime - a.creationTime))
}

function addCacheLogger(q) {
  onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        // if (env.devmode && env.verbose) console.log("Cache: New data: ", change.doc.data());
      }
      const source = snapshot.metadata.fromCache ? "local cache" : "server";
      const date = new Date()
      // if (env.devmode && env.verbose) console.log('Cache logger', date.getHours(), date.getMinutes(), ": Data came from:", source/*+ '\n' + source.includes('local') + " = local data"*/);
      // if (env.devmode && env.verbose) console.log(source.includes('local'), 'local cache');
    })
  })
  return q
}

const nil = ''
//prettier-ignore
export const docPlaceholder = {
  name: nil,
  uid: nil,
  author: nil,
  firstName: nil,
  lastName: nil,
  email: nil,
  imagePath: nil,
  filePath: nil,
  unit: nil,
  role: nil,
  title: nil,
  description: nil,
  city: nil,
  headmaster: nil,
  phoneNr: nil,
  zipCode: nil,
}
