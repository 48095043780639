import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "../src/components/App";
import { Provider } from "react-redux";
import { store } from "./store/reducers/rootreducer";
import reportWebVitals from "./reportWebVitals";
import { version } from './assets/version'
import "firebase/auth"
import { env } from "./env";

if (window.location.hostname === 'localhost')
  document.title = '(local) ' + document.title + ' ' + version

if (env.verbose) console.log('verbose debug console', env.verbose)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
