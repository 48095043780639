import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  json,
} from "react-router-dom";
import "../assets/styles/global.scss";
import "../../src/App.css";
import LoginPage from "../pages/LoginPage";
import StartPage from "../pages/StartPage";
import ProfilePage from "../pages/ProfilePage";
import AccountPage from "../pages/AccountPage";
import EditAccountPage from "../pages/EditAccountPage";
import UnitPage from "../pages/UnitPage";
import EditUnitPage from "../pages/EditUnitPage";
import NewsPage from "../pages/NewsPage";
import EditNewsPage from "../pages/EditNewsPage";
import DocumentPage from "../pages/DocumentPage";
import FolderPage from "../pages/FolderPage";
import BuildingPage from "../pages/BuildingPage";
import RemoveFolderPage from "../pages/RemoveFolderPage"
import BuildingContactPage from "../pages/BuildingContactPage";
import BuildingLevel1FolderPage from "../pages/BuildingLevel1FolderPage";
import BuildingLevel2FolderPage from "../pages/BuildingLevel2FolderPage";
import BuildingLevel3FolderPage from "../pages/BuildingLevel3FolderPage";
import BuildingLevel4FolderPage from "../pages/BuildingLevel4FolderPage";
import SignInMenu from "../components/menus/SignInMenu";
import {env, foo} from "../env"
import { elevatedRoles } from "../store/firebase/roleEnums";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentAuth, setCurrentUser} from "../store/reducers/accountReducer";
import useUser from "../hooks/user/useUser.ts";
import useProfile from "../hooks/user/useProfile.ts";
import {convertTimestampsToString} from "../store/actions/fetchDocs";

const page = {
  start: '/startsida',
  nyheter: '/hantera-nyheter',
  enheter: '/enheter',
  min: '/enhet/',
  profil: '/profil',
  konton: '/konton',
  dok: '/hantera-dokument',
  mapp: '/hantera-mappar',
}

function App() {
  const firstPage = page.start
  const dispatch = useDispatch();
  const auth = useUser()
  const profile = useProfile(auth?.user)
  // const user = useSelector((state) => state.account.user)
  // const [isLoggedIn, setIsLoggedIn] = useState(null)
  // const currentAuth = useSelector((state) => state.account.auth)
  const [partialUser, setPartialUser] = useState(null)
  const [bId, setBiD] = useState('1')

  useEffect(() => {
    console.debug('debugg', 1, auth.user?.uid)
    if ( auth.user?.uid) {
      const {displayName, email, lastLoginAt, lastRefreshAt, localId, uid} = auth.user
      const preJson = auth?.user?.auth?.currentUser?.reloadUserInfo?.customAttributes
        if (profile.profile)
          dispatch(setCurrentUser(convertTimestampsToString(profile.profile)))
      console.debug('preJson', preJson)
      if (preJson !== undefined) {
        const attr = JSON.parse(preJson)
        const {role, unitId} = attr
        const data = {uid, role, unitId, displayName, email, lastLoginAt, lastRefreshAt, localId}
        // if (partialUser !== data) setPartialUser(data)
        if (unitId !== bId) setBiD(unitId)
        // console.debug('setting partialuser', data)
        // setPartialUser(data)
        dispatch(setCurrentAuth(data))
      }
    }
    else {
      // dispatch(setCurrentUser(null))
      dispatch(setCurrentAuth(null))
      // setIsLoggedIn(null)
    }
  }, [auth, dispatch]);

  // useEffect(() => {
  //   console.debug('debugg', 2)
  //     setIsLoggedIn(true)
  //   }
  // }, [partialUser]);

  const isLoggedIn = auth.user !== null

  if (env.devtest) console.debug("\nisLoggedIn:", isLoggedIn)
  console.debug(1337, 'useprofile:' ,
    // !profile ? 'no profile' :''
      // !user ? 'no user' :
      //   'role: ' + user.role + ' | ' +
      //   'unitid: ' + user.unitId + ' | ' +
      //   'unit: ' +user.unit,
  )
  console.debug('______')
  console.debug('auth.user:', auth?.user?.auth?.currentUser?.reloadUserInfo?.customAttributes
  ) //auth.currentUser.reloadUserInfo.localId
  console.debug('auth.user:', auth?.user//?.uid
  ) //auth.currentUser.reloadUserInfo.localId
  // console.debug('currentAuth:', currentAuth
  // )
  // console.debug('user:', user
  // ) //auth.currentUser.reloadUserInfo.localId
  console.debug('profileunitid:', profile.profile?.unitId
  ) //auth.currentUser.reloadUserInfo.localId
  console.debug('______')
  // if (!firstPage) return <div>Loading...</div>
  return (
    <Router>
      <div className="App">
        {isLoggedIn && <SignInMenu />}
        <Routes>
          <Route
            path="/"
            element={
              !isLoggedIn ?
                <LoginPage />
                :
                !elevatedRoles.includes(partialUser?.role || 'user') ?
                  // <BuildingPage />
                  <Navigate replace to={"enhet/start"/* + bId*/} />
                  :
                  <Navigate replace to={firstPage} />
            }
          />
          <Route
            path="/startsida"
            element={isLoggedIn ? <StartPage /> : <Navigate replace to="/" />}
          />
          <Route
            path="/profil"
            element={isLoggedIn ? <ProfilePage /> : <Navigate replace to="/" />}
          />
          <Route
            path="/konton"
            element={isLoggedIn ? <AccountPage /> : <Navigate replace to="/" />}
          />
          <Route
            path="/editera-konto/:uid"
            element={
              isLoggedIn ? <EditAccountPage /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/enheter"
            element={isLoggedIn ? <UnitPage /> : <Navigate replace to="/" />}
          />
          <Route
            path="/editera-enhet/:uid"
            element={
              isLoggedIn ? <EditUnitPage /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/hantera-nyheter"
            element={isLoggedIn ? <NewsPage /> : <Navigate replace to="/" />}
          />
          <Route
            path="/editera-nyhet/:uid"
            element={
              isLoggedIn ? <EditNewsPage /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/hantera-dokument"
            element={
              isLoggedIn ? <DocumentPage /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/hantera-mappar"
            element={isLoggedIn ? <FolderPage /> : <Navigate replace to="/" />}
          />
          <Route
            path="/enhet/:id"
            element={
              isLoggedIn ? <BuildingPage /> : <Navigate replace to="/" />
            }
          />

          <Route
            path="/kontaktaenhet/:id"
            element={
              isLoggedIn ? <BuildingContactPage /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/raderamapp/"
            element={
              isLoggedIn ? <RemoveFolderPage /> : <Navigate replace to="/" />
            }
          />

          {/* Level 1 - byggnad/allaMappar*/}
          <Route
            path="/enhet/:id/mappar"
            element={
              isLoggedIn ? (
                <BuildingLevel1FolderPage />
              ) : (
                <Navigate replace to="/" />
              )
            }
          />
          {/* Level 2 - enhet/mapp1*/}
          <Route
            path="/enhet/:buildingId/mappar/:folderId"
            element={
              isLoggedIn ? (
                <BuildingLevel2FolderPage />
              ) : (
                <Navigate replace to="/" />
              )
            }
          />
          {/* Level 3 - enhet/mapp1/mapp2*/}
          <Route
            path="/enhet/:buildingId/mappar/:folderId/:level2ID"
            element={
              isLoggedIn ? (
                <BuildingLevel3FolderPage />
              ) : (
                <Navigate replace to="/" />
              )
            }
          />
          {/* Level 4 - enhet/mapp1/mapp2/ här visas endast innehåll*/}

          <Route
            path="/enhet/:buildingId/mappar/:folderId/:level2ID/:level3ID"
            element={
              isLoggedIn ? (
                <BuildingLevel4FolderPage />
              ) : (
                <Navigate replace to="/" />
              )
            }
          />
          <Route
            path="*"
            element={<h1>Välkommen! Välj en byggnad från menyn.</h1>}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
