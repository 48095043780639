import React from "react";
import "../../../../assets/styles/Listing/template/BuildingFolderHeadlineTemplate.scss";
import {Link, useParams} from "react-router-dom";
import {createNextState} from "@reduxjs/toolkit";

const BuildingLevel2FolderTemplate = ({
  icon,
  title,
  nextfolder,
}) => {
  const isIconEmoji = typeof icon === "string" && icon.length < 10;
  const {buildingId, folderId} = useParams()

  return (
    <div className="card">
      <Link to={"/enhet/" + buildingId + "/mappar/" + folderId + "/" + nextfolder}
        className="folder-link"
      >
        <div className="card-icon">
          {isIconEmoji ? icon : <img src={icon} alt={`${title} icon`}/>}
        </div>
        <div className="card-title">{title}</div>
      </Link>
    </div>
  )
};

export default BuildingLevel2FolderTemplate;
