// src/reducers/accountReducer.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signUp, updateUserAccount, deleteUser } from "../actions/authActions";
import { deleteDocFully, disableDoc } from "../actions/setDocs-alt";
import {setDoc} from "firebase/firestore";


/*Skapa användare */
export const makeUser = createAsyncThunk(
  "account/makeUser",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const uid = await signUp({ formData });
      if (uid !== false) {
        const path = "users/" + uid
        const fileNameSegments = formData.imagePath.split("/")
        const fileName = fileNameSegments[fileNameSegments.length - 1]
        const imagePath = "public/profile_pictures/" + uid + "/" + fileName
        const data = { imagePath }
        // export function updateUserAccount(uid, formData) {
        if (formData.imagePath !== '') updateUserAccount(uid, data)
        // updateDoc(path, uid, data)
        return uid;
      } else {
        return rejectWithValue("Gick inte att skapa användare");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const disableUser = createAsyncThunk(
  "account/disableUser",
  async (uid, { rejectWithValue }) => {
    try {
      await disableDoc("users", uid);
      await deleteDocFully("users", uid);
      return uid;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSpecificUser = createAsyncThunk(
  "news/deleteUser",
  async ({ uid }, { rejectWithValue }) => {
    try {
      const result = await deleteUser(uid);
      if (result === true) {
        return { uid };
      } else {
        return rejectWithValue("Gick inte att radera nyhet");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "account/updateUser",
  async ({ uid, formData }, { rejectWithValue }) => {
    try {
      const result = await updateUserAccount(uid, formData);
      if (result === true) {
        const { creationTime, ...serializableFormData } = formData;
        return { uid, ...serializableFormData };
      } else {
        return rejectWithValue("Gick inte att uppdatera användare");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    users: [],
    theUser: {},
    status: "idle",
    selectedUser: null,
    error: null,
    user: null,
    currentAuth: null,
  },
  reducers: {
    setCurrentAuth: (state, action) => {
      state.currentAuth = action.payload;
      state.auth = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.theUser = action.payload;
      state.user = action.payload
    },
    setUsers: (state, action) => {
      state.theUser = action.payload;
    },
    setSelectedUser: (state, action) => {
      const { creationTime, updateTime, ...updatedPayload } = action.payload;
      state.selectedUser = updatedPayload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(makeUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(makeUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(disableUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(disableUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = null;
      })
      .addCase(disableUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteSpecificUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSpecificUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = state.users.filter(
          (user) => user.uid !== action.payload.uid
        );
      })
      .addCase(deleteSpecificUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { setCurrentAuth,
  setUsers,
  setSelectedUser,
  setCurrentUser
} = accountSlice.actions;
export default accountSlice.reducer;
