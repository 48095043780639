import React, { useState, useEffect, useRef } from "react";
import Template from "../pages/template/Template";
import UnitForm from "../components/Forms/UnitForm";
import UnitListing from "../components/Listing/UnitListing.js";
import "../assets/styles/Pages/AccountPage.scss";
import { fetchDocs } from "../store/actions/fetchDocs";
import filterIcon from "../assets/images/icons/filter-icon-white.png";
import {BackButton} from "../components/Button.js"
import {env} from "../env";

function UnitPage() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleFormSubmit = (formData) => {
    //console.log("Formulärdata: ", formData);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  return (
    <Template>
      <BackButton />
      <h1>Enheter</h1>
      <p>Lägg till/ändra dina enheter här...</p>
      <UnitForm onSubmit={handleFormSubmit} />

      <div className="profile-page">
        <section className={env.devmode ? "special-section" : 'hidden'}>
          {/*to do*/}
          <h2>Att göra</h2>
          <p>
            Denna sektion har unika stilar definierade i UnitPage.scss.
            Noterbart att vi ännu inte har implementerat alla värden för
            enhetsidan.
          </p>
        </section>
      </div>
      <section className="template-subsection">
        <section className="left-section">
          <h1>Skapade enheter</h1>
          <p>Dina skapade enheter listas nedan...</p>
        </section>
        <section className="right-section">
          <input
            type="text"
            name="search-filter-field"
            className="searchFilterbtn"
            placeholder="Sök efter enhet här"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </section>
      </section>

      <UnitListing searchQuery={searchQuery} />
    </Template>
  );
}

export default UnitPage;
