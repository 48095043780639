import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logotyp/company-logotype.png";
import "../../assets/styles/Menues/SignInMenu.scss"; // Importera din SCSS-fil här
import UserIcon from "../../assets/images/icons/user-icon.png"
import {logoutUser} from "../../store/reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import DevTestMenu from "../../devTest/devTestBackendButton";
import {getFileUrl} from "../../store/actions/handleStorage";
import {consolecheck, env, foo} from "../../env";
import useUser from "../../hooks/user/useUser.ts";
import useProfile from "../../hooks/user/useProfile.ts";
import {setCurrentUser} from "../../store/reducers/accountReducer";
import {convertTimestampsToString} from "../../store/actions/fetchDocs";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // State för att hantera hamburgarmeny på mobila enheter
  const dispatch = useDispatch();
  /*Hämtar data om aktuell användare från Global state*/
  // const user = useSelector((state) => state.account.theUser);
  const [img, setImg] = useState(UserIcon);
  const auth = useUser()
  const profile = useProfile(auth?.user)
  const user = profile.profile;
  const [prevUser, setPrevUser] = useState();
  const [consoleChecked, setConsoleChecked] = useState(false)
  if (!consoleChecked) setConsoleChecked(consolecheck('signinmenu'))

  useEffect(() => {
    if (prevUser !== user) {
      setPrevUser(user)
      dispatch(setCurrentUser(convertTimestampsToString(user)))
    }
  }, [user]);

  useEffect(() => {
    let ignore
    if (!ignore && user?.imagePath) {
      if (env.verbose) console.debug(50, foo.kd, user.imagePath)
      getFileUrl(user?.imagePath, user?.role).then(url => setImg(url))
    }
    return () => {ignore = true}
  }, [user]);


  return (
    <nav className="navbar">
      <div className="wrapper">
        <div className="navbar-logo">
          <Link to={"/enhet/" + user?.unit}>
            <img src={logo} alt="Logotyp" />
          </Link>
        </div>

        <DevTestMenu/>

        <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          {/* Ikon för hamburgarmeny */}
          &#9776;
        </button>
        <div className={`nav-items ${isOpen ? "open" : ""}`}>
          <Link to={"/enhet/" + profile?.profile?.unit} >Startsida</Link>

          <Link to="/startsida">Hantera</Link>

          <Link to="/profil">Min Profil</Link>

          <div onClick={e => { dispatch(logoutUser()) }}>
            <a href="#">Logga ut</a>
          </div>
          <Link to="/profil" className="pfp-wrapper"><img
            src={img}
            alt={'profile'}
            className="profile-image"
            id={"profile"}
          /></Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
