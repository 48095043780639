import React, { useEffect, useState } from "react";
import NewsTemplate from "./template/NewsTemplate";
import { fetchDocs, fetchDocsData, sortDocsByCreationTime, sorter } from "../../store/actions/fetchDocs";
import {getRoleArray} from "../../store/firebase/roleEnums";
import useUser from "../../hooks/user/useUser.ts";

const NewsListing = ({
  searchQuery,
  filterTypeRole,
  filterValueRole,
  filterTypeUnit,
  filterValueUnit,
  theUser
}) => {
  const [news, setNews] = useState([]);
  const user = useUser()


  useEffect(() => {
    let ignore = false;
    const unsorted = [{ creationTime: 3 }, { creationTime: 2 }, { creationTime: 6 }, { creationTime: 4 }, { creationTime: 9 }, { creationTime: 8 }]
    console.log('sorted asc', sorter(unsorted))
    console.log('sorted desc', sorter(unsorted, true))
    if (!ignore && user && theUser) fetchDocsData("news", ['role', 'in', getRoleArray(theUser.role)])
      .then(res => {
        sortDocsByCreationTime(res, false)
          .then(sortedNews => {
            console.log('unsorted docs\n', res.map(n => ({ [n.creationTime.seconds]: n.title })), '\nsortedNews\n', sortedNews.map(n => ({ [n.creationTime.seconds]: n.title })))
            setNews(sortedNews.toReversed())
          })
          .catch(e => console.error(e))
      })
    .catch(e => {console.warn(e)})
    return () => {
      ignore = true;
    };
  }, []);

  const filteredNews = news.filter((newsItem) => {
    const matchesSearchQuery =
      newsItem.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      newsItem.content?.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesRoleFilter =
      filterTypeRole === "role" ? newsItem.role === filterValueRole : true;

    const matchesUnitFilter =
      filterTypeUnit === "unit" ? newsItem.unitId === filterValueUnit : true;

    return matchesSearchQuery && matchesRoleFilter && matchesUnitFilter;
  });

  if (news.length === 0) return <p>Inga nyheter att visa</p>;
  if (news === undefined) return <></>;

  return (
    <div key="units" className="unit-list">
      {filteredNews.map((newsItem) => (
        <NewsTemplate key={newsItem.id} news={newsItem} theUser={theUser} />
      ))}
    </div>
  );
};

export default NewsListing;
