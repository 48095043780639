import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "../../../../assets/styles/Listing/template/BuildingLevel1DocumentTemplate.scss";
import PopupEditDocuments from "../../../Popups/PopupEditDocuments"
import {
    convertTimestampsFromString,
    fetchDocByFullpath,
    fetchDocDataSerializeable,
    getDocRef, getUnitName, getUnitNameInstantly,
    humanreadablePath
} from "../../../../store/actions/fetchDocs";
import {env, foo, ReloadPage} from "../../../../env";
import {deleteDoc, Timestamp} from "firebase/firestore";
import {createDoc, deleteDocFully} from "../../../../store/actions/setDocs";
import {deleteFile, getFileRef, getFileUrl, prepDownload, startDownload} from "../../../../store/actions/handleStorage";
import {getMetadata} from "firebase/storage";
import documentIcon from "../../../../assets/images/icons/document-icon.png"
import Icons from "../../../../assets/images/icons/Icons";

const DocumentPageTemplate = ({document, readabliePath, UnitName, allUnits, dirs, upCount, setUpCount}) => {
    const icons = Icons()
    const navigate = useNavigate();
    const [url, setUrl] = useState("");
    const [fileRef, setFileRef] = useState("");
    const [readablePath, setReadablePath] = useState("")
    const [prepped, setPrepped] = useState(false)
    const [prepping, setPrepping] = useState(false)
    const [docIcon, setDocIcon] = useState(icons.documentIcon);
    const [deleted, setDeleted] = useState(false)
    const [md, setMd] = useState({});
    function reloadPage() {ReloadPage("/hantera-dokument", {navigate}, 500)}
    const dwnLink = "download-link-" + document.path || '' // + document.uid
    const [fatalError, setFatalError] = useState(false);

    useEffect(() => {
        let ignore = false
        if (document.filePath && document.filePath !== '') {
        getFileUrl(document.filePath).then(url => setUrl(url))
        .catch(e => {
            console.error('Fatal error:', document.title, e)
            setFatalError(true)
        });
        getFileRef(document.filePath, true).then(ref => {
            setFileRef(ref)
            getMetadata(ref)
            .then(metadata => {
                setMd(metadata)
                if (metadata.contentType.includes('audio')) setDocIcon(icons.musicIcon)
                if (metadata.contentType.includes('image')) setDocIcon(icons.imageIcon)
                if (metadata.contentType.includes('zip')) setDocIcon(icons.zipIcon)
                // console.debug('15', foo.kd, ref)
            })
            .catch(e => console.error(e));
        })
        .catch(e => {
            console.error('Fatal error:', document.title, e)
            setFatalError(true)
        });


        return () => {ignore = true}
        }
    }, [document]);

    useEffect(() => {
        let ignore = false
        if (prepped) return;
        if (prepping) return;
        if (!fileRef) return;
        if (!md) return;

        const link = window.document.getElementById(dwnLink)
        const mbString = String(md.size * 0.000001).split(".")[0]
        const mb = parseInt(mbString)

        if (!link) return;
        if (mb < 10) setPrepping(true)
        if (mb < 10) prepDownload(fileRef, link)
        .then(fileName => {
            link.setAttribute('download', fileName)
            setPrepped(true)
            setPrepping(false)
            // console.debug('Download prepped', mb, fileName)
        })
        .catch(e => {
            if (e.code.includes('not-found')) console.debug(e.code, document.filePath)
            else console.error(e)
        })
        // console.debug('mbString', mbString)
        // console.debug('metadata', mb + 'MB', metadata)
        return () => {ignore = true}
    }, [md]);

    useEffect(() => {
        let ignore = false
        console.debug(1337, document.path, document.name)
        setReadablePath(humanreadablePath(document.path, document.unit, document.unitName, allUnits, 'no options').replace(document.id, ''))
        return () => {ignore = true}
    },[])

    const handleSelectChange = (selectName, value) => {
        console.log(`Changed ${selectName} to ${value}`);
    };

    const handleSubmit = (selectedUnit, selectedDir) => {
        !env.devmode?{}:console.log('10', foo.kd + 'selectedUnit', selectedUnit, '\nselectedDir', humanreadablePath(selectedDir), '\ncurrentPath', humanreadablePath(document.path) );
        const docRef = getDocRef(document.path)
        const parent = docRef.parent.parent
        console.debug('02', 'parent', parent.path)
        const newPath = selectedDir ? selectedDir + "/content/"  : 'folders/' + selectedUnit + "/content/"
        fetchDocByFullpath(document.path).then(doc => {
            const data = {formData: {
                ...convertTimestampsFromString(doc),
                unit: selectedUnit,
                directory:false,
            }}
            console.log('01',  newPath, data)
        createDoc(newPath, data, document.id).then(res => {
            deleteDoc(docRef).then(()=>{
                reloadPage()
                setUpCount(upCount +1)
            })
            .catch(e => { console.error(e) })

        }).catch(e => { console.error(e) })
        })
    };

    function convertFilePath(path, documentID) {
        const unitIds = allUnits.map(u=>(u.id))
        console.debug(0, document.unit, allUnits)
        if (unitIds.includes(document.unit)) {
            console.debug(1, document.unit, getUnitNameInstantly(document.unit, allUnits))
            return humanreadablePath(path, document.unit, getUnitNameInstantly(document.unit, allUnits)).replace(documentID, '')
        }
        else return getUnitName(document.unit).then(unitName => {
            console.debug(2, document.unit, unitName)
            return humanreadablePath(path, document.unit, unitName).replace(documentID, '')
        })
    }

    const handleClick = (e) => {

        const { id } = e.target;
        switch (id) {
            case 'edit':
                const visualPath = convertFilePath(document.path)
                console.log("edit", document)
                // go to new page with the exact document objeckt and send the visualPath aswell
                break;
            case 'delete':
                !env.devmode?{}:console.log("delete", document.filePath)

                if (!document.filePath || document.filePath === '') {
                    console.warn('File missing, only deleting doc')
                    return deleteDocFully(document.path)
                    .then(()=> {
                        setDeleted(true)
                        reloadPage()
                    })
                    .catch(e => console.error(e))
                }
                else {
                const segments = document.filePath.split("/")
                if (segments.length < 3) {
                    console.warn('Incorrect file path:', document.filePath, '| Deleting doc.')
                    deleteDocFully(document.path)
                    .then(()=> {
                        setDeleted(true)
                        reloadPage()
                    })
                    .catch(e => console.error(e))
                }
                else
                    deleteFile(document.filePath).then(() => {
                        deleteDocFully(document.path).then(()=> {
                            setDeleted(true)
                            reloadPage()})
                        .catch(e => console.error(e))
                    })
                    .catch((e) => {
                        if (e.code !== 'storage/object-not-found' ) console.error(e)
                        else deleteDocFully(document.path).then(()=> {
                            setDeleted(true)
                            reloadPage()
                        })
                        .catch(e => console.error(e))
                    })
                }
                break;
            case 'title':
            if (env.devtest) {
                window.open(url, '_blank')
            }
                break
            default:
                break;
        }

        //setSearchQuery(e.target.value);
    };

    function handleDownload() {
        console.log(/*id, */'fileName:', '=> url:\n' + url)
        const link = window.document.getElementById(dwnLink)
        if (prepped) {
            window.document.body.appendChild(link);
            startDownload(fileRef, link)
            .then(() => {
                link.parentNode.removeChild(link)
                setPrepped(false)
                console.log('Download finished')
            })
            .catch(e => {
                console.error(e)
            })
        } else {
            setPrepping(true)
            prepDownload(fileRef, link)
            .then(r => {
                link.setAttribute('download', fileRef.name)
                setPrepped(true)
                setPrepping(false)
                console.debug('Download prepped', r)
                window.document.body.appendChild(link);
                startDownload(fileRef, link)
                .then(() => {
                    link.parentNode.removeChild(link)
                    setPrepped(false)
                    console.log('Download finished')
                })
                .catch(e => {
                    console.error(e)
                })
            })
            .catch(e => console.error(e));
        }
    }

    let kb, mb, secondpart, filesize
    let ctype = ['','']
    if (md) {
        kb = String(md.size / 1000).split(".")
        mb = String(md.size / 1000000).split(".")[0] + " mb"
        secondpart = kb[0].length < 2 ? ("." + kb[1][0] + " kb") : (' kb')
        filesize = kb[0] + secondpart
        if (md.contentType) ctype = md.contentType.split("/")
    }
    return deleted || fatalError ? (<></>)
    : (
        <section className="folder-document-row-container">
            {" "}
            {/* Tillagd container klass */}
            <section className="folder-document-row">
                <section className="folder-document-cell folder-document-icon">
                    <img src={docIcon} alt={`${document.id} icon`}
                         className={docIcon === icons.imageIcon ? "filter-color" : ''} id='icon' onClick={handleClick}/>
                </section>


                {!env.devmode?<></>:<section style={{fontSize: "smaller", width: '3vw'}}>
                    <p>{ctype[0]}</p>
                    <p>{ctype[1]}</p>
                    <p>{kb[0].length < 4 ? filesize : mb}</p>
                </section>}


                <section id='title' className="folder-document-cell folder-document-folder" onClick={handleClick}>
                    { document.fileName || document.title }{/* "id: " {document.id}*/}
                </section>
                <section className="folder-document-cell folder-document-title">
                    {readablePath}
                </section>
                <section className="folder-document-cell folder-document-action">
                    <button id="download" className="folder-document-download-btn download" onClick={handleDownload}>
                        Ladda ner
                    </button>
                    <PopupEditDocuments
                      // id="edit"
                      document={document}
                      currentPath={readablePath}
                      handleSelectChange={handleSelectChange}
                      handleSubmit={handleSubmit}
                      allUnits={allUnits}
                      dirs={dirs}
                      UnitName={UnitName}
                    />
                    <button id="delete" className="folder-document-download-btn delete" onClick={handleClick}>
                        Radera
                    </button>
                </section>
            </section>
            {/*eslint-disable-next-line*/}
            <a id={dwnLink} className="hidden"></a>
        </section>
    );
};

export default DocumentPageTemplate;