import React, { useEffect, useState } from "react";
import Template from "../pages/template/Template";
import ProfileForm from "../components/Forms/ProfileForm";
import "../assets/styles/Pages/ProfilePage.scss";
import { useSelector } from "react-redux";
import UserIcon from "../assets/images/icons/user-icon.png";
import { getFileUrl } from "../store/actions/handleStorage";
import { env } from "../env";

function ProfilePage() {
  const [img, setImg] = useState(UserIcon)
  const user = useSelector((state) => state.account.theUser);
  const handleFormSubmit = (formData) => {
    console.log("Formulärdata: ", formData);
  };

  useEffect(() => {
    let ignore = false
    if (user?.imagePath && user.imagePath !== '') getFileUrl(user.imagePath).then(url => {
      setImg(url)
    })
    return () => { ignore = true }
  }, [user]);

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  return user === null ? <></> : (
    <Template>
      <section className="edit-profile-header-section">
        <img
          src={img}
          alt="profile"
          className="edit-profile-pic"
          id="edit-account-img"
        />
        <div className="edit-profile-text">
          {user ? (
            <>
              <h1>{user.firstName} {user.lastName}</h1>
              <p>{user.email}</p>
            </>
          ) : (
              <>
                <h1>Profil</h1>
                <p>Här är din profil information...</p>
              </>
            )}
        </div>
      </section>
      <ProfileForm onSubmit={handleFormSubmit} user={user} />

      <div className="profile-page">
        <section className={env.devmode ? "special-section" : 'hidden'}>
          <h2>Specialsektion</h2>
          <p>
            Denna sektion har unika stilar definierade i ProfilePage.scss.
            Noterbart att vi ännu inte har implementerat alla värden för
            profilpage.
                </p>
        </section>
        <div
          style={{ display: "grid", justifyContent: "center", marginTop: "10vh" }}
          className={img ? '' : 'hidden'}
        //todo inline css
        >

        </div>


      </div>
    </Template>
  );
}

export default ProfilePage;
