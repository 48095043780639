import { createSlice } from "@reduxjs/toolkit";
import { getAuth, signOut } from "firebase/auth";

export const userSlice = createSlice({
  name: "user",
  initialState: { isLoggedIn: false },
  reducers: {
    setUser: (state, action) => {
      //DONEtodo A non-serializable value was detected in the state, in the path: `user.isLoggedIn`. Value: Promise { <state>: "pending" }
      // signIn(action.payload)
      //   .then(loginState => { state.isLoggedIn = loginState })
      //   .catch(e => { console.error(e) })
      state.isLoggedIn = true;
    },

    logoutUser: (state, action) => {
      signOut(getAuth())
        .then(() => {console.log("logout success")})
        .catch((error) => {
          // console.error(error.message);
        });

      if (process.env.REACT_APP_RESET_LOGIN_FORM) {
        state.email = "";
        state.password = "";
      }
    },
  },
});

export const { setUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
