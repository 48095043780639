import { } from "firebase/auth";

import { createDoc, updateDoc } from "./setDocs";

export function updateUnit(uid, formData) {
  return updateDoc("units", uid, formData)
    .then((result) => {
      return true;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}
