import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Template from "../pages/template/Template";
import { fetchDocs, fetchDocsDataSerializeable, getUnitName } from "../store/actions/fetchDocs";
import { updateUser } from "../store/reducers/accountReducer";
import { Timestamp } from "firebase/firestore";
import { toUploadDefault, getFileRef, uploadFile, storageDirectories, getFileUrl } from "../store/actions/handleStorage";
import { elevatedRoles, getRoleAsString } from "../store/firebase/roleEnums";
import { BackButton } from "../components/Button";
import { env } from "../env";
import { ValidateAccountForm } from "../components/Validate/FormValidation";
import { viewErrorPopup } from "../components/Popups/FormErrorPopup";
import "../assets/styles/Pages/EditAccountPage.scss"
import UserIcon from '../assets/images/icons/user-icon.png'

const EditAccountPage = () => {
  const { uid } = useParams();
  // const docRef = getDoc()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.account.selectedUser);
  const storageDirectory = storageDirectories.profilepics
  const theUser = useSelector((state) => state.account.theUser);
  const [units, setUnits] = useState([]);

  const [formData, setFormData] = useState(
    selectedUser || {
      title: "",
      firstName: "",
      lastName: "",
      phoneNr: "",
      email: "",
      role: "",
      unitId: "",
      note: "",
      imagePath: "",
      updateTime: null,
    }
  );
  const originalImagePath = selectedUser.imagePath || ''
  const [toUpload, setToUpload] = useState(toUploadDefault.image)
  const [imageRef, setImageRef] = useState(toUploadDefault.image)
  const [img, setImg] = useState('')
  const [orgImg, setOrgImg] = useState(UserIcon)

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setFormData(selectedUser);
      if (orgImg !== selectedUser.imagePath && selectedUser.imagePath) getFileUrl(selectedUser.imagePath).then(url => setOrgImg(url))
      fetchDocsDataSerializeable('units').then(units => setUnits(units)).catch(err => console.error(err));
    }
  }, [selectedUser, orgImg]);

  useEffect(() => {
    if (env.verbose) console.debug(0, formData
    )
    if (formData.imagePath && formData.imagePath !== "") {

      setImageRef(getFileRef(formData.imagePath))

      getFileUrl(formData.imagePath)
        .then(url => { setImg(url) })
    }
  }, [formData.imagePath, formData])

  const handleChange = (e) => {
    const { name, id, value, files } = e.target;
    switch (name) {
      case 'image':
        const img = files[0]
        if (!img) return setToUpload(toUploadDefault.image)
        setToUpload(img)
        console.debug(111, img)
        setFormData(prevState => ({ ...prevState, imagePath: storageDirectory + uid + "/" + img.name }))
        console.debug('imgname:', img.name)
        break
      default:
        setFormData({
          ...formData,
          [name]: value,
        });
    }
  };

  function resetToUpload(type, e) {
    console.debug('no', type)
    setToUpload(toUploadDefault.image)
    setFormData(prevState => ({ ...prevState, imagePath: originalImagePath }))
    // document.getElementById(type).setAttribute('value', '')
  }
  function uploadFiles() {
    if (toUpload) if (toUpload.name !== toUploadDefault.image.name)
      uploadFile(storageDirectory, toUpload, uid + "/" + toUpload.name)
        .then(path => {
          resetToUpload()
          updateUser({uid, formData: {imagePath: path}})
          console.debug("file upload success:", path)
        })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { hasErrors, errorString } = ValidateAccountForm(formData, formData.password?.length > 0)
    if (formData === selectedUser)  {
      console.warn('Inget förändrat i kontot')
      return;
    }
    /* Kör eller neka... */
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {
      const { creationTime, ...updatedFormData } = formData;
      updatedFormData.updateTime = Timestamp.now().toMillis();
      // updatedFormData.updateTime = Timestamp.now().toMillis();
      uploadFiles()

      getUnitName(formData.unitId).then((unit) => {
        updatedFormData.unit = unit
        dispatch(updateUser({ uid, formData: updatedFormData }))
          .unwrap()
          .then(() => {
            console.log('User:', selectedUser.email, 'updated successfully')
            navigate(-1);
          })
          .catch((error) => {
            console.error("OhNo:", error);
          });
      })
    }

  };

  return (
    <Template>
      <BackButton />
      <section className="edit-account-header-section">

        <img
          src={orgImg}
          alt="profile"
          className={orgImg ? "edit-account-pic" : 'hidden'}
          id={"edit-account-img"}
          style={{ maxWidth: "40vw" }}
        //todo inline css
        />

        <div className="account-profile-text">
          {formData ? (
            <>
              <h1>{formData.firstName} {formData.lastName}</h1>
              <p>{formData.email}</p>
            </>
          ) : (
              <>
                <h1>Profil</h1>
                <p>Här är din profil information...</p>
              </>
            )}
        </div>
      </section>

      <div>
        <form className="flex-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="title"
            className="flex100"
            value={formData.title}
            onChange={handleChange}
            placeholder="Titel"
          />
          <input
            type="text"
            name="firstName"
            className="flex50"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Förnamn"
          />
          <input
            type="text"
            name="lastName"
            className="flex50"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Efternamn"
          />
          <input
            type="email"
            name="email"
            className="flex50"
            value={formData.email}
            onChange={handleChange}
            placeholder="E-post"
          />
          <input
            type="text"
            name="phoneNr"
            className="flex50"
            value={formData.phoneNr}
            onChange={handleChange}
            placeholder="Telefonnummer"
          />
          <select
            name="role"
            className="flex50"
            value={formData.role}
            onChange={handleChange}
          >
            <option value="">Välj administratörsroll</option>
            {theUser.role === 'superadmin' && (
              <>
                <option value="superadmin">Superadmin</option>
                <option value="admin">Administratör</option>
                <option value="manager">Föreståndare</option>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'dev' && (
              <>
                <option value="superadmin">Superadmin</option>
                <option value="admin">Administratör</option>
                <option value="manager">Föreståndare</option>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'admin' && (
              <>
                <option value="admin">Administratör</option>
                <option value="manager">Föreståndare</option>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'manager' && (
              <>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'user' && (
              <>
                <option value="user">Medarbetare</option>
              </>
            )}
          </select>
          <select
            name="unitId"
            className="flex50"
            value={formData.unitId}
            onChange={handleChange}
          >
            <option value="">Välj enhetsåtkomst</option>
            {elevatedRoles.includes(formData.role) ? (<option value='Alla'>Alla</option>) : (<></>)}
            {units.map((unit) => {

              if ((theUser.role === 'manager' || theUser.role === 'user') && theUser.unitId === unit.uid) {
                return (
                  <option key={unit.uid} value={unit.uid}>
                    {unit.name}
                  </option>
                );
              }
              else if (theUser.role !== 'manager' && theUser.role !== 'user') {
                return (
                  <option key={unit.uid} value={unit.uid}>
                    {unit.name}
                  </option>
                );
              }
              return null;
            })}
          </select>
          <input
            type="password"
            name="password"
            className="flex50"
            value={formData.password}
            onChange={handleChange}
            placeholder="Lösenord"
            autoComplete={'new-password'}
          />

          <input
            type="password"
            name="passwordrepeat"
            className="flex50"
            value={formData.passwordrepeat}
            onChange={handleChange}
            placeholder="Repetera lösenord"
            autoComplete={'new-password'}
          />

          <input hidden
            type="file"
            name="image"
            id='image'
            onChange={handleChange}
          /><label htmlFor="image" className="flex30 formBtn upload-button">{imageRef.name}</label>

          <button className=" flex30 formBtn" type="submit">
            Skicka
          </button>
        </form>
        <div
          style={{ display: "grid", justifyContent: "center", marginTop: "10vh" }}
          className={img ? '' : 'hidden'}
        //todo inline css
        >

        </div>

      </div>
    </Template>
  );
};

export default EditAccountPage;
