import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import HeroImage from "../components/Building/BuildingHeroImage";
import companyLogotype from "../assets/images/logotyp/company-logotype.png";
import {
  docPlaceholder,
  fetchDoc,
  fetchDocsData,
  fetchDocs, fetchDocDataSerializeable, fetchDocsDataSerializeable, getUnitByName
} from "../store/actions/fetchDocs";
import Template from "../../src/pages/template/Template";
import "../assets/styles/Pages/Buildings/BuildingPage.scss";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import BuildingsMenu from "../components/menus/BuildingsMenu";
import BuildingNewsListing from "../components/Listing/BuildingNewsListing";
import BuildingFolderHeadlineListing from "../components/Building/Listing/BuildingFolderHeadlineListing";
import BuildingDocumentHeadlineListing from "../components/Building/Listing/BuildingDocumentHeadlineListing";
import { env, foo } from "../env";
import { getFileUrl } from "../store/actions/handleStorage";
import { connectStorageEmulator } from "firebase/storage";
import { elevatedRoles } from "../store/firebase/roleEnums";

let ignoreConsole = false;
function BuildingPage() {
  const theLimit = 5
  const { id } = useParams()
  const [userUnitid, setId] = useState()
  const [buildingDoc, setBuildingDoc] = useState();
  const [allBuildings, setAllBuildings] = useState([]);
  const [img, setImg] = useState(companyLogotype);
  const [building, setBuilding] = useState(docPlaceholder)
  const [role, setRole] = useState("user")
  /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
  const user = useSelector((state) => state.account.theUser);

  useEffect(() => { setRole(user?.role) }, [user]);

  useEffect(() => {
    let ignore = false
    if (ignore) return;
    else if (id || userUnitid) {
      fetchDocsDataSerializeable("units")
        .then((result) => {
          setAllBuildings(result);
          if (env.verbose) console.debug(foo.kd, "Setting AllBuildings:", result);
        })
        .catch((error) => {
          console.error("OhNo", error)
        });
      return () => {
        ignore = true
      };
    }
  }, [id, userUnitid]);

  useEffect(() => {
    let ignore = false
    if (ignore) return;
    else if ((!id || id === 'start') && user) {
      setId(user.unitId)
      console.debug(0, foo.kd, user.unitId)
      // window.location.set('/enhet/' + user.unitId)
      return () => { ignore = false }
    }
    else if (id && id !== 'start') {
      setId(id)
      console.debug(1, foo.kd, id)
      return () => { ignore = false }
    }
  }, [user, id]);

  useEffect(() => {
    if (allBuildings.length === 0) {
      console.debug('allunits:', allBuildings.length)
      return;
    }
    if (id === 'start' || id=== '1' || userUnitid === 'Alla') {
      setBuilding(allBuildings[0])
      !env.devmode?{}:console.debug('id pre:', id, '\nuserunit', userUnitid, '\nsel unit:', allBuildings[0].name)
    }
    else if (id.includes('EQ')) {
      const chosenBuilding = allBuildings.find((b) => b.name === userUnitid)
      setBuilding(chosenBuilding)
      !env.devmode?{}:console.debug('id pre:', id, '\nuserunit', userUnitid, '\nsel unit:', chosenBuilding?.name)
    }
    else {
      const chosenBuilding = allBuildings.find((b) => b.id === userUnitid)
      setBuilding(chosenBuilding)
      !env.devmode?{}:console.debug('id pre:', id, '\nuserunit', userUnitid, '\nsel unit:', chosenBuilding?.name)
    }

    return () => { };
  }, [allBuildings]);

  useEffect(() => {
    if (!user || !building || Object.keys(building).length === 0) return;
    if (!user.role) return ;
    const invalid = [null, undefined, ""];
    if (invalid.includes(building.imagePath)) setImg(companyLogotype);
    else getFileUrl(building.imagePath, user.role).then((url) => setImg(url));
    return () => { };
  }, [building, user]);

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  const buildingsToShow = elevatedRoles.includes(role) ? allBuildings : [];

  return !building ? (<></>) : (
    <section>
      <HeroImage
        imagePath={building.imagePath}
        title={building.name}
        description={building.description}
        units={allBuildings}
        id={id}
      />
      <section className="building-page-menucontainer">
        <BuildingsMenu id={id} buildings={buildingsToShow} currentBuilding={building} />
      </section>
      <section className="building-page-layout">
        <section className="building-content-left">
          <h1>Nyheter</h1>
          {/* {console.log("news10", newsArray)} */}
          <BuildingNewsListing id={building.uid} role={role} />
        </section>
        <section className="building-content-right">
          <h3>Senaste dokument
            {env.devmode ? <section style={{ fontSize: "small" }}>(limit: {theLimit})</section> : <></>}
          </h3>
          <section className="building-content-right-latest-document">
            <BuildingDocumentHeadlineListing id={building.uid} role={role} limit={theLimit} />
          </section>
          <section className="building-content-right-latest-news">
            <h3>Mappar</h3>
            <Link to={`/enhet/${building.name}/mappar`} className="view-all-folders">
              Visa alla
            </Link>
          </section>
          <BuildingFolderHeadlineListing id={building.uid} buildingName={building.name} />
        </section>
      </section>
      <Template></Template>
    </section>
  );
}

export default BuildingPage;
