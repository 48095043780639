import React, { useEffect, useState } from "react";
import DocumentIcon from "../../../assets/images/icons/document-icon.png";
import BuildingDocumentTemplate from "../template/Documents/BuildingDocumentTemplate";
import {getContentInDirAndSubdirs, humanreadablePath} from "../../../store/actions/fetchDocs";
import {env} from "../../../env";
import {getFileUrl} from "../../../store/actions/handleStorage";
import {useSelector} from "react-redux";

const BuildingLevel1DocumentListing = ({ id, docPath, directory, buildingName, buildingId }) => {
  const user = useSelector(state => state.account.theUser);
  const userRole = user.role

  const [docs, setDocs] = useState()
  useEffect(() => {
    if (docPath === '' || docPath === directory) return;
    if (env.verbose) console.debug('foo docpath', docPath)
    getContentInDirAndSubdirs(id, userRole, 50).then(docs => {
      console.log(docs.length, docs.length === 1 ? ("Document") : ("Documents"))
      setDocs(docs)
    })
    return () => { }
  }, [docPath, directory, userRole])

  if (!docs) return env.devmode ? <p>Debug: Inga dokument laddade</p> : <></>
  if (docs < 1) return env.devmode ? <p>Debug: Dokument att visa: 0</p> : <></>
  console.log(docs ? 'docs null' : docs.length, docs.length === 1 ? ("Document") : ("Documents"))

  return (
    <div className="hejsan">
      <h1>Dokument,
      {env.devmode ? ' (debug: att visa: ' + docs.length + ")" : <></>}
      </h1>
      {docs.map((item) => (
        <BuildingDocumentTemplate
          key={item.BuildingDocumentHeadline || item.id}
          icon={item.BuildingDocumentPicture || DocumentIcon}
          title={item.title || item.fileName || item.id}
          inFolder={humanreadablePath(item.path, buildingId, buildingName).replace(item.id, '') || 'error'}
          DocumentLink={item.filePath /*|| item.DocumentLink */}
        />
      ))}
    </div>
  );
};

export default BuildingLevel1DocumentListing;
