import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteDocFully,
  createDocUniqID,
} from "../actions/setDocs";
import { updateNews, deleteNews } from "../actions/newsAction";

export const createNewsArticle = createAsyncThunk(
  "news/createNewsArticle",
  async (formData, { rejectWithValue }) => {
    try {
      const uid = await createDocUniqID("news", formData);
      console.debug('imagePath:', formData.imagePath, 'filePath:', formData.filePath, 'uid', uid)
      // updateNews(uid, {})
      return { ...formData, uid };
    } catch (error) {
      return rejectWithValue("Gick inte att skapa nyhet");
    }
  }
);

export const updateSpecificNews = createAsyncThunk(
  "news/updateNews",
  async ({ uid, formData }, { rejectWithValue }) => {
    try {
      const result = await updateNews(uid, formData);
      if (result === true) {
        const { creationTime, ...serializableFormData } = formData;
        return { uid, ...serializableFormData };
      } else {
        return rejectWithValue("Gick inte att uppdatera nyhet");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSpecificNews = createAsyncThunk(
  "news/deleteNews",
  async ({ uid, formData }, { rejectWithValue }) => {
    return deleteNews(uid)
      .then(result => {
        // const { ...serializableFormData } = formData
        return { uid}
      })
      .catch(error => (rejectWithValue("Gick inte att radera nyhet: " + error.message)))
  }
);

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    newsToEdit: null,
    status: "idle",
    error: null,
  },
  reducers: {
    setNewsToEdit: (state, action) => {
      const {
        creationTime,
        updateTime,
        ...payloadWithoutTimestamps
      } = action.payload;
      state.newsToEdit = payloadWithoutTimestamps;
    },
    disableNewsArticle: (state, action) => {
      const { uid } = action.payload;
      deleteDocFully("news", uid)
        .then((id) => { })
        .catch((e) => console.error(e));
      return {
        ...state,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewsArticle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createNewsArticle.fulfilled, (state, action) => {
        state.status = "succeeded";
        // You can handle the new article here if needed
      })
      .addCase(createNewsArticle.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateSpecificNews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSpecificNews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.newsToEdit = action.payload;
      })
      .addCase(updateSpecificNews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteSpecificNews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSpecificNews.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Handle state update after delete if necessary
      })
      .addCase(deleteSpecificNews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { disableNewsArticle, setNewsToEdit } = newsSlice.actions;
export default newsSlice.reducer;
