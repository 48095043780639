import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/styles/Listing/template/NewsTemplate.scss";
import { deleteSpecificNews, setNewsToEdit } from "../../../store/reducers/newsReducer";
import NewsIcon from "../../../assets/images/icons/news-icon.png";
import {useDispatch, useSelector} from "react-redux";
import { disableNewsArticle } from "../../../store/reducers/newsReducer";
import { getStorage, ref } from "firebase/storage";
import {getFileRef, getFileUrl, prepDownload, startDownload} from "../../../store/actions/handleStorage";
import { fetchDoc, firestoreTimestampToString } from "../../../store/actions/fetchDocs";
import { getRoleAsString } from "../../../store/firebase/roleEnums";
import { env, ReloadPage } from "../../../env";
import useUser from "../../../hooks/user/useUser.ts";
import PopupConfirmDeletion from "../../Popups/PopupConfirmDeletion";

let ignore = false
function NewsTemplate({ news, theUser }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [img, setImg] = useState(NewsIcon);
  const [author, setAuthor] = useState();
  const [authorDisplayName, setAuthorDisplayName] = useState('Användare borttagen<')
  // if (env.verbose) console.debug('news', news)
  const [url, setUrl] = useState(null);
  const [fileRef, setFileRef] = useState(null);
  const [deleted, setDeleted] = useState(false)
  const user = useSelector((state) => state.account.user);

  function handleClick(e) {
    console.debug(e.target, news.id);
    const uid = news.uid;
    switch (e.target.className) {
      case "changeBtn":
        // dispatch()

        const { creationTime, updateTime, ...unitWithoutTimestamps } = news;
        dispatch(setNewsToEdit(unitWithoutTimestamps));
        window.scrollTo(0, 0)
        // dispatch(setNewsToEdit(newsRef.id))
        setTimeout(() => {
          // Vänta en liten stund för att säkerställa att state uppdateras
          navigate(`/editera-nyhet/${news.id}`);
        }, 100);
        break;
      case "news-pic":
        // getFileUrl(news.imagePath).then((url) => {
        //   navigate(url);
        //   window.location.replace(url);
        // });
        break;
      default: break
    }
  }

  function handleDelete() {
    const uid = news.id;
    // console.log('news killed')
    dispatch(deleteSpecificNews({ uid }))
    .unwrap()
    .then(() => {
      // reloadPage()
      setDeleted(true)
    })
    .catch((error) => {
      console.error("OhNo:", error);
    });
  }
  function handleDownload() {
    if (env.devmode) console.log(/*id, */'fileName:', '=> url:\n' + url)
    const link = window.document.getElementById(url)
    prepDownload(fileRef, link)
    .then(r => {
      link.setAttribute('download', fileRef.name)
      // setPrepped(true)
      // setPrepping(false)
      console.debug('Download prepped', r)
      window.document.body.appendChild(link);
      startDownload(fileRef, link)
      .then(() => {
        link.parentNode.removeChild(link)
        // setPrepped(false)
        console.log('Download finished')
      })
      .catch(e => {
        console.error(e)
      })
    })
    .catch(e => console.error(e));
  }

  useEffect(() => {
    let ignore = false
    if (user) fetchDoc('users/', news.author, true)
    .catch(e => {console.warn(e)})
    .then(authorDoc => {
      setAuthor(authorDoc)
      if (env.verybose) console.debug(1337, news.title, authorDoc.firstName, authorDoc.lastName)
    })
    return () => ignore = true
  }, [user, news.author])

  //to do cache
  useEffect(() => {
    if (!author) return;
    if (!author.imagePath) return;
    if (env.verybose) console.debug(author.imagePath)
  }, [author])

  useEffect(() => {
    let ignore = false
    if (!url && news.filePath) {
      setFileRef(getFileRef(news.filePath))
      getFileUrl(news.filePath).then((res) => {
        setUrl(res)
      })
      .catch(e => {})
    }

    return () => ignore = true
  }, [news.filePath])

  const isDecisionMakers = ["superadmin", "admin", "dev"].includes(theUser.role);
  const isManager = theUser.role === "manager";
  const isOwnUnitNews = theUser.unitId === news.unitId;

  // console.log("news", news)
  return deleted ? <></> : (
    <section>
      {(isDecisionMakers || (isManager && isOwnUnitNews)) &&
        <section>
          <div className="news-item" key={news.id}>
            <div className="news-info">
              <img
                about="Author profile picture"
                src={NewsIcon || img}
                alt={authorDisplayName}
                className="news-icon"
                id={"newsimg-" + news.uid}
                onClick={handleClick}
              />
              <div className="news-details">
                <div className="news-roleArray">{getRoleAsString(news.role)}</div>
                <div className="news-newsHeadline">{news.title}</div>
                <div className="news-newsUnitArray">
                <span className="news-dateandplace-building">
                  {firestoreTimestampToString(news.creationTime)} - {news.unitName}
                </span>
                </div>
              </div>
            </div>

            {!env.devmode ? <></> : <section className="debug-checkmarks" style={{fontSize: "small"}}>debug:
              <p>img{img ? ' ✅' || '= tru' : '= false'}</p>
            <section>{news.filePath ? <></> : 'no file'} </section></section>}

            <div className="news-actions">
              {url ? <button className="downloadBtn" onClick={handleDownload}>Ladda ner</button> : <button className="downloadBtn opacity-0" />}
              <button className="changeBtn" onClick={handleClick}>Ändra</button>
              {/*<button className="deleteBtn" onClick={handleClick}>Radera</button>*/}
              <PopupConfirmDeletion handleDelete={handleDelete} word={'nyheten'} />
            </div>

          </div>
        </section>
      }


      {!env.devmode ? <></> : <section className="debug-text" style={{position: 'absolute', marginTop: '-35px', zIndex: '3'}} >{
        url ?
          <section>filepath={news.filePath.replace('news/', '')
          .replace(news.unitId, news.unitName || news.unit) //unitid => name
          .replace(news.id, news.title + "/ ") //newsid => title
          .replace(news.fileName, ' ')
          // .split("/").map((segment, i) => '/'+ segment +' ')
          } </section>
          : <></>
      } </section>}


      {/*eslint-disable-next-line*/}
      <a id={url} className="hidden"></a>
    </section>
  );
}

export default NewsTemplate;
