import React, {useEffect, useState} from "react";
import Template from "../pages/template/Template";
import DocumentForm from "../components/Forms/DocumentForm";
import "../assets/styles/Pages/DocumentPage.scss";
import {consolecheck, env, foo} from "../env";
import { getContentInDir } from "../store/actions/fetchDocs";
import { getAuth } from "firebase/auth";
import { BackButton } from "../components/Button"

function DocumentPage() {
    const handleFormSubmit = (formData) => {
        console.log("Formulärdata: ", formData);
    };
  const [consoleChecked, setConsoleChecked] = useState(false)
  if (!consoleChecked) setConsoleChecked(consolecheck('documentpage'))

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  return (
        <Template>
            <BackButton />
            <h1>Hantera dokument</h1>
            <p>Här är dina dokument här...</p>
            <DocumentForm onSubmit={handleFormSubmit} />

            <div className="profile-page">
                <section className={env.devmode ? "special-section" : 'hidden'}>
                    <h2>Att göra</h2>
                    <p>
                        Denna sektion har unika stilar definierade i DocumentPage.scss.
                        Noterbart att vi ännu inte har implementerat alla värden för nyheter
                        sidan. Ladda upp filer. (ska va alla format) saknas fortfarande.
                        Mappar ska generas dynamiskt
                  </p>
                </section>

            </div>
        </Template>
    )
        ;
}

export default DocumentPage;
