import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import Template from "../pages/template/Template";
import {
    convertTimestampsFromString,
    fetchAllFolderDirectoryDocs,
    fetchDocByFullpath,
    getDocRef,
    humanreadablePath
} from "../store/actions/fetchDocs";
import {env, foo} from "../env"
import {BackButton} from "../components/Button";
import { useLocation } from 'react-router-dom';
import documentIcon from "../assets/images/icons/document-icon.png";
import Swal from 'sweetalert2';
import PopupEditDocuments from "../components/Popups/PopupDocBeforeDeleteFolder"
import {createDoc, disableDoc} from "../store/actions/setDocs";
import PopupConfirmDeletion from "../components/Popups/PopupConfirmDeletion";
import {getFileRef, prepDownload, startDownload} from "../store/actions/handleStorage";
import {deleteDoc} from "firebase/firestore";


const RemoveFolderPage = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const { folder, documents, UnitName, allUnits } = location.state;
    const [docIcon, setDocIcon] = useState(documentIcon);
    const [dirs, setDirs] = useState([])
    const [deleted, setDeleted] = useState([])
    const [delCount, setDelCount] = useState(0)

    /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
    const theUser = useSelector((state) => state.account.theUser);

    useEffect(() => {

        fetchAllFolderDirectoryDocs(undefined, theUser?.role)
            .then(res => {
                setDirs(res || []);
            })
            .catch(e => console.error(e));
    }, [theUser?.role]);

    const handleClick = async (e) => {
        const { id } = e.target;

        switch (id) {
            case 'delete':
                try {

                } catch (error) {
                    console.error("OhNo: ", error);
                }
                break;
            default:
                break;
        }
    };

    const openPopup = (doc) => {

        Swal.fire({
            html: `
            <section class="popupRemoveFolder-select-column-container">
            <h1>${UnitName}</h1>   
            <h2>Är du säker på att du vill radera dokumentet ${doc.fileName}?</h2>
               
            </section>
            <button id="delete-btn" class="popupRemoveFolder-submit-btn-delete">Radera</button>
        `,
            showConfirmButton: false,
            didOpen: (popup) => {

                const deleteButton = popup.querySelector('#delete-btn');
                deleteButton.addEventListener('click', () => {

                    // TO DO:: Radera endast detta dokument och skit i konsekvenserna...
                    !env.devmode?{}:console.log("KEVIN: Radera endast begrava detta dokument...", doc.fullpath)
                    disableDoc(folder.fullpath).catch(e => {
                        env.devmode ? console.error(e) : console.error("Det gick inte att ta bort dokumentet")
                    })
                    .then(() => {
                        console.log('Dokument borttaget')
                        Swal.close()
                    })
                });
            }
        });
    };

    const handleSelectChange = (selectName, value) => {
        //console.log(`Changed ${selectName} to ${value}`);
    };

    function handleDelete(doc) {
        const docRef = getDocRef(doc.path)
        deleteDoc(docRef).then(()=>{
            const newArr = deleted
            newArr.push(doc.path)
            setDeleted(newArr)
            setDelCount(delCount + 1)
        })
    }

    function handleDownload(doc) {
        // if (env.devmode) console.log(/*id, */'fileName:', '=> url:\n' + url)
        const fileRef = getFileRef(doc.filePath)
        const link = window.document.getElementById(doc.path)
        prepDownload(fileRef, link)
        .then(r => {
            link.setAttribute('download', fileRef.name)
            // setPrepped(true)
            // setPrepping(false)
            console.debug('Download prepped', r)
            window.document.body.appendChild(link);
            startDownload(fileRef, link)
            .then(() => {
                link.parentNode.removeChild(link)
                // setPrepped(false)
                console.log('Download finished')
            })
            .catch(e => {
                console.error(e)
            })
        })
        .catch(e => console.error(e));
    }
    const handleSubmit = (selectedUnit, selectedDir, doc) => {
        !env.devmode?{}:console.log('10', foo.kd + 'selectedUnit', selectedUnit, '\nselectedDir', humanreadablePath(selectedDir), '\ncurrentPath', humanreadablePath(document.path) );
        const docRef = getDocRef(doc.path)
        const parent = docRef.parent.parent
        console.debug('02', 'parent', parent.path)
        const newPath = selectedDir ? selectedDir + "/content/"  : 'folders/' + selectedUnit + "/content/"


        const data = {formData: {
            ...convertTimestampsFromString(doc),
            unit: selectedUnit,
            directory:false,
        }}
        console.log('01',  newPath, data)
        createDoc(newPath, data, document.id).then(res => {
            deleteDoc(docRef).then(()=>{
                const newArr = deleted
                newArr.push(doc.path)
                setDeleted(newArr)
                setDelCount(delCount + 1)
                })
            .catch(e => { console.error(e) })
        }).catch(e => { console.error(e) })

    }
    const filteredDocs = documents?.filter(d => (!deleted.includes(d.path))) || []

    return (
        <Template>
            <BackButton />
            <h1>{UnitName}</h1><h1>Flytta dokument ur "{folder.name}" och dess undermappar  innan du raderar mappen </h1>
            {!env.devmode? <></> : <section>
                debug, borttaget: {deleted.map(d => (d + ", "))}
            </section> }
            {filteredDocs.length === 0 ? <h2>Inget mer att granska!</h2> :
              filteredDocs.map(doc => (
                <section key={doc.path}>
                    <section className="folder-document-row-container">
                        {/* Tillagd container klass */}
                        <section className="folder-document-row">

                            <section className="folder-document-cell folder-document-icon">
                                <img src={docIcon} alt={`${document.id} icon`}
                                    id='icon' onClick={handleClick} />
                            </section>
                            <section id='title' className="folder-document-cell folder-document-folder" onClick={handleClick}>
                                {/*document.fileName || document.title}{/* "id: " {document.id}*/}
                                {doc.fileName}
                                {/*humanreadablePath(doc.path, doc.unit, '')}{/*sf.path*/}

                            </section>
                            <section className="folder-document-cell folder-document-title">

                            </section>
                            <section className="folder-document-cell folder-document-action">
                                <PopupConfirmDeletion
                                  word={'dokumentet'}
                                  handleDelete={() => handleDelete(doc)}
                                />

                                <button onClick={() => handleDownload(doc)} className="download-btn download">
                                    Ladda ner
                                </button>
                                <PopupEditDocuments
                                    // id="edit"
                                    document={doc}
                                    handleSelectChange={handleSelectChange}
                                    handleSubmit={handleSubmit}
                                    allUnits={allUnits}
                                    UnitName={UnitName}
                                    dirs={dirs}
                                    folder={folder}
                                />

                            </section>
                        </section>
                        {/*eslint-disable-next-line*/}
                        <a id={doc.path} className="hidden"></a>
                    </section>
                </section>
            ))}

        </Template>
    );
};

export default RemoveFolderPage;
