import Swal from "sweetalert2";

export function viewErrorPopup(title, errorMessages) {
  Swal.fire({
    title: title,
    icon: "error",
    html: `${errorMessages}`,
    background: "#ffff",
    color: "black",
    confirmButtonColor: "#00B2A1",
    iconHtml: "🚫",
    showCloseButton: true,
  });
}
