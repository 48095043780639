import React from "react";

import BuildingIcon from "../../../assets/images/icons/folder-icon.png";
import BuildingLevel3FolderTemplate from "../template/Folders/BuildingLevel3FolderTemplate";
import env from "../../../env";
import {useSelector} from "react-redux";



const BuildingLevel3FolderListing = ({ building, folders, path }) => {
  if (!folders) return env.devmode ? <>Debug: Inga mappar att visa (!folders)</> : <></>
  if (folders.length === 0) return env.devmode ? <>Debug: Inga mappar att visa (folders = 0)</> : <></>

  const pathSegments = path.replace('content/', '').replace('content/', '').split("/")
  return (
    <div className="folder-list">
      {/* <div>pathsegments: {pathSegments.map((segment, i) => (<p>{i + ": " + segment + "  "}</p>))}</div> */}
      {folders.map((item) => (
        <BuildingLevel3FolderTemplate
          key={item.id}
          icon={BuildingIcon}
          title={item.id}
          nextfolder={item.id}
        />
      ))}
    </div>
  );
};

export default BuildingLevel3FolderListing;
