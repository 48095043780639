import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { createDoc, updateDoc, deleteDocFully } from "./setDocs";

export function updateNews(uid, formData) {
  return updateDoc("news", uid, formData)
    .then((result) => {
      return true;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}
export function deleteNews(uid) {
  return deleteDocFully("news", uid)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}
