import React, {useEffect, useState} from "react";
import "../../../assets/styles/Listing/template/BuildingDocumentHeadlineTemplate.scss";
import {env, foo} from "../../../env";
import {getMetadata} from "firebase/storage";
import {getFileRef, prepDownload, startDownload} from "../../../store/actions/handleStorage";
import {getIcon} from "../../../assets/images/icons/Icons";

const BuildingDocumentHeadlineTemplate = ({
  icon,
  title,
  inFolder,
  DocumentLink,
  path,
  filepath
}) => {
  const [theIcon, setIcon] = useState(icon)
  const isIconEmoji = typeof icon === "string" && icon.length < 10;
  const [fatalError, setFatalError] = useState(false);
  const [fileRef, setFileRef] = useState(null)

  useEffect(() => {
    let ignore = false
    !env.devmode ? {} : console.debug('filepath', filepath)
    if (!ignore && filepath)
      getFileRef(filepath, true).then(fileRefRes => {
        setFileRef(fileRefRes)
        getMetadata(fileRefRes).then(md =>
          getIcon(md.contentType).then(anIcon =>
            setIcon(anIcon)
          )
        )
      })
      .catch(e => {
        console.error('Fatal error:', title, e)
        setFatalError(true)
      })
    return () => {ignore = true}
  }, [icon, filepath]);

  function handleDownload() {
    if (env.devmode) console.log(/*id, */'fileName:', '=> url:\n' + DocumentLink)
    const link = window.document.getElementById(DocumentLink)
    // if (prepped) {
    //   window.document.body.appendChild(link);
    //   startDownload(fileRef, link)
    //   .then(() => {
    //     link.parentNode.removeChild(link)
    //     setPrepped(false)
    //     console.log('Download finished')
    //   })
    //   .catch(e => {
    //     console.error(e)
    //   })
    // } else {
    //   setPrepping(true)
    prepDownload(fileRef, link)
    .then(r => {
      link.setAttribute('download', fileRef.name)
      // setPrepped(true)
      // setPrepping(false)
      console.debug('Download prepped', r)
      window.document.body.appendChild(link);
      startDownload(fileRef, link)
      .then(() => {
        link.parentNode.removeChild(link)
        // setPrepped(false)
        console.log('Download finished')
      })
      .catch(e => {
        console.error(e)
      })
    })
    .catch(e => console.error(e));
  }


  return fatalError ? <></> :
    <div className="document-list">
      <div className="document-card" onClick={handleDownload}>
        <div className="document-card-icon">
          {isIconEmoji ? icon : <img src={theIcon} alt={`${title} icon`}/>}
        </div>

        <section className="view-all-folders">
          <section className={'' /*|| "document-list-file-title"*/}>{title}</section>
          {env.devmode ? <section style={{fontSize: "smaller", color: "black"}}>debug: {inFolder}</section> : ''}
        </section>


      </div>
      {/*eslint-disable-next-line*/}
      <a id={DocumentLink} className="hidden"></a>
    </div>
};

export default BuildingDocumentHeadlineTemplate;
