import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Template from "../pages/template/Template";
import { Timestamp } from "firebase/firestore";
import {
  updateSpecificNews,
  deleteSpecificNews,
} from "../store/reducers/newsReducer";
import { checkIfFileExists, getFileUrl, storageDirectories, toUploadDefault, uploadFile } from "../store/actions/handleStorage";
import { env, foo } from "../env"
import { getRoleAsString } from "../store/firebase/roleEnums";
import { fetchDocs, fetchDocsData, getAllUnitIds } from "../store/actions/fetchDocs";
import { BackButton, handleBackTemplate } from "../components/Button";
import { ValidateNewsForm } from "../components/Validate/FormValidation";
import { viewErrorPopup } from "../components/Popups/FormErrorPopup";
import {updateNews} from "../store/actions/newsAction";

const EditNewsPage = () => {
  const storageDirectory = storageDirectories.news
  let { uid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theUser = useSelector((state) => state.account.theUser);
  let ignore = false

  const preSelectedNews = useSelector((state) => state.news.newsToEdit);
  const selectedNews = { ...preSelectedNews, uid }
  let originalImagePath = selectedNews.imagePath
  let originalFilePath = selectedNews.filePath
  let imagePathSegments, originalImageName, filePathSegments, originalFileName

  if (originalImagePath === '' || originalImagePath === undefined) {
    originalImagePath = toUploadDefault.image.name
  } else {
    console.debug(originalImagePath)
    imagePathSegments = originalImagePath.split("/")
    originalImageName = imagePathSegments[imagePathSegments.length - 1]
  }

  if (originalFilePath === '' || originalFilePath === undefined) {
    originalFilePath = toUploadDefault.file.name
  } else {
    console.debug(originalFilePath)
    filePathSegments = originalFilePath.split("/")
    originalFileName = filePathSegments[filePathSegments.length - 1]
  }

  const [formData, setFormData] = useState(
    selectedNews || {
      unit: "",
      role: "",
      description: "",
    }
  );
  const [filePath, setFilePath] = useState(originalFilePath)
  const [fileName, setFileName] = useState(originalFileName)
  const [imagePath, setImagePath] = useState(originalImagePath)
  const [imageName, setImageName] = useState(originalImageName)
  const [toUpload, setToUpload] = useState({})
  const [unitRefs, setUnitRefs] = useState()
  const [units, setUnits] = useState([])
  const [img, setImg] = useState()
  // console.debug('env.verbose', env.verbose)
  if (env.verbose) console.debug('selectedNews', selectedNews)

  useEffect(() => {
    console.debug('useeffect selectedNews', selectedNews)
    if (selectedNews !== formData && !ignore) {
      setFormData(selectedNews)
      if (selectedNews.filePath !== '') setFilePath(selectedNews.filePath)
      if (selectedNews.imagePath !== '') setImagePath(selectedNews.imagePath)
      fetchDocsData("units/")
        .then(res => {
          setUnits(res)
          return () => { ignore = true }
        })
    }
  }, [preSelectedNews]);

  useEffect(() => {
    let ignore = false
    getFileUrl(originalImagePath, theUser.role)
      .then(res => {
        console.debug('originalImagePath', originalImagePath, res)
        setImg(res)
      })
    return () => { ignore = true }
  }, [imagePath])

  useEffect(() => {
    if (unitRefs !== undefined) console.log('unitRefs', unitRefs)
     //() => { }
  }, [unitRefs])

  useEffect(() => {
    if (units !== undefined && units.length > 0) console.log('units', units)
     //() => { }
  }, [units])

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'unitId':
        const unitId = e.target.value
        const aUnit = units.filter(u => (u.id === unitId))[0]
        console.debug('aUnit', aUnit)
        const unitName = aUnit?.name || "Alla"
        setFormData({
          ...formData,
          unitId,
          unitName
        })
        break
      default:
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
    }
  };

  function resetToUpload(type, e) {
    if (!type) throw new Error('ingen typ')
    // console.debug('no', type)
    switch (type) {
      case 'file':
        setToUpload(prevState => ({ ...prevState, file: toUploadDefault.file }))
        setFormData(prevState => ({ ...prevState, filePath: '' }))
        break
      case 'image':
        setToUpload(prevState => ({ ...prevState, image: toUploadDefault.image }))
        setFormData(prevState => ({ ...prevState, imagePath: '' }))
        break
      default:
        resetToUpload('file')
        resetToUpload('image')
    }
    const el = document.getElementById(type)
    try { el.setAttribute("value", ""); }
    catch { (e) => { console.error(e) } }
  }

  function handleFileChange(e) {
    const { id, files } = e.target
    const file = files[0]

    if (!file) return resetToUpload(id, e.target)

    switch (id) {
      case 'file':
        setToUpload(prevState => ({ ...prevState, file: file }))
        setFormData(prevState => ({ ...prevState, filePath: storageDirectory.filetest + file.name }))
        setFileName(file.name)
        console.debug('filename:', file.name)
        break
      case 'image':
        setToUpload(prevState => ({ ...prevState, image: file }))
        setFormData(prevState => ({ ...prevState, imagePath: storageDirectory.newspics + file.name }))
        console.debug('imgname:', file.name)
        setImageName(file.name)
        break
      default: break
    }
  }

  function uploadFiles(role, unitId, newsId) {
    if (!toUpload.file && !toUpload.image) return undefined;
    let pathToSet = storageDirectory + role + "/" + unitId + "/" + newsId + "/"

    console.debug('1338', toUpload.image)

    if (toUpload.file)
      if (toUpload.file.name !== toUploadDefault.file.name)
        checkIfFileExists(pathToSet + toUpload.file.name).then(fileExists => {
          console.debug(foo.kd, fileExists)
          if (!fileExists)
            uploadFile(pathToSet, toUpload.file, 'file-' + toUpload.file.name)
            .then((path) => {
              console.debug("file upload success:", path);
              updateNews(newsId, {filePath: path}).then(r => {console.debug('newsDoc filepath updated')})
            })

          else {
            uploadFile(pathToSet, toUpload.file, "(" + String(fileExists) + ")" + 'file-' +  toUpload.file.name)
            .then((path) => {
              console.debug("file upload success:", path);
              updateNews(newsId, {filePath: path}).then(r => {console.debug('newsDoc filepath updated')})
            })
          }
        })
    if (toUpload.image) {
      !env.devmode ? {}: console.debug(toUpload.image.name)
      !env.devmode ? {}: console.debug(toUploadDefault.image.name)
      const imagenameSegments = toUpload.image.name.split(".")
      const fileExtension = imagenameSegments[ imagenameSegments.length -1 ]
      if (toUpload.image.name !== toUploadDefault.image.name)
        checkIfFileExists(pathToSet + toUpload.image.name).then(fileExists => {
          !env.devmode ? {}: console.debug(foo.kev, fileExists)
          if (fileExists === 0 || fileExists === false) uploadFile(pathToSet, toUpload.image, 'image-' + toUpload.image.name)
          .then((path) => {
            console.debug("image upload success:", path);
            updateNews(newsId, {imagePath: path}).then(r => {console.debug('newsDoc imagepath updated')})
          })
          else {
            uploadFile(pathToSet, toUpload.image, "(" + String(fileExists) + ")" + 'image-' + toUpload.image.name)
            .then((path) => {
              console.debug("image upload success:", path);
              updateNews(newsId, {imagePath: path}).then(r => {console.debug('newsDoc imagepath updated')})
            })
          }
        });
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    const { hasErrors, errorString } = ValidateNewsForm(formData, true)

    /* Kör eller neka... */
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {
      let same = true

      if (formData.description !== selectedNews.description) same = false
      if (formData.unitId !== selectedNews.unitId) same = false
      if (formData.role !== selectedNews.role) same = false
      if (formData.imagePath !== selectedNews.imagePath) same = false
      if (formData.filePath !== selectedNews.filePath) same = false
      if (formData.title !== selectedNews.title) same = false

      console.log('same', same)
      if (same) {
        console.log('No change, going back')
        navigate(-1)
        return;
      }

      const { creationTime, ...updatedFormData } = formData;
      const { role, unitId } = updatedFormData
      let pathToSet = storageDirectory + role + "/" + unitId + "/"// + uid + "/"
      // console.log("foo bar", formData);
      let iPath, fPath
      if (toUpload.file) {
        fPath = pathToSet + toUpload.file.name
      } else {
        fPath = formData.filePath
      }
      if (toUpload.image) {
        iPath = pathToSet + toUpload.image.name
      } else {
        iPath = formData.imagePath
      }

      updatedFormData.updateTime = Timestamp.now().toMillis();
      // const uid = selectedNews.unitId
      if (env.verbose) console.debug('updatedFormData', uid, updatedFormData)

      dispatch(updateSpecificNews({ uid, formData: { ...updatedFormData, filePath: fPath || '', imagePath: iPath || '' } }))
        .unwrap()
        .then((res) => {

          if (toUpload) uploadFiles(formData.role, formData.unitId, uid);

          navigate(-1)
        })
        .catch((error) => {
          console.error("OhNo:", error)
        })
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const { creationTime, ...updatedFormData } = formData;
    updatedFormData.updateTime = Timestamp.now().toMillis();
    const uid = selectedNews.unitId
    //todo !! delete file
    dispatch(deleteSpecificNews({ uid, formData: updatedFormData }))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("OhNo:", error);
      });
  };
  // console.debug('units', units)
  return (
    <Template>
      <BackButton />
      <h1>Ändra nyhet</h1>
      <p>{formData.title} ({formData.author})</p>
      <p>{formData.unitName} ({formData.unitId}) | {getRoleAsString(formData.role)}</p>
      <div>
        <form className="flex-form" onSubmit={handleSubmit}>
          <select
            name="unitId"
            className="flex50"
            value={formData.unitId}
            // value={formData.unitId || formData.unitId}
            onChange={handleChange}
          >
            <option value="">Välj enhet</option>
            <option value="Alla">Alla</option>

            {units.map((unit) => {
              if ((theUser.role === 'manager' || theUser.role === 'user') && theUser.unitId === unit.uid) {
                return (
                  <option key={unit.uid} value={unit.uid}>
                    {unit.name}
                  </option>
                );
              }
              else if (theUser.role !== 'manager' && theUser.role !== 'user') {
                return (
                  <option key={unit.uid} value={unit.uid}>
                    {unit.name}
                  </option>
                );
              }
              return null;
            })}
          </select>
          <select
            name="role"
            className="flex50"
            value={formData.role}
            onChange={handleChange}
          >>
            <option key="NewsForm-role" value="unassigned">
              Tillgång för...
            </option>
            {theUser.role === 'superadmin' && (
              <>
                <option value="superadmin">Superadmin</option>
                <option value="admin">Administratör</option>
                <option value="manager">Föreståndare</option>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'dev' && (
              <>
                <option value="superadmin">Superadmin</option>
                <option value="admin">Administratör</option>
                <option value="manager">Föreståndare</option>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'admin' && (
              <>
                <option value="admin">Administratör</option>
                <option value="manager">Föreståndare</option>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'manager' && (
              <>
                <option value="manager">Föreståndare</option>
                <option value="user">Medarbetare</option>
              </>
            )}
            {theUser.role === 'user' && (
              <>
                <option value="user">Medarbetare</option>
              </>
            )}
          </select>
          <input
            type="text"
            name="description"
            className="flex100"
            value={formData.description}
            onChange={handleChange}
            placeholder="Skriv in beskrivning här"
          />
          <input hidden
            id="file"
            type="file"
            onChange={handleFileChange}
          /><label htmlFor="file" className="flex50 formBtn upload-button">{fileName === 'Ladda upp en fil' || !fileName ? 'Ladda upp en fil' : 'Fil: ' + fileName}</label>
          <input hidden
            id="image"
            type="file"
            onChange={handleFileChange}
          /><label htmlFor="image" className="flex50 formBtn upload-button">{imageName === 'Ladda upp en bild' || !imageName ? 'Ladda upp en bild' : 'Bild: ' + imageName}</label>

          <button className=" flex30 formBtn deleteBtn" onClick={handleDelete}>
            Radera nyhet
          </button>
          <button className=" flex30 formBtn" type="submit">
            Skicka
          </button>
        </form>

        <div
          style={{ display: "grid", justifyContent: "center", marginTop: "10vh" }}
          className={img ? '' : 'hidden'}
        //todo inline css
        >
          <img
            src={img}
            alt="news"
            className="edit-news-pic"
            id={"edit-news-img"}
            style={{ maxWidth: "40vw" }}
          //todo inline css
          />
          {!env.devmode ? <></> : <p>debug: img={img}</p>}
        </div>

      </div>
    </Template>
  );
};

export default EditNewsPage;
