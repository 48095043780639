/* eslint-disable */
import React, {useEffect, useState} from "react";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { auth, db, storage } from "../store/firebase/firebaseConfig";
import { ref, getMetadata } from "firebase/storage"
import { getAuth } from "firebase/auth";
import "../assets/styles/Buttons/developer-button.scss";
import { getFunctions, httpsCallable } from "firebase/functions";
import { env } from "../env";
import useProfile from "../hooks/user/useProfile.ts";
import useUser from "../hooks/user/useUser.ts";
import {useSelector} from "react-redux";
import {getRoleName} from "../store/firebase/roleEnums";
import {getUnitByName, getUnitName} from "../store/actions/fetchDocs";

export default function DevTestMenu() {
  const user = useSelector((state) => state.account.user);
  const useUser = useSelector((state) => state.account.auth);
  const [unitName, setUnitName] = useState(null)

  !env.devmode?{}:!env.verybose?{}:console.debug('auth.user:', useUser//?.user?.auth?.currentUser?.reloadUserInfo?.customAttributes
  )
  useEffect(() => {
    let ignore = false
    if (useUser) {
      getUnitName(useUser.unitId).then(r => setUnitName(r))
      return () => ignore = true
    }
  }, [useUser]);


  // if (env.verbose) console.debug(user?.role)
  return !env.devtest && !env.devmode ? <></> : (
    <div className={"developer-menu"}>
      <div className={"dev-btn-default"} onClick={devTesting}>{useUser?.email.split('@')[0] + "@.." || user?.email || 'nil'}</div>
      <div className={"dev-btn-default"} onClick={rolebutton}>R: {useUser?.role || "~" + user?.role}</div>
      <div className={"dev-btn-default"} onClick={(e) => unitbutton(unitName)}>E: {unitName ? unitName : "~" + user?.unit}</div>
    </div>
  )
}

function devTesting(optionals) {
  console.debug('_ _\ncurrentuser', auth.currentUser.email,
    '\nattr:', auth.currentUser.reloadUserInfo.customAttributes,
    '\nid: ', auth.currentUser.uid,
    auth.currentUser.auth
  )
}
function rolebutton(){}
function unitbutton(name){
  getUnitByName(name).then(res => console.info(res)).catch(e => console.warn(e))
}

// function getDataSource() {
//   let x = "local mock"
//   if (process.env.REACT_APP_MOCK_DATA !== 'true') x = "firebase"
//   return x
// }

// function devTesting3(optionals) {
//   const baseData = {
//     creationTime: Timestamp.now(),
//     updateTime: Timestamp.now(),
//     disabled: false,
//     author: getAuth().currentUser.uid,
//     // uid: dociref.id
//   }
//   const data2 = {
//     test: '',
//     ost: 'ost',
//   }
//   const date = Timestamp.now().toDate()
//   const mins = addAzero(date.getMinutes())
//   const secs = addAzero(date.getUTCSeconds())
//   const data3 = { ...baseData, ...data2 }
//
//   function addAzero(int) {
//     if (int > 9) return int.toString()
//     return '0' + int.toString()
//   }
//
//
//   console.debug(mins, secs)
//
//   // createXhr('woo', 1000, 'GET', 'ringbutton', '', true).then((xhr) => xhr.send())
// }
// function devTesting2(optionals) {
//   // createXhr('woo', 1000, 'GET', 'ringbutton', '').then((xhr) => xhr.send())
//   const functions = getFunctions();
//   const callbtn = httpsCallable(functions, 'callbtn')
//   console.debug('callbtn')
//   callbtn().then((res) => {
//     console.debug(1, res)
//   })
//     .catch((e) => { console.error(e) })
//   // getGreeting()
//   // .then((result) => {
//   //   // Read result of the Cloud Function.
//   //   /** @type {any} */
//   //   console.debug('result: ',result.data)
//   //
//   // })  .catch((error) => {
//   //   // Getting the Error details.
//   //   const code = error.code;
//   //   const message = error.message;
//   //   const details = error.details;
//   //   // ...
//   // })
// }
//
  // console.debug(auth.currentUser.reloadUserInfo.customAttributes, '| custom attributes')

  // testCallableButton(user, user.uid, customAttributes.role)
  // getDataFromFireBase()

//
// function testCallableButton(user, uid, role) {
//   if (user == null || user === undefined) throw new Error("No user loaded")
//   // const role = customAttributes.role
//   // console.debug('btn', user)
//   user.getIdToken(true)
//     .then(token => {
//       const xhr = new XMLHttpRequest()
//       xhr.open(
//         "GET",
//         "http://localhost:5001/kev-infid-humanisthemmet/europe-north1/getuser?uid=" + uid,
//         true
//       )
//       xhr.setRequestHeader("Authorization", "Basic " + token)
//       // xhr.setRequestHeader("Authorization", "Bas" ) // error testing
//
//       const body = JSON.stringify({
//         uid: uid,
//         role: role
//       })
//
//       // xhr.onreadystatechange = () => {console.debug('readystate', xhr.readyState, xhr.status)}
//
//       xhr.onload = () => {
//         switch (xhr.status) {
//           case 200:
//             if (xhr.readyState !== 4) break
//             // console.debug('restext:', xhr.responseText)
//             const body = JSON.parse(xhr.responseText)
//             console.log('callable button res:', xhr.status, xhr.statusText,
//               "\n" + body.email,
//               // "\n" + xhr.responseText,
//               "\ntoken:" + token.substring(token.length - 4))
//             break
//           default:
//             console.error(xhr.status, xhr.statusText)
//         }
//       }
//       // xhr.send()
//       xhr.send(body)
//
//
//     }
//     )
//     .catch(error => {
//       console.error(error)
//     })
//   // const getfunctions = getFunctions(app, "europe-north1")
//   // console.debug('getfunctions', getfunctions.region)
//   // const callButton = httpsCallable(getfunctions, 'callbutton');
//   // callButton({ text: 'text' })
//   // .then((response) => {
//   //   console.debug('callbutton:', response)
//   // })
//   // .catch((e) => {console.error(e)})
// }
//
// async function collectToken() {
//   return await user.getIdToken()
// }
//
// function getDataFromFireBase() {
//   // getUserRoles('users', fName)
//   getDocData('testdocs', 'user')
//   getDocData('testdocs', 'mngr')
//   getDocData('testdocs', 'admin')
//   getDocData('testdocs', 'sa')
//   getDocData('testdocs', 'dev')
//   getImgMetaData('dev/slip.png')
//   getImgMetaData('sa/slip.png')
//   getImgMetaData('admin/slip.png')
//   getImgMetaData('mngr/slip.png')
//   getImgMetaData('user/slip.png')
//   getImgMetaData('slip.png')
//   // const url = getAuth().currentUser.photoURL
//   // console.debug("pfp:", url)
// }
//
// function getImgMetaData(url) {
//   const boilerplateURL = 'roletesting/'
//   const imgRef = ref(storage, boilerplateURL + url);
//   // Get metadata properties
//   getMetadata(imgRef)
//     .then((metadata) => {
//       return console.debug('storage/' + url, '| ok')
//       // Metadata now contains the metadata for 'images/forest.jpg'
//     }).catch((error) => {
//       return console.debug('storage/' + url, '|', error.code)
//     })
// }
//
// async function getDocData(path, document) {
//   try {
//     const docSnap = await getDoc(doc(db, path, document))
//
//     if (docSnap.exists()) {
//       console.debug(path + "/" + document + ": ok |", docSnap.data().content)
//     } else {
//       console.debug(path + '/' + document + ": No such document!")
//       // docSnap.data() will be undefined in this case
//     }
//   } catch (e) {
//     console.debug(path + "/" + document + ":", e.code)
//   }
// }
//
// async function getUserRoles(path, document) {
//   try {
//     const docRef = doc(db, path, document)
//     const docSnap = await getDoc(docRef)
//     const dataMap = docSnap.data()
//     // console.debug(dataMap)
//     const role = dataMap.roles
//
//     if (docSnap.exists()) {
//       console.debug(path + '/' + document + ": Document roles: ", role)
//
//     } else {
//       // docSnap.data() will be undefined in this case
//       console.debug(path + '/' + document + ": No such document!")
//     }
//   } catch (e) {
//     console.debug(path, document, ":", e.message)
//   }
// }