import type { User } from 'firebase/auth';
import { getApp } from 'firebase/app';
import { doc, updateDoc, getFirestore, onSnapshot } from 'firebase/firestore';
import type { FirestoreError } from 'firebase/firestore';
import { useEffect, useState } from 'react';

// Whatever your profile looks like!
export type ProfileProps = {};

type UseProfileResponse = {
    profile: ProfileProps | null | undefined;
    updateProfile: (newData: Partial<ProfileProps>) => Promise<void>;
    profileLoading: boolean;
    profileError: FirestoreError | undefined;
};

const useProfile = (
    user: Partial<User> | null | undefined
): UseProfileResponse => {
    const app = getApp();
    const firestore = getFirestore(app);
    const [profile, setProfile] = useState<ProfileProps | null>(null);
    const [profileError, setProfileError] = useState<
        FirestoreError | undefined
    >();
    const [profileLoading, setProfileLoading] = useState(false);

    useEffect(() => {
        if (!user?.uid) {
            setProfile(null);
            return undefined;
        }

        setProfileLoading(true);
        const profileRef = doc(firestore, 'users', user.uid);
        const unsubscribe = onSnapshot(
            profileRef,
            (profileDoc) => {
                setProfile(profileDoc.data() as ProfileProps);
                setProfileLoading(false);
            },
            setProfileError
        );

        return unsubscribe;
    }, [firestore, user?.uid]);

    const updateProfile = async (
        newData: Partial<ProfileProps>
    ): Promise<void> => {
        if (!user?.uid) {
            return;
        }

        const profileRef = doc(firestore, 'users', user.uid);
        await updateDoc(profileRef, newData);
    };

    return {
        profile,
        updateProfile,
        profileLoading,
        profileError,
    };
};

export default useProfile;