import { useState, useEffect } from "react";
import { StartPageConfig } from "../config/StartPageConfig";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../../store/reducers/userReducer";
import useUser from "../../user/useUser.ts";
import env from "../../../env";

export const StartPageAccess = () => {
  const [links, setLinks] = useState([]);
  const user = useSelector((state) => state.account.theUser);
  const auth = useSelector((state) => state.account.auth);
  // const [attributes, setAttributes] = useState({role: '', unitId: ''});
  // const auth = useUser()
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const preJson = auth?.user?.auth?.currentUser?.reloadUserInfo?.customAttributes
  //   console.debug('preJson', preJson)
  //   if (preJson !== undefined && (attributes.role === '' || attributes.unitId === '')) {
  //     console.debug('korv')
  //     const attr = JSON.parse(preJson)
  //     const {role, unitId} = attr
  //     setAttributes({role, unitId})
  //   }
  //
  // }, [auth])

  useEffect(() => {
    !env.devmode ? {}:console.debug('authrole:', auth?.role)
    if (auth?.role) {
      const roleLinks = StartPageConfig[auth.role] || [];
      const updatedLinks = roleLinks.map((link) => {
        if (link.path === "/enhet/") {
          console.log('aaaa', user.unit)
          return { ...link, path: `/enhet/${user.unit}` };
        }
        return link;
      });
      setLinks(updatedLinks);
    }
    // else dispatch(logoutUser)
  }, [auth]);

  return links;
};
