let x = {v: false, vv: false, dt: false, d: false, m: false}



export const env = () => {
  x.dt = 'true' // devtest
  x.d = 'true'  // devmode
// m: true  // mockdata
// v: true  // verbose console
// vv: true  // very verbose console
  return {
    devtest:  x.dt === 'true' && process.env.NODE_ENV === 'development' && process.env.REACT_APP_devtest       === 'true',
    devmode:  x.d === 'true'  && process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_MODE      === 'true',
    verbose:  x.v === 'true'  && process.env.NODE_ENV === 'development' && process.env.REACT_APP_verbose       === 'true',
    verybose: x.vv === 'true' && process.env.NODE_ENV === 'development' && process.env.REACT_APP_very_verbose  === 'true',
    mockdata: x.m  === 'true' && process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_DATA     === 'true',
  }
}

export const foo = env.devmode ? { kd: 'foo kev\n', oc: 'foo olle\n' } : { kd: '', oc: '' }

export const invalidUnitIds = [undefined, null, "1", 1, 'ENHETSNAMN']

export const invalidString = [undefined, null, '', ' ']

export function ReloadPage(urlPath, {navigate}, timeout = 50) {
  console.log("Reloading", urlPath, 'timeout', timeout*2)

  setTimeout(() => {navigate("/")}, timeout);
  setTimeout(() => {navigate(urlPath)}, timeout);
}

export const storageDirectories = {
  news: 'news/',
  documents: 'files/',
  unitimages: 'public/units/',
  profilepics: 'public/profile_pictures/',
}

export function consolecheck(from) {
  if (process.env.NODE_ENV === 'development') {
    console.assert(env.devtest, 'devtest ','off')
    console.assert(env.devmode, 'dev mode ', 'off')
    console.assert(env.verbose, 'verbose','off')
    console.assert(env.verybose, 'very verbose', 'off')
    console.count('console checked from ' + from)
  }
  return true
}
consolecheck()

export default env;
