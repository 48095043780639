
import documentIcon from "../icons/document-icon.png"
import musicIcon from "../icons/music.png"
import imageIcon from "../icons/picture-icon.svg"
import zipIcon from "../icons/zip.png"
import {ref} from "firebase/storage";
import env from "../../../env";

export default function Icons() {
  return {
    documentIcon,
    musicIcon: env.devmode ? musicIcon : documentIcon,
    imageIcon: env.devmode ? imageIcon : documentIcon,
    zipIcon: env.devmode ? zipIcon : documentIcon,
  }
}

export function getIcon(contentType) {
  console.debug(contentType)
  return new Promise((resolve, reject) => {
    if (!contentType) reject( new Error("No file specified")  )
    if (contentType.includes('audio') && env.devmode) resolve( musicIcon )
    if (contentType.includes('image') && env.devmode) resolve( imageIcon )
    if (contentType.includes('zip') && env.devmode) resolve( zipIcon )
    else resolve ( documentIcon )
  })
}
