import React, { useState, useEffect } from "react";
import Template from "../pages/template/Template";
import FolderForm from "../components/Forms/FolderForm";
import "../assets/styles/Pages/FolderPage.scss";
import { env } from "../env";
import { getSubDirs } from "../store/actions/fetchDocs";
import { getAuth } from "firebase/auth";
import { BackButton } from "../components/Button"
import { useSelector } from 'react-redux';

function DocumentPage() {
  const handleFormSubmit = (formData) => {
    console.log("Formulärdata: ", formData);
  };
  /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
  const theUser = useSelector((state) => state.account.theUser);

    useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Template>
      < BackButton />
      <h1>Hantera mappar</h1>
      <p>Här är dina mappar här...</p>
      <FolderForm onSubmit={handleFormSubmit} theUser={theUser} />

      <div className="profile-page">
        <section className={env.devmode ? "special-section" : 'hidden'}>
          <h2>Att göra</h2>
          <p>
            Denna sektion har unika stilar definierade i FolderPage.scss.
            Noterbart att vi ännu inte har implementerat alla värden för mappar
            sidan. Dynamisk laddning för underkatergorier. Möte om detta behövs
            etc.
                </p>
        </section>
      </div>
    </Template>
  );
}

export default DocumentPage;