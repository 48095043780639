import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { setUser } from "../../store/reducers/userReducer"
import { signIn } from "../../store/actions/authActions"
import { env } from "../../env"
import "../../assets/styles/Forms/LoginForm.scss"

export function LoginForm() {
  const [inputFields, setInputFields] = useState({ email: '', password: '' })
  const [felMeddelande, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  function handleChange(e) {
    const { id, value } = e.target
    switch (id) {
      case "email":
        const email = value
        setInputFields({ ...inputFields, email })
        break
      case 'password':
        const password = e.target.value
        setInputFields({ ...inputFields, password })
        break
      default: break
    }
  }
  // Dispatcha setUser action, email och password som payload
  // Ändrat till token /k
  function handleSubmit(event) {
    event.preventDefault()
    const { email, password } = inputFields
    signIn({ email, password }).then(userCredential => {
      if (env.verbose) console.debug(userCredential)
      // const { accessToken, photoURL, reloadUserInfo } = userCredential
      // const { customAttributes, disabled } = reloadUserInfo

      dispatch(setUser({ email, password }))
      resetInputFields()
      setErrorMessage('');
    })
      .catch((error) => {
        console.error("Sign in error", error);
        setErrorMessage('Ange rätt användarnamn och lösenord');
      });
  };

  function resetInputFields() {
    if (process.env.REACT_APP_RESET_LOGIN_FORM) return;
    setInputFields({ email: '', password: '' })
    console.debug('input fields reset')
  }

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <section className="login-container">
        <section className="login-input-container">
          <p className="login-labels">E-post</p>
          <input
            className="login-input-field"
            type="email"
            id="email"
            value={inputFields.email}
            autoComplete={'current-user'}
            onChange={handleChange}
            required
          />
        </section>
        <section className="login-input-container">
          <p className="login-labels">Lösenord</p>
          <input
            type="password"
            id="password"
            className="login-input-field"
            value={inputFields.password}
            autoComplete={'current-password'}
            onChange={handleChange}
            required
          />
        </section>
        {felMeddelande && <div className="login-error-message">{felMeddelande}</div>}
        <button className="login-submit-btn" type="submit">Logga in</button>
      </section>
    </form>
  );
}

export default LoginForm;
