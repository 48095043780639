import React, { useState } from "react";
import {
  validateEmail,
  validatePhoneNr,
} from "../Validate/FormValidation";

import { viewErrorPopup } from "../Popups/FormErrorPopup";
import { getAuth } from "firebase/auth";
import { checkIfFileExists, storageDirectories, toUploadDefault, uploadFile } from "../../store/actions/handleStorage";
import { foo } from "../../env";
import { makeUser, updateUser } from "../../store/reducers/accountReducer";
import { useDispatch } from "react-redux";
// import { getStorage, ref } from "firebase/storage";

function FormTemplate({ onSubmit, user }) {
    const dispatch = useDispatch();
    const [submitDone, setSubmitDone] = useState(false)
    const storageDirectory = storageDirectories.profilepics
    const [toUpload, setToUpload] = useState({});
    const [formData, setFormData] = useState({
    title: user.title,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNr: user.phoneNr,
    email: user.email,
    role: user.role,
    imagePath: user.imagePath,
  });


  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, id, value, files } = e.target;
    switch (name) {
      case "image":
        const file = files[0];
        if (!file) return resetToUpload(id, e.target);
        setToUpload((prevState) => ({ ...prevState, image: file }));
        setFormData((prevState) => ({
          ...prevState,
          imagePath: storageDirectory + user.id + "/" + file.name,
        }));
        console.debug("imgname:", file.name);
        break;
      case "unit":
        const selectedOption = e.target.options[e.target.selectedIndex];
        const unit = selectedOption.text;
        const unitId = e.target.value;

        setFormData((prevState) => ({
          ...prevState,
          unit,
          unitId,
        }));

        break;
      default:
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  function resetToUpload(type, e) {
    console.debug("no", type);
    setToUpload((prevState) => ({}));
    setFormData((prevState) => ({ ...prevState, imagePath: "" }));
    // document.getElementById(type).setAttribute('value', '')
  }

  function uploadFiles(uid) {
    console.debug('toUpload', toUpload)
    if (toUpload.image)
      if (toUpload.image.name !== toUploadDefault.image.name) {
        const pathToSet = storageDirectory + uid + "/"
        checkIfFileExists(pathToSet + toUpload.image.name).then(fileExists => {
          console.debug(foo.kd, fileExists)
          if (!fileExists)
            uploadFile(pathToSet, toUpload.image).then((path) => {
              resetToUpload();
              console.debug("image upload success:", path);
              updateUser({uid, formData: {imagePath: path}})
            })
          else {
            uploadFile(pathToSet, toUpload.image, "(" + String(fileExists) + ")" + toUpload.file.image).then((path) => {
              resetToUpload();
              console.debug("image upload success:", /*path*/);
              updateUser({uid, formData: {imagePath: path}})
            })
          }
        })
      }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    /* Valideringslogik nedan*/
    /*Valideringsarray*/
    const valideringFunctionArray = {
      email: validateEmail,
      phoneNr: validatePhoneNr,
    };
    let hasErrors = false;
    let errorString = "";

    for (const field in formData) {
      const validator = valideringFunctionArray[field];
      if (validator) {
        const error = validator(formData[field]);
        if (error) {
          hasErrors = true;
          errorString += `<p>${error}</p>`;
          setErrors((prev) => ({ ...prev, [field]: error }));
        } else {
          setErrors((prev) => ({ ...prev, [field]: null }));
        }
      }
    }
    /* Kör eller neka... */
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {
      console.debug(formData);
      dispatch(updateUser({ uid: user.uid, formData }))
        .unwrap()
        .then(uid => {
          console.debug(1336, toUpload.image, toUploadDefault.image)
          if (toUpload.image !== toUploadDefault.image) uploadFiles(user.uid)
                setSubmitDone(true)
          // navigate(0)
          // window.location.reload()
        })
    }

    //onSubmit(formData); // Skicka formdata till föräldrakomponenten eller hantera här
  };

  return (
    <form className="flex-form" onSubmit={handleSubmit}>
      <input
        type="text"
        name="title"
        className="flex30"
        value={formData.title}
        onChange={handleChange}
        placeholder="Titel"
      />
      <input
        type="text"
        name="firstName"
        className="flex30"
        value={formData.firstName}
        onChange={handleChange}
        placeholder="Skriv in förnamn"
      />
      <input
        type="text"
        name="lastName"
        className="flex30"
        value={formData.lastName}
        onChange={handleChange}
        placeholder="Skriv in efternamn"
      />
      <input
        type="number"
        name="phoneNr"
        className="flex50"
        value={formData.phoneNr}
        onChange={handleChange}
        placeholder="Telefonnummer"
      />
      <input
        type="email"
        name="email"
        className="flex50"
        value={formData.email}
        onChange={handleChange}
        placeholder="E-post"
      />
      {/*<select*/}
      {/*    name="role"*/}
      {/*    className="flex30"*/}
      {/*    value={formData.role}*/}
      {/*    onChange={handleChange}*/}
      {/*>*/}
      {/*    <option value="">Välj ett alternativ</option>*/}
      {/*    <option value="option1">Alternativ 1</option>*/}
      {/*    <option value="option2">Alternativ 2</option>*/}
      {/*</select>*/}

      <input
        hidden
        name="image"
        id="image"
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="image" className="flex50 formBtn upload-button">
        {toUpload.image ? `Profilbild: ${toUpload.image.name}` : 'Ladda upp en bild'}
      </label>

      <button className=" flex30 formBtn" type="submit" style={!submitDone ? {} : {backgroundColor: "green"}}>
        Skicka
            {submitDone ? ' ✓' : ''}
          </button>
    </form>
  );
}

export default FormTemplate;
