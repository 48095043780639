import React, { useEffect, useState } from "react";
import BuildingIcon from "../../../assets/images/icons/document-icon2.png";
import BuildingFolderTemplate from "../template/BuildingFolderHeadlineTemplate";
import "../../../assets/styles/Listing/template/BuildingFolderHeadlineTemplate.scss";
// import { folderData } from "../../../store/firebase/mockData";
import { getSubDirs, humanreadablePath } from "../../../store/actions/fetchDocs";
import { getAuth } from "firebase/auth";
import folderIcon from "../../../assets/images/icons/folder-icon.png"
import { useNavigate } from "react-router-dom";
import {env} from "../../../env";
import {useSelector} from "react-redux";



const BuildingFolderHeadlineListing = ({ id, buildingName }) => {
  let rootDirectory = "folders" + "/"
  const user = useSelector((state) => state.account.theUser);
  const userRole = user?.role
  let ignore = false

  const [docPath, setDocPath] = useState('')
  const [folders, setFolders] = useState([])
  const [prevId, setPrevId] = useState('1')
  const navigate = useNavigate()

  useEffect(() => {
    if (ignore || docPath === '' || docPath === rootDirectory || !userRole) return;
    getSubDirs(docPath, userRole, true).then(dirs => {
      if (env.verbose) console.debug('docpath', docPath)
      if (env.verbose) console.debug('directories', dirs)
      dirs.push({path: docPath, id: buildingName})
      console.debug('data', dirs)
      setFolders(dirs)
    })
    return () => { ignore = true }
  }, [docPath, userRole])

  useEffect(() => {
    if (id !== undefined && prevId !== id && id !== "1") {
      setPrevId(id)
      setDocPath(rootDirectory + id)
    }
  }, [id])

  function handleClick(link) {
    if (link === undefined) return ;
    // const pathsegments = path.split('/') || path
    // console.log(1338, "byggnad/" + pathsegments[0] + "/" + pathsegments[1])
    // navigate(0)
    navigate(link, { replace: true })
  }

  function getLink(path) {
    if (!path) return '';
    path = humanreadablePath(path)
    const pathsegments = path.split('/')
    const firspartOfURL = "/enhet/" + id + "/mappar"
    const secondPartOfURL = path.replace(id, '')
    return firspartOfURL + secondPartOfURL
  }


  // const relevantFolders = folderData[id] || [];
  if (folders.length === 0) return <p>Inga mappar att visa</p>
  console.debug('folders', folders)
  return (
    <div className="folder-list-list">
      {folders?.map((item) => (
        <BuildingFolderTemplate
          key={item.path}
          icon={item.BuildingFolderPicture || folderIcon}
          title={item.id /*+ " (" + item.fullpath + ")"*/}
          link={/*getLink(*/item.path/*)*/}
          path={item.path}
          onClick={(e) => handleClick(getLink(item.path))}
          buildingId={id}
          buildingName={buildingName}
        />
      ))}
    </div>
  );
};

export default BuildingFolderHeadlineListing;
