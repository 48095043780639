import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createDoc, deleteDocFully, disableDoc } from "../actions/setDocs";
import { updateUnit } from "../actions/unitAction";
import {storageDirectories} from "../../env";

export const updateSpecificUnit = createAsyncThunk(
  "news/updateUnit",
  async ({ uid, formData }, { rejectWithValue }) => {
    try {
      const result = await updateUnit(uid, formData);
      if (result === true) {
        const { creationTime, ...serializableFormData } = formData;
        return { uid, ...serializableFormData };
      } else {
        return rejectWithValue("Gick inte att uppdatera enhet");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createUnitT = createAsyncThunk(
  "news/createNewsArticle",
  async (formData, { rejectWithValue }) => {

      return createDoc("units", { ...formData }).then(uid => {
        console.debug('Success', uid)
        const rootData = {
          directory: true,
          fullpath: storageDirectories.documents + uid,
          unitName: formData.formData.name,
          name: formData.formData.name,
          role: 'user',
          unit: uid,
        }
        return createDoc("folders", {formData: {...rootData}}, uid).then(() => ({ uid }))
      })
    .catch(e => {return rejectWithValue("Gick inte att skapa enhet: " + e)})
  });

export const unitSlice = createSlice({
  name: "unit",
  initialState: {
    unitToEdit: null,
    name: '',
    id: '',
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload
    },
    chooseUnitToEdit: (state, action) => {
      console.debug(action.payload.uid);
      return {
        ...state,
        unitToEdit: action.payload.uid,
      };
    },
    setUnitToEdit: (state, action) => {
      const {
        creationTime,
        updateTime,
        ...payloadWithoutTimestamps
      } = action.payload;
      state.unitToEdit = payloadWithoutTimestamps;
    },
    createUnit: (state, action) => {
      console.debug(action.payload);
      const { formData } = action.payload;
      createDoc("units", { formData })
        .then((uid) => {
          return {
            ...state,
            uid
          };
        })
        .catch((e) => console.error(e));
    },
    deleteUnit: (state, action) => {
      console.debug(action.payload);
      const { uid } = action.payload;
      // disableDoc('units', uid).then((uid) => {})
      deleteDocFully("units", uid)
        .then((uid) => { })
        .catch((e) => console.error(e));
      return {
        ...state,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSpecificUnit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSpecificUnit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.unitToEdit = action.payload;
      })
      .addCase(updateSpecificUnit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  createUnit,
  deleteUnit,
  setId,
  setUnitToEdit,
  setName
} = unitSlice.actions;
export default unitSlice.reducer;
