import React, { useState, useEffect, useRef } from "react";
import Template from "../pages/template/Template";
import AccountForm from "../components/Forms/AccountForm";
import AccountListing from "../components/Listing/AccountListing.js";
import "../assets/styles/Pages/AccountPage.scss";
import {fetchDocs, fetchDocsDataSerializeable} from "../store/actions/fetchDocs";
import filterIcon from "../assets/images/icons/filter-icon-white.png";
import { useSelector } from 'react-redux';
import { BackButton } from "../components/Button.js";
import { env } from "../env";

function AccountPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [units, setUnits] = useState([]);

  /*Hämtar data om aktuell användare från Global state (Sätt när användaren besöker StartPage.. */
  const theUser = useSelector((state) => state.account.theUser);


  useEffect(() => {
    let ignore = false;

    if (!ignore) fetchDocsDataSerializeable("units").then((res) => setUnits(res))
    return () =>
      ignore = true;
  }, []);

  useEffect(() => {
    let ignore = false;
    window.scrollTo(0,0)
    return () => ignore = true;
  }, []);

  const handleFormSubmit = (formData) => { };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return !theUser ? <></> : (
    <Template>
      <BackButton />
      <h1>Konton</h1>
      <p>Skapa/ändra dina konton här...</p>
      {theUser.role === 'user' ? <></> : <AccountForm onSubmit={handleFormSubmit} units={units} theUser={theUser} />}

      <div className="profile-page">
        <section className={env.devmode ? "special-section" : 'hidden'}>
          {/*to do*/}
          <h2>Att göra</h2>
          <p>
            Denna sektion har unika stilar definierade i AccountPage.scss.
            Noterbart att vi ännu inte har implementerat alla värden för account
            sidan. Profilbild saknas.
          </p>
        </section>
      </div>
      <section className="template-subsection">
        <section className="left-section">
          <h1>Skapade konton</h1>
          <p>Nedan visas dina skapade konton</p>
        </section>
        <section className="right-section">
          <input
            type="text"
            name="search-filter-field"
            className="searchFilterbtn"
            placeholder="Sök efter användare här"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </section>
      </section>
      <section className="template-subsection-list">
        <AccountListing searchQuery={searchQuery} />
      </section>
    </Template>
  );
}

export default AccountPage;
