import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeUser } from "../../store/reducers/accountReducer";
import { uploadFile, toUploadDefault, storageDirectories, checkIfFileExists } from "../../store/actions/handleStorage";
import { ReloadPage } from "../../env"

import {
  ValidateAccountForm,
  validateEmail,
  validatePassword,
  validatePhoneNr,
} from "../Validate/FormValidation";

import { viewErrorPopup } from "../Popups/FormErrorPopup";
import { useNavigate } from "react-router-dom";
import { foo } from "../../env";
import {updateDoc} from "../../store/actions/setDocs";
import {elevatedRoles} from "../../store/firebase/roleEnums";

function AccountForm({ onSubmit, units, theUser }) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    role: "",
    unit: "",
    imagePath: "",
    password: "",
    passwordrepeat: "",
    unitId: "",
  });

  const storageDirectory = storageDirectories.profilepics
  const [errors, setErrors] = useState({});
  const [submitDone, setSubmitDone] = useState(false);
  const [toUpload, setToUpload] = useState({
    image: toUploadDefault.image,
    file: toUploadDefault.file,
  });

  const handleChange = (e) => {

    const { name, id, value, files } = e.target;
    switch (name) {
      case "image":
        const file = files[0];
        if (!file) return resetToUpload(id, e.target);
        setToUpload((prevState) => ({ ...prevState, image: file }));
        setFormData((prevState) => ({
          ...prevState,
          imagePath: storageDirectory + file.name,
        }));
        console.debug("imgname:", file.name);
        break;
      case "unit":
        const selectedOption = e.target.options[e.target.selectedIndex];
        const unit = selectedOption.text;
        const unitId = e.target.value;

        setFormData((prevState) => ({
          ...prevState,
          unit,
          unitId,
        }));

        break;
      default:
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
    setSubmitDone(false)
  };

  function resetToUpload(type, e) {
    console.debug("no", type);
    setToUpload((prevState) => ({
      ...prevState,
      image: toUploadDefault.image,
    }));
    setFormData((prevState) => ({ ...prevState, imagePath: "" }));
    // document.getElementById(type).setAttribute('value', '')
  }

  function uploadFiles(uid) {
    console.debug('toUpload', toUpload)
    if (toUpload.image)
      if (toUpload.image.name !== toUploadDefault.image.name) {
        const pathToSet = storageDirectory + uid + "/"
        checkIfFileExists(pathToSet + toUpload.image.name).then(fileExists => {
            console.debug(foo.kd, fileExists)
            if (!fileExists)
              uploadFile(pathToSet, toUpload.image).then((path) => {
                resetToUpload();
                console.debug("file upload success:", path);
                updateDoc('users', uid, {imagePath: path});
              })
            else {
              uploadFile(pathToSet, toUpload.image, "(" + String(fileExists) + ")" + toUpload.file.image).then((path) => {
                resetToUpload();
                console.debug("file upload success:", path);
                updateDoc('users', uid, {imagePath: path});
              })
            }
        })
      }
  }
  function reloadPage(){ReloadPage("/konton", {navigate})}

  const handleSubmit = (e) => {
    e.preventDefault();
    const {hasErrors, errorString} = ValidateAccountForm(formData, true)

    /* Kör eller neka... */
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {
      console.debug(formData);
      dispatch(makeUser({ formData }))
        .unwrap()
        .then(uid => {
          console.debug(1336, toUpload.image, toUploadDefault.image)
          if (toUpload.image !== toUploadDefault.image) uploadFiles(uid)
          updateDoc('users', uid, {uid});
          reloadPage()
          setSubmitDone(true)
        })
    }

  };
  return (
    <form className="flex-form" onSubmit={handleSubmit}>
      <input
        type="text"
        name="title"
        className="flex100"
        value={formData.title}
        onChange={handleChange}
        placeholder="Titel"
      />
      <input
        type="text"
        name="firstName"
        className="flex50"
        value={formData.firstName}
        onChange={handleChange}
        placeholder="Förnamn"
      />
      <input
        type="text"
        name="lastName"
        className="flex30"
        value={formData.lastName}
        onChange={handleChange}
        placeholder="Efternamn"
      />
      <input
        type="email"
        name="email"
        className="flex50"
        value={formData.email}
        onChange={handleChange}
        placeholder="E-post"
        autoComplete="email"
      />
      <div className="input-group">
        <input
          type="text"
          name="phoneNr"
          className="flex50"
          value={formData.phoneNr}
          onChange={handleChange}
          placeholder="Telefonnummer"
        />
      </div>

      <select
        name="role"
        className="flex50"
        value={formData.role}
        onChange={handleChange}
      >
        <option value="" disabled>
          Välj administratörsroll
      </option>
        {theUser.role === 'superadmin' && (
          <>
            <option value="superadmin">Superadmin</option>
            <option value="admin">Administratör</option>
            <option value="manager">Föreståndare</option>
            <option value="user">Medarbetare</option>
          </>
        )}
        {theUser.role === 'dev' && (
          <>
            <option value="superadmin">Superadmin</option>
            <option value="admin">Administratör</option>
            <option value="manager">Föreståndare</option>
            <option value="user">Medarbetare</option>
          </>
        )}
        {theUser.role === 'admin' && (
          <>
            <option value="admin">Administratör</option>
            <option value="manager">Föreståndare</option>
            <option value="user">Medarbetare</option>
          </>
        )}
        {theUser.role === 'manager' && (
          <>
            <option value="user">Medarbetare</option>
          </>
        )}
        {theUser.role === 'user' && (
          <>
            <option value="user">Medarbetare</option>
          </>
        )}
      </select>
      <select
        name="unit"
        className="flex50"
        value={formData.unitId}
        onChange={handleChange}
      >
        <option value="" disabled>Välj enhet</option>

        {elevatedRoles.includes(formData.role) ? (<option value='Alla'>Alla</option>) : (<></>)}

        {units.map((unit) => {

          if ((theUser.role === 'manager' || theUser.role === 'user') && theUser.unitId === unit.uid) {
            return (
              <option key={unit.uid} value={unit.uid}>
                {unit.name}
              </option>
            );
          }
          else if (theUser.role !== 'manager' && theUser.role !== 'user') {
            return (
              <option key={unit.uid} value={unit.uid}>
                {unit.name}
              </option>
            );
          }
          return null;
        })}
      </select>
      <input
        hidden
        name="image"
        id="image"
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="image" className="flex50 formBtn upload-button">
        {toUpload.image.name}
      </label>
      <input
        type="password"
        name="password"
        className="flex50"
        value={formData.password}
        onChange={handleChange}
        placeholder="Lösenord"
        autoComplete="new-password"
      />
      <input
        type="password"
        name="passwordrepeat"
        className="flex50"
        value={formData.passwordrepeat}
        onChange={handleChange}
        placeholder="Repetera lösenord"
        autoComplete="new-password"
      />
      <button className=" flex100 formBtn" type="submit">
        Skicka
      </button>
      <section style={{marginTop: '1vh'}}>
        {submitDone ? 'Konto har skapats!' : ''}
      </section>
    </form>
  );
}

export default AccountForm;
