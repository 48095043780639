import React from "react";
import "../../../assets/styles/Listing/template/BuildingFolderHeadlineTemplate.scss";
import { humanreadablePath } from "../../../store/actions/fetchDocs";
import {env, foo} from "../../../env";
import { Link } from "react-router-dom";

const BuildingFolderHeadlineTemplate = ({ icon, title, link, onClick, path, buildingId, buildingName }) => {
  const isIconEmoji = typeof icon === "string" && icon.length < 10;
  const pathSegments = link.split("/");
    // const pathsegmentsWithOutFileName = humanreadablePath(path).replace("/" + title, '').split("/")
    // console.debug(foo.kd, title, "path:", path, pathsegmentsWithOutFileName, pathsegmentsWithOutFileName.length)

    if (env.verbose) console.debug(foo.kd, title, "path:", path, 'link', link)
    return (
    <div className="folder-card-list" onClick={onClick}>
      <div className="folder-card-icon-list">
        {isIconEmoji ? icon : <img src={icon} alt={`${title} icon`} />}
      </div>

      <Link to={link} className="view-all-folders">
        {pathSegments.length < 3 ? 'Hem' : title }
        {env.devmode ? <section style={{fontSize: "smaller", color: "black"}} >debug: {link}</section> : ''}
      </Link>

      {/*<div className="folder-card-title-list">{title}</div>*/}
    </div>
  );
};

export default BuildingFolderHeadlineTemplate;
