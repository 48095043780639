import React, { useEffect, useState } from "react";
import { setDirectory } from "../../store/actions/setDirectory";
import {
  addToStringForFiltering, convertDocSnapshot,
  fetchDocByFullpath,
  fetchDocsData, getAllUnitIdsAndNames,
  getContentInDirAndSubdirs,
  getSubDirs,
  getSubDirsRecursive, getUnitName, humanreadablePath
} from "../../store/actions/fetchDocs"
import {env, foo, ReloadPage} from "../../env";
import {elevatedRoles, getRoleName, getRoleNameAndBelow} from "../../store/firebase/roleEnums";
import {useNavigate} from "react-router-dom";
import {ValidateFolderForm} from "../Validate/FormValidation";
import {viewErrorPopup} from "../Popups/FormErrorPopup";
import {useSelector} from "react-redux";
import FolderPageListning from "../Listing/Folders/FolderPageListning";

function FormTemplate({ onSubmit, theUser }) {
  const [formData, setFormData] = useState({
    title: "",
    role: "user",
    unitId: "default",
  });

  const navigate = useNavigate()
  const user = useSelector(state => state.account.theUser)
  const [units, setUnits] = useState([])
  const [theUnit, setTheUnits] = useState([])
  const [dirs, setDirs] = useState([])
  const [subdirs, setSubdirs] = useState([])
  const [unitDirs, setUnitDirs] = useState({filteredDirs: [], dirs: []})
  const [role, setRole] = useState('user')
  function reloadPage(){ReloadPage("/hantera-mappar", {navigate})}
  /**Alla Dokument */
  const [allDocs, setAllDoc] = useState(0);
  const [addedDocs, setAddedDocs] = useState([]);
  const [addCount, setAddCount] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    !env.devmode ? {}:console.debug('val', value)

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  };

  const handleUnitChange = (e) => {
    const { name, value } = e.target;
    const selectedUnitName = e.target.options[e.target.selectedIndex].text;

    setTheUnits(selectedUnitName)
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      unitName: selectedUnitName
    }))
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const {hasErrors, errorString} = ValidateFolderForm(formData, true)
    !env.devmode ? {}:console.debug('hasErrors', hasErrors, 'errorString', errorString)
    if (hasErrors) {
      viewErrorPopup(
        "Ooupps! Några små ändringar tills vi är klara.",
        errorString
      );
    } else {
        setDirectory({ formData }, true).then((docRef) => {
          setFormData((prevState) => ({
           ...prevState,
           title: "",
           role: "user",
          }))
          !env.devmode ?  console.log("doc created") :
            console.log("doc created with ref:", docRef.path, docRef.data)
          const newArr = addedDocs
          newArr.push({...docRef.data, path: docRef.path, id: docRef.id, unit: formData.unitId})
          setAddedDocs(newArr)
          setAddCount(addCount + 1)
        })
      }
  };

  useEffect(() => {

    const fetchAllDocuments = async (path, role, limit) => {
      try {
        const fetchedDocs = await getContentInDirAndSubdirs("", user.role, limit);
        setAllDoc(fetchedDocs)
      } catch (error) {
        console.error("Error fetching documents:", error);
        return [];
      }
    };
    fetchAllDocuments()


  }, []);


  useEffect(() => {
    let ignore = false
    if (!ignore && units.length === 0) fetchDocsData('units')
      .then(res =>
        fetchDocByFullpath("units/Alla").then(doc => {
          !env.devmode ?{}:console.debug('allUnits length:', res.length, '(+ 1)'/*map(r=>(r.id))*/, '\ndoc')
          setUnits(res)
          if (!elevatedRoles.includes(role) && user?.unitId)
            setFormData((prevState) => ({
                ...prevState,
              unitId: user.unitId
            }))
        })

        )
    else if (!ignore && !elevatedRoles.includes(role) && user?.unitId)
      setFormData((prevState) => ({
        ...prevState,
        unitId: user.unitId
      }))
    return () => { ignore = true }
  }, [])

  useEffect(() => {
    let ignore = false
    if (env.verbose) getUnitName(formData.unitId).then(res => {console.log('Enhet vald:', res)})
    const path = 'folders/' + formData.unitId
    if (formData?.unitId !== '' && user?.role) {
      getSubDirsRecursive(path, user.role, formData.unitId).then(res => {
        const docs = res.map(doc => (addToStringForFiltering(doc)))
        const filtered = docs.filter(d => d.unit === formData.unitId)
        setUnitDirs({dirs: filtered, filteredDirs: filtered})
      })
      getSubDirs(path)
      .then(res => {
        if (!ignore) {
          setDirs(res)
          setFormData((prevState) => ({
            ...prevState,
            folderLocation1: '',
          }))
        }
      })
    }
    return () => { ignore = true }
  }, [formData.unitId])

  useEffect(() => {
    let ignore = false
    const path = 'folders/' + formData.unitId + "/content/" + formData.folderLocation1
    if (env.verybose) console.debug('useEffect folderlocation1', formData.folderLocation1)
    setFormData((prevState) => ({
      ...prevState,
      folderLocation2: "",
    }))
    // console.assert(unitDirs.dirs.length > 0, 'unitDirs.length = 0')
    setUnitDirs({dirs: unitDirs.dirs, filteredDirs: unitDirs.dirs.filter(d => (d.path?.includes(path)))})
    if (formData.folderLocation1) {
      getSubDirs(path)
        // fetchDocs('units')
        .then(res => {
          if (!ignore) setSubdirs(res)
        })
    } else setSubdirs([])
    return () => { ignore = true }
  }, [formData.folderLocation1, formData.unitId])

  useEffect(()=> { if (theUser?.role && theUser?.role !== role) setRole(theUser?.role) }, [theUser?.role, role])

  useEffect(() => {
    if (formData.folderLocation1 && formData.folderLocation2) {
      const path = 'folders/' + formData.unitId + "/content/" + formData.folderLocation1 + "/content/" + formData.folderLocation2
      setUnitDirs({dirs: unitDirs.dirs, filteredDirs: unitDirs.dirs.filter(d => (d.path?.includes(path)))})
    }
  }, [formData.folderLocation1, formData.folderLocation2]);

  // !env.devmode ?{}:console.debug('units.length', dirs.length, units)

    // const roleEl = document.getElementById('role')
    // const unitEl = document.getElementById('unitRole')
    // if (role === 'manager') {
    //     if (!roleEl?.hasAttribute('hidden')) roleEl?.setAttribute('hidden')
    //     if (!unitEl?.hasAttribute('hidden')) unitEl?.setAttribute('hidden')
    // } else {
    //     if (roleEl?.hasAttribute('hidden')) roleEl?.removeAttribute('hidden')
    //     if (unitEl?.hasAttribute('hidden')) unitEl?.removeAttribute('hidden')
    // }

  return (
    <section>
    <form className="flex-form" onSubmit={handleSubmit}>
      <input
        type="text"
        name="title"
        className="flex100"
        value={formData.title}
        onChange={handleChange}
        placeholder="Skriv in rubrik till mappen"
      />
      <select
        name="role"
        id="role"
        className="flex50"
        value={formData.role}
        onChange={handleChange}
      >
        <option value="">
          Välj läsbehörighet för mappen
      </option>
        {elevatedRoles.includes(role) && (
          <>
            {/*<option value="user">Alla</option>*/}
            <option value="admin">{getRoleNameAndBelow('admin')}</option>
            <option value="manager">{getRoleNameAndBelow('MANAGER')}</option>
            <option value="user">{getRoleNameAndBelow('USER')}</option>
          </>
        )}
        {theUser?.role === 'manager' && (
          <>
            <option value="manager">{getRoleNameAndBelow('manager')}</option>
            <option value="user">{getRoleNameAndBelow('user')}</option>
          </>
        )}
        {theUser?.role === 'user' && (
          <>
            <option value="user">{getRoleNameAndBelow('user')}</option>
          </>
        )}
      </select>
      <select
        name="unitId"
        id="unitId"
        className="flex50"
        value={formData.unitId}
          onChange={handleUnitChange}
      >
        <option value="default">Välj appens enhet</option>

        {units.map((unit) => {
          if (theUser?.role === 'manager' && theUser?.unitId === unit.uid) {
            return (
              <option key={unit.path} value={unit.id}>
                {unit.name}
              </option>
            );
          }
          else if (elevatedRoles.includes(theUser?.role)) {
            return (
              <option key={unit.path} value={unit.id}>
                {unit.name}
              </option>
            );
          }
          return <></> ;
        })}
      </select>
      <select
        name="folderLocation1"
        id="folderLocation1"
        className="flex50"
        value={formData.folderLocation1}
        onChange={handleChange}
        disabled={dirs.length === 0}
      >
        <option value="">Överordnad mapp 1</option>
        {/* <option value="sdir">undermapp</option>
        <option value="sdir2">undermapp 2</option>
        <option value="sdir3">undermapp 3</option> */}
        {dirs.map((doc) => {
          return (<option key={doc.path} value={doc.id}>{doc.id}</option>)
        })}
      </select>
      <select
        name="folderLocation2"
        id="folderLocation2"
        className="flex50"
        value={formData.folderLocation2}
        onChange={handleChange}
        disabled={subdirs.length === 0}
      >
        <option value="" >Överordnad mapp 2</option>
        {/* <option value="sdir">Alternativ 1</option>
        <option value="sdir2">Alternativ 2</option>
        <option value="sdir3">Alternativ 3</option> */}
        {subdirs.map((doc) => {
          return (<option /*key='sdirOption'*/ value={doc.ref.id}>{doc.ref.id}</option>)
        })}
      </select>

      <button className=" flex30 formBtn" type="submit">
        Skicka
      </button>

      {!env.devmode? <></> : <section>count: {addCount}</section> }
    </form>
      <FolderPageListning
        unitDirs={unitDirs}
        documents={allDocs}
        addedDocs={addedDocs}
        units={units}
        theUnit={theUnit}
      />
    </section>

  );
}

export default FormTemplate;
