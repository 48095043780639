import React from "react";
import "../../../../assets/styles/Listing/template/BuildingFolderHeadlineTemplate.scss";
import { Link } from "react-router-dom";
import {humanreadablePath, prettyPath} from "../../../../store/actions/fetchDocs";
import env from "../../../../env";

const BuildingLevel1FolderTemplate = ({
  icon,
  title,
  building,
  folderId,
  hasSubFolder,
  path
}) => {
  const isIconEmoji = typeof icon === "string" && icon.length < 10;

  return (
    <div className="card">
      <Link to={"/enhet/" + building.name + "/mappar/" + folderId} className="folder-link">
        <div className="card-icon">
          {isIconEmoji ? icon : <img src={icon} alt={`${title} icon`}/>}
        </div>
        <div className="card-title">{title}</div>
      </Link>
      {env.devmode ? <section style={{fontSize: "small", marginLeft: "1vw", marginRight: "1vw"}}>debug: {humanreadablePath(path, building.id, '')}</section> : <></>}
    </div>
  )
    ;
};

export default BuildingLevel1FolderTemplate;
